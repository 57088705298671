/*
* @Author: 王翎爆
* @Date: 2023-03-30 09:37:53
* @Last Modified by: wangg
* @Last Modified time: 2024-07-23 14:42:410
*/
<template>
    <div>
        <div id="page">
            <el-table :data="changeData" border style="width: 100%; " ref="table"   
                :header-cell-style="{ 'text-align': 'center' }" :span-method="objectSpanMethod">
                <el-table-column prop="idx" label="序号" className="my-cell" width="60px" >
                </el-table-column>
                <el-table-column prop="point" label="检测点位置" className="my-cell"  >
                </el-table-column>
                <el-table-column prop="paramet" label="检测子项" className="my-cell">
                </el-table-column> 
                <el-table-column prop="mode" label="检测方式" className="my-cell">
                </el-table-column>
                <!-- <el-table-column prop="result" label="检测结果/测量数据" className="my-cell" width="200px">
                </el-table-column>  -->
                <el-table-column prop="result" label="检测结果" width="130px">
                    <template slot-scope="scope"> 
                        <div id="spdImg" key="result" v-if="scope.row.type == 'photo'">
                            <el-image style="width: 35mm; height: 35mm" :src="scope.row.imgurl"></el-image>
                        </div>
                        <span v-else>{{ scope.row.result }}</span>
                    </template>
                </el-table-column> 
            </el-table>
        </div>
        <div id="print">
            <el-table3 :data="changeData" style="width: 200mm; margin-left: 19px;color: black; font-family: song;"
                ref="table" :header-cell-style="{ 'text-align': 'center' }" :row-style="{ height: '5' }"
                :cell-style="{ padding: '5.5px 0px' }" :span-method="objectSpanMethodprint">
                <el-table-column prop="idx" label="序号" className="my-cell">
                </el-table-column>
                <el-table-column prop="point" label="检测点" width="170px">
                </el-table-column>
                <el-table-column prop="paramet" label="检测参数" width="180px">
                </el-table-column> 
                <el-table-column prop="mode" label="检测方式" width="100px">
                </el-table-column> 
                <el-table-column prop="result" label="检测结果" width="180px">
                    <template slot-scope="scope"> 
                        <div id="spdImg" key="result" v-if="scope.row.type == 'photo'">
                            <el-image style="width: 35mm; height: 35mm" :src="scope.row.imgurl"></el-image>
                        </div>
                        <span v-else>{{ scope.row.result }}</span>
                    </template>
                </el-table-column> 
            </el-table3>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ReportSubrgion',
    props: ['SubrgionName', 'endorseMessage', 'auditMessage', 'reportTemplate'],
    data() {
        return {
            status: true,
            spanArr: [],
            pos: null,
            mark: '',
            changeData: [],
            backesdorse: '',
            inputIndex: '',
            urlList: [],
            photo: {},
            focusData: '',
            subregionFlag: '000'
        };
    },
    components: {
    },
    created() {
        this.$bus.$on('Subrgionchangeexaminecontents', this.changeexaminecontents)
    },
    mounted() {
        this.changeDataForm()
    },
    methods: {
        //省去修约(四舍六入五成双)
        evenRound(num, decimalPlaces) { 
            return num
        },
        //单元格合并
        getSpanArr(data) {
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].point === data[i - 1].point) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                }
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            // 合并一行相同列数据，检测项目
            if (row.idx === row.paramet && row.idx === row.standard && row.idx === row.mode && row.idx === row.result && row.idx === row.determine) {
                if (columnIndex === 0) {
                    return { rowspan: 1, colspan: 8 }; // 合并后的单元格不显示
                } else if (columnIndex === 1) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 2) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 3) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 4) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 5) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 6) {
                    return { rowspan: 1, colspan: 0 }
                } else if (columnIndex === 7) {
                    return { rowspan: 1, colspan: 0 }
                }
            }
            //合并检测点位置列相同行数据
            if (columnIndex === 1) {
                const _row = this.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                // console.log(`rowspan:${_row} colspan:${_col}`)
                return { // [0,0] 表示这一行不显示,被合并了  [2,1]表示行的合并数
                    rowspan: _row,
                    colspan: _col
                }
            }
        },

        objectSpanMethodprint({ row, column, rowIndex, columnIndex }) {
            // 合并一行相同列数据，检测项目
            if (row.idx === row.paramet && row.idx === row.standard && row.idx === row.mode && row.idx === row.result && row.idx === row.determine) {
                if (columnIndex === 0) {
                    return { rowspan: 1, colspan: 8 }; // 合并后的单元格不显示
                }  
            } 
        },
        focusContent(event) {
            this.focusData = event.target.innerText.trim()
        },
        focusInputContent(index, row) {
            this.focusData = row.mark

        },
        //获取批注框信息
        leaveContent(event) {
            let textareaContent = event.target.innerText.trim()
            // console.log(textareaContent);
            if (this.focusData != textareaContent) {
                this.$emit('change', this.endorseMessage + `.areaCode`, textareaContent)
                this.$emit('auditChange', this.auditMessage + '：', textareaContent)
            }
        },
        //获取表格批注信息
        inputContent(index, row) {
            console.log(row.point, index);
            let inputContent = row.mark
            if (this.focusData != inputContent) {
                console.log(inputContent);
                this.$emit('change', this.endorseMessage + `.sourceDataList.` + index, inputContent)
                this.$emit('auditChange', this.auditMessage + '-' + row.point + '-' + row.project + '-' + row.paramet + '：', inputContent)
            }
        },
        // 将检测结果和标准值的字符串类型改为数值类型
        changeType(result, standard) {
            // console.log(result);
            var changeResult = result.match(/\d+(\.\d+)?/g)
            // console.log(changeResult);
            if (changeResult != null) {
                changeResult = changeResult.map(Number)
                changeResult = changeResult[0]
            } else {
                changeResult = result
            }
            if (standard) {
                // console.log(standard)
                var changeStandard = standard.match(/\d+(\.\d+)?/g)
                if (changeStandard != null) {
                    // console.log(changeStandard)
                    changeStandard = changeStandard.map(Number)
                    changeStandard = changeStandard[0]
                } else {
                    changeStandard = standard
                }

            }
            return { changeResult, changeStandard }
        },
        addIdx(flag) {
            return this.subregionFlag = (Number(flag) + 1).toString().padStart(3, '0')
        },
          //分区数据格式转换
        changeDataForm() {
            this.subregionFlag = '000'
            for (let i = 0; i < this.SubrgionName.sourceDataList.length; i++) {
                let subregion = this.SubrgionName.sourceDataList[i]
                let downlead = this.SubrgionName.sourceDataList[i]
                let earthing = this.SubrgionName.sourceDataList[i]
                let strike = this.SubrgionName.sourceDataList[i]
                let powersurge = this.SubrgionName.sourceDataList[i]
                let equipotential = this.SubrgionName.sourceDataList[i]
                let lightning = this.SubrgionName.sourceDataList[i]
                if (subregion.testProject == '接闪器') {  
                    if (subregion.lx && subregion.testProject == '接闪器' && subregion.lx != '请选择') {
                        const lx = {}
                        lx.idx = this.addIdx(this.subregionFlag)
                        lx.point = subregion.testPosition
                        lx.paramet = '类型'
                        lx.standard = 'GB/T21431-2023,5.5.1.1.1'
                        lx.mode = '观察'
                        lx.project = subregion.testProject

                        lx.result = subregion.lx
                        if (this.SubrgionName.protectionType == '第一类' && (subregion.lx != '接闪杆' && subregion.lx != '接闪线' && subregion.lx != '架空接闪网')) {
                            lx.determine = '不符合要求'
                        } else {
                            lx.determine = '符合要求'
                        }
                        this.changeData.push(lx)
                    }
                    if (subregion.fs && subregion.testProject == '接闪器' && subregion.fs != '请选择') {
                        const fs = {}
                        fs.idx = this.addIdx(this.subregionFlag)
                        fs.point = subregion.testPosition
                        fs.project = subregion.testProject
                        fs.paramet = '方式'
                        fs.mode = '观察'
                        fs.result = subregion.fs
                        fs.standard = '水平/垂直'
                        fs.determine = '符合要求'
                        this.changeData.push(fs)
                    }
                    if (subregion.fswz && subregion.testProject == '接闪器' && subregion.fswz != '请选择') {
                        const fswz = {}
                        fswz.idx = this.addIdx(this.subregionFlag)
                        fswz.point = subregion.testPosition
                        fswz.project = subregion.testProject
                        fswz.paramet = '敷设位置'
                        fswz.mode = '观察'
                        fswz.result = subregion.fswz
                        fswz.standard = '/'
                        fswz.determine = '参考项'
                        this.changeData.push(fswz)
                    }
                    if (subregion.cl && subregion.testProject == '接闪器') {
                        const cl = {}
                        cl.idx = this.addIdx(this.subregionFlag)
                        cl.point = subregion.testPosition
                        cl.project = subregion.testProject
                        cl.paramet = '材料'
                        cl.mode = subregion.cljcfs
                        cl.result = subregion.cl
                        cl.standard = 'GB50057-2010,5.1.1,5.2'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (subregion.gg && subregion.testProject == '接闪器') {
                        const gg = {}
                        gg.idx = this.addIdx(this.subregionFlag)
                        gg.point = subregion.testPosition
                        gg.project = subregion.testProject
                        gg.mode = subregion.ggjcfs
                        if (subregion.ggUnit ) {
                           gg.paramet = `规格(${earthing.ggUnit})`
                        }  
                        if (subregion.ggStandard == '参考项') {
                            gg.result = subregion.gg
                            gg.standard = '/'
                            gg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(subregion.gg, subregion.ggStandard)
                            gg.result = this.evenRound(changeObj.changeResult, 0)
                            if (gg.result >= changeObj.changeStandard) {
                                gg.standard = subregion.ggStandard
                                gg.determine = '符合要求'
                            } else {
                                gg.standard = subregion.ggStandard
                                gg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(gg)
                    }
                    if (subregion.azgy && subregion.testProject == '接闪器' && subregion.azgy != '请选择') {
                        const azgy = {}
                        azgy.idx = this.addIdx(this.subregionFlag)
                        azgy.point = subregion.testPosition
                        azgy.project = subregion.testProject
                        azgy.paramet = '安装工艺'
                        azgy.mode = '观察'
                        azgy.result = subregion.azgy
                        azgy.standard = '明敷/暗敷/立面金属'
                        azgy.determine = '符合要求'
                        this.changeData.push(azgy)
                    }
                    if (subregion.xz && subregion.testProject == '接闪器' && subregion.xz != '请选择') {
                        const xz = {}
                        xz.idx = this.addIdx(this.subregionFlag)
                        xz.point = subregion.testPosition
                        xz.project = subregion.testProject
                        xz.paramet = '现状'
                        xz.mode = '观察'
                        xz.result = subregion.xz
                        xz.standard = 'GB/T21431-2023,5.5.1.4.1'
                        xz.determine = '参考项'
                        this.changeData.push(xz)
                    }
                    if (subregion.ffcs && subregion.testProject == '接闪器' && subregion.ffcs != '请选择') {
                        const ffcs = {}
                        ffcs.idx = this.addIdx(this.subregionFlag)
                        ffcs.point = subregion.testPosition
                        ffcs.project = subregion.testProject
                        ffcs.paramet = '防腐措施'
                        ffcs.mode = subregion.ffcsjcfs
                        ffcs.result = subregion.ffcs
                        ffcs.standard = '镀锌/刷漆/无需防腐'
                        if (subregion.ffcs == '无措施') {
                            ffcs.determine = '不符合要求'
                        } else {
                            ffcs.determine = '符合要求'
                        }
                        this.changeData.push(ffcs)
                    }
                    if (subregion.xs && subregion.testProject == '接闪器' && subregion.xs != '请选择') {
                        const xs = {}
                        xs.idx = this.addIdx(this.subregionFlag)
                        xs.point = subregion.testPosition
                        xs.project = subregion.testProject
                        xs.paramet = '锈蚀'
                        xs.mode = '观察'
                        xs.result = subregion.xs
                        xs.standard = '不应超过初始截面的1/3'
                        if (subregion.xs == '超过初始截面的1/3') {
                            xs.determine = '不符合要求'
                        } else {
                            xs.determine = '符合要求'
                        }
                        this.changeData.push(xs)
                    }
                    if (subregion.gdzjdjj && subregion.testProject == '接闪器') {
                        const gdzjdjj = {}
                        gdzjdjj.idx = this.addIdx(this.subregionFlag)
                        gdzjdjj.point = subregion.testPosition
                        gdzjdjj.project = subregion.testProject
                        gdzjdjj.paramet = '固定支架间距(m)'
                        gdzjdjj.mode = '测量'
                        gdzjdjj.standard = subregion.gdzjdjjStandard
                        var changeObj = this.changeType(subregion.gdzjdjj, gdzjdjj.standard)
                        gdzjdjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (gdzjdjj.result <= changeObj.changeStandard) {
                            gdzjdjj.determine = '符合要求'
                        } else {
                            gdzjdjj.determine = '不符合要求'
                        }
                        this.changeData.push(gdzjdjj)
                    }
                    if (subregion.djksz && subregion.testProject == '接闪器') {
                        const djksz = {}
                        djksz.idx = this.addIdx(this.subregionFlag)
                        djksz.point = subregion.testPosition
                        djksz.project = subregion.testProject
                        djksz.paramet = '固定支架高度(mm)'
                        djksz.mode = '测量'
                        djksz.standard = '≥150'
                        var changeObj = this.changeType(subregion.djksz, djksz.standard)
                        djksz.result = this.evenRound(changeObj.changeResult, 0)
                        if (djksz.result >= changeObj.changeStandard) {
                            djksz.determine = '符合要求'
                        } else {
                            djksz.determine = '不符合要求'
                        }
                        this.changeData.push(djksz)
                    }
                    if (subregion.gdzjdczll && subregion.testProject == '接闪器') {
                        const gdzjdczll = {}
                        gdzjdczll.idx = this.addIdx(this.subregionFlag)
                        gdzjdczll.point = subregion.testPosition
                        gdzjdczll.project = subregion.testProject
                        gdzjdczll.paramet = '固定支架的垂直拉力(N)'
                        gdzjdczll.mode = '测量'
                        gdzjdczll.standard = '≥49'
                        var changeObj = this.changeType(subregion.gdzjdczll, gdzjdczll.standard)
                        gdzjdczll.result = this.evenRound(changeObj.changeResult, 0)
                        if (gdzjdczll.result >= changeObj.changeStandard) {
                            gdzjdczll.determine = '符合要求'
                        } else {
                            gdzjdczll.determine = '不符合要求'
                        }
                        this.changeData.push(gdzjdczll)
                    }
                    if (subregion.wgcc && subregion.testProject == '接闪器') {
                        const wgcc = {}
                        wgcc.idx = this.addIdx(this.subregionFlag)
                        wgcc.point = subregion.testPosition
                        wgcc.project = subregion.testProject
                        wgcc.paramet = '网格尺寸(m)'
                        wgcc.mode = '测量'
                        wgcc.standard = subregion.wgccStandard
                        var cresult = subregion.wgcc.match(/(-?\d+)(\.\d+)?/g)
                        cresult = cresult.map(Number)
                        //计算检测结果中的最大值和最小值
                        var mincresult = Math.min(...cresult)
                        var maxcresult = Math.max(...cresult)
                        var gone = this.evenRound(mincresult, 1)
                        var gtwo = this.evenRound(maxcresult, 1)
                        var cresults = gone + '×' + gtwo
                        wgcc.result = cresults
                        if (subregion.wgccStandard) {
                            var cstandard = subregion.wgccStandard.match(/\d+(\.\d+)?/g)
                            cstandard = cstandard.map(Number)
                            var minnumber
                            var maxnumber
                            if (cstandard[2] > cstandard[3]) {
                                minnumber = cstandard[3]
                                maxnumber = cstandard[2]
                            } else {
                                minnumber = cstandard[2]
                                maxnumber = cstandard[3]
                            }
                            // 检测结果中的最大值小于标准值中第一个值，或结果最小值小于标准中最小值、最大值小于标准最大值则符合要求，否则不符合
                            if (maxcresult <= cstandard[0] || mincresult <= minnumber && maxcresult <= maxnumber) {
                                wgcc.determine = '符合要求'
                            } else {
                                wgcc.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(wgcc)
                    }
                    if (subregion.ssfcdkj && subregion.testProject == '接闪器' && subregion.ssfcdkj != '请选择') {
                        const ssfcdkj = {}
                        ssfcdkj.idx = this.addIdx(this.subregionFlag)
                        ssfcdkj.point = subregion.testPosition
                        ssfcdkj.project = subregion.testProject
                        ssfcdkj.paramet = '伸缩缝处的跨接'
                        ssfcdkj.mode = '观察'
                        ssfcdkj.result = subregion.ssfcdkj
                        ssfcdkj.standard = 'GB50601-2010,6.1.2-5'
                        if (subregion.ssfcdkj == '已跨接') {
                            ssfcdkj.determine = '符合要求'
                        } else {
                            ssfcdkj.determine = '不符合要求'
                        }
                        this.changeData.push(ssfcdkj)
                    }
                    if (subregion.ddwljxn && subregion.testProject == '接闪器') {
                        const ddwljxn = {}
                        ddwljxn.idx = this.addIdx(this.subregionFlag)
                        ddwljxn.point = subregion.testPosition
                        ddwljxn.project = subregion.testProject
                        ddwljxn.paramet = '等电位连接性能(Ω)'
                        ddwljxn.mode = '测量'
                        ddwljxn.standard = subregion.ddwljxnStandard
                        var changeObj = this.changeType(subregion.ddwljxn, ddwljxn.standard)
                        ddwljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwljxn.result <= changeObj.changeStandard) {
                            ddwljxn.determine = '符合要求'
                        } else {
                            ddwljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwljxn)
                    }
                    if (subregion.fz && subregion.testProject == '接闪器' && subregion.fz != '请选择') {
                        const fz = {}
                        fz.idx = this.addIdx(this.subregionFlag)
                        fz.point = subregion.testPosition
                        fz.project = subregion.testProject
                        fz.paramet = '附着'
                        fz.mode = '观察'
                        fz.result = subregion.fz
                        fz.standard = '不应附着电气、通信、信号或其它线路'
                        if (subregion.fz == '无附着其它电气线路') {
                            fz.determine = '符合要求'
                        } else {
                            fz.determine = '不符合要求'
                        }
                        this.changeData.push(fz)
                    }
                    if (subregion.jgjl && subregion.testProject == '接闪器') {
                        const jgjl = {}
                        jgjl.idx = this.addIdx(this.subregionFlag)
                        jgjl.point = subregion.testPosition
                        jgjl.project = subregion.testProject
                        jgjl.paramet = '间隔距离(m)'
                        jgjl.mode = '测量'
                        jgjl.result = subregion.jgjl
                        jgjl.standard = '/'
                        jgjl.determine = '参考项'
                        this.changeData.push(jgjl)
                    }
                    if (subregion.fcjcs && subregion.testProject == '接闪器' && subregion.fcjcs != '请选择') {
                        const fcjcs = {}
                        fcjcs.idx = this.addIdx(this.subregionFlag)
                        fcjcs.point = subregion.testPosition
                        fcjcs.project = subregion.testProject
                        fcjcs.paramet = '防侧击措施'
                        fcjcs.mode = subregion.fcjcsjcfs
                        fcjcs.result = subregion.fcjcs
                        fcjcs.standard = 'GB/T21431-2023,5.5.1.13.1'
                        fcjcs.determine = '符合要求'
                        this.changeData.push(fcjcs)
                    }
                    if (subregion.bhfw && subregion.testProject == '接闪器') {
                        const bhfw = {}
                        bhfw.idx = this.addIdx(this.subregionFlag)
                        bhfw.point = subregion.testPosition
                        bhfw.project = subregion.testProject
                        bhfw.paramet = '保护范围(m)'
                        bhfw.mode = subregion.bhfwjcfs
                        bhfw.result = subregion.bhfw
                        bhfw.standard = 'GB/T21431-2023,5.5.1.14.1'
                        bhfw.determine = '参考项'
                        this.changeData.push(bhfw)
                    }
                }
                //引下线相关数据
                if (downlead.testProject == '引下线') { 
                    if (downlead.lx && downlead.testProject == '引下线' && downlead.lx != '请选择') {
                        const downleadlx = {}
                        downleadlx.idx = this.addIdx(this.subregionFlag)
                        downleadlx.point = downlead.testPosition
                        downleadlx.project = downlead.testProject
                        downleadlx.paramet = '类型'
                        downleadlx.mode = '观察'
                        downleadlx.result = downlead.lx
                        downleadlx.standard = '自然引下线/专设引下线'
                        downleadlx.determine = '符合要求'
                        this.changeData.push(downleadlx)
                    }
                    if (downlead.fs && downlead.testProject == '引下线' && downlead.fs != '请选择') {
                        const downleadfs = {}
                        downleadfs.idx = this.addIdx(this.subregionFlag)
                        downleadfs.point = downlead.testPosition
                        downleadfs.project = downlead.testProject
                        downleadfs.paramet = '敷设'
                        downleadfs.mode = '观察'
                        downleadfs.result = downlead.fs
                        downleadfs.standard = '明敷/暗敷/组合'
                        downleadfs.determine = '符合要求'
                        this.changeData.push(downleadfs)
                    }
                    if (downlead.cl && downlead.testProject == '引下线') {
                        const downleadcl = {}
                        downleadcl.idx = this.addIdx(this.subregionFlag)
                        downleadcl.point = downlead.testPosition
                        downleadcl.project = downlead.testProject
                        downleadcl.paramet = '材料'
                        downleadcl.mode = downlead.cljcfs
                        downleadcl.result = downlead.cl
                        downleadcl.standard = 'GB50057-2010,5.1.1,5.3.1,5.3.3~5.3.5'
                        downleadcl.determine = '参考项'
                        this.changeData.push(downleadcl)
                    }
                    if (downlead.gg && downlead.testProject == '引下线') {
                        const downleadgg = {}
                        downleadgg.idx = this.addIdx(this.subregionFlag)
                        downleadgg.point = downlead.testPosition
                        downleadgg.project = downlead.testProject
                        downleadgg.mode = downlead.ggjcfs
                        if (downlead.gg.includes('mm²')) {
                            downleadgg.paramet = '规格(mm²)'
                        } else {
                            downleadgg.paramet = '规格(mm)'
                        }
                        if (downlead.ggStandard == '参考项') {
                            downleadgg.result = downlead.gg
                            downleadgg.standard = '/'
                            downleadgg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(downlead.gg, downlead.ggStandard)
                            downleadgg.result = this.evenRound(changeObj.changeResult, 0)
                            if (downleadgg.result >= changeObj.changeStandard) {
                                downleadgg.standard = downlead.ggStandard
                                downleadgg.determine = '符合要求'
                            } else {
                                downleadgg.standard = downlead.ggStandard
                                downleadgg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(downleadgg)
                    }
                    if (downlead.azgy && downlead.testProject == '引下线' && downlead.azgy != '请选择') {
                        const downleadazgy = {}
                        downleadazgy.idx = this.addIdx(this.subregionFlag)
                        downleadazgy.point = downlead.testPosition
                        downleadazgy.project = downlead.testProject
                        downleadazgy.paramet = '安装工艺'
                        downleadazgy.mode = '观察'
                        downleadazgy.result = downlead.azgy
                        downleadazgy.standard = '焊接/螺栓连接/机械连接'
                        downleadazgy.determine = '符合要求'
                        this.changeData.push(downleadazgy)
                    }
                    if (downlead.xz && downlead.testProject == '引下线' && downlead.xz != '请选择') {
                        const downleadxz = {}
                        downleadxz.idx = this.addIdx(this.subregionFlag)
                        downleadxz.point = downlead.testPosition
                        downleadxz.project = downlead.testProject
                        downleadxz.paramet = '现状'
                        downleadxz.mode = '观察'
                        downleadxz.result = downlead.xz
                        downleadxz.standard = 'GB/T21431-2023,5.5.2.4.1'
                        downleadxz.determine = '参考项'
                        this.changeData.push(downleadxz)
                    }
                    if (downlead.xs && downlead.testProject == '引下线' && downlead.xs != '请选择') {
                        const downleadxs = {}
                        downleadxs.idx = this.addIdx(this.subregionFlag)
                        downleadxs.point = downlead.testPosition
                        downleadxs.project = downlead.testProject
                        downleadxs.paramet = '锈蚀'
                        downleadxs.mode = '观察'
                        downleadxs.result = downlead.xs
                        downleadxs.standard = '不应超过初始截面的1/3'
                        if (downlead.xs == '超过初始截面的1/3') {
                            downleadxs.determine = '不符合要求'
                        } else {
                            downleadxs.determine = '符合要求'
                        }
                        this.changeData.push(downleadxs)
                    }
                    if (downlead.gdzjdjj && downlead.testProject == '引下线') {
                        const downleadgdzjdjj = {}
                        downleadgdzjdjj.idx = this.addIdx(this.subregionFlag)
                        downleadgdzjdjj.point = downlead.testPosition
                        downleadgdzjdjj.project = downlead.testProject
                        downleadgdzjdjj.paramet = '固定支架的间距(m)'
                        downleadgdzjdjj.mode = '测量'
                        downleadgdzjdjj.standard = downlead.gdzjdjjStandard
                        var changeObj = this.changeType(downlead.gdzjdjj, downleadgdzjdjj.standard)
                        downleadgdzjdjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadgdzjdjj.result <= changeObj.changeStandard) {
                            downleadgdzjdjj.determine = '符合要求'
                        } else {
                            downleadgdzjdjj.determine = '不符合要求'
                        }
                        this.changeData.push(downleadgdzjdjj)
                    }
                    if (downlead.gdzjdczll && downlead.testProject == '引下线') {
                        const downleadgdzjdczll = {}
                        downleadgdzjdczll.idx = this.addIdx(this.subregionFlag)
                        downleadgdzjdczll.point = downlead.testPosition
                        downleadgdzjdczll.project = downlead.testProject
                        downleadgdzjdczll.paramet = '固定支架的垂直拉力(N)'
                        downleadgdzjdczll.mode = '测量'
                        downleadgdzjdczll.standard = '≥49'
                        var changeObj = this.changeType(downlead.gdzjdczll, downleadgdzjdczll.standard)
                        downleadgdzjdczll.result = this.evenRound(changeObj.changeResult, 0)
                        if (downleadgdzjdczll.result >= changeObj.changeStandard) {
                            downleadgdzjdczll.determine = '符合要求'
                        } else {
                            downleadgdzjdczll.determine = '不符合要求'
                        }
                        this.changeData.push(downleadgdzjdczll)
                    }
                    if (downlead.djksz && downlead.testProject == '引下线' && downlead.djksz!= '请选择') {
                        const downleaddjksz = {}
                        downleaddjksz.idx = this.addIdx(this.subregionFlag)
                        downleaddjksz.point = downlead.testPosition
                        downleaddjksz.project = downlead.testProject
                        downleaddjksz.paramet = '断接卡设置'
                        downleaddjksz.mode = '观察'
                        downleaddjksz.result = downlead.djksz
                        downleaddjksz.standard = 'GB/T21431-2023,5.5.2.8.1'
                        downleaddjksz.determine = '参考项'
                        this.changeData.push(downleaddjksz)
                    }
                    if (downlead.bhcs && downlead.testProject == '引下线' && downlead.bhcs != '请选择') {
                        const downleadbhcs = {}
                        downleadbhcs.idx = this.addIdx(this.subregionFlag)
                        downleadbhcs.point = downlead.testPosition
                        downleadbhcs.project = downlead.testProject
                        downleadbhcs.paramet = '保护措施'
                        downleadbhcs.mode = '观察'
                        downleadbhcs.result = downlead.bhcs
                        downleadbhcs.standard = 'GB/T21431-2023,5.5.2.8.1'
                        downleadbhcs.determine = '符合要求'
                        this.changeData.push(downleadbhcs)
                    }
                    if (downlead.fjcdyhpcsldycs && downlead.testProject == '引下线' && downlead.fjcdyhpcsldycs != '请选择') {
                        const fjcdyhpcsldycs = {}
                        fjcdyhpcsldycs.idx = this.addIdx(this.subregionFlag)
                        fjcdyhpcsldycs.point = downlead.testPosition
                        fjcdyhpcsldycs.project = downlead.testProject
                        fjcdyhpcsldycs.paramet = '防接触电压和旁侧闪络电压措施'
                        fjcdyhpcsldycs.mode = downlead.fjcdyhpcsldycsjcfs
                        fjcdyhpcsldycs.result = downlead.fjcdyhpcsldycs
                        fjcdyhpcsldycs.standard = 'GB50057-2010,4.5.6'
                        fjcdyhpcsldycs.determine = '符合要求'
                        this.changeData.push(fjcdyhpcsldycs)
                    }
                    if (downlead.fz && downlead.testProject == '引下线' && downlead.fz != '请选择') {
                        const downleadfz = {}
                        downleadfz.idx = this.addIdx(this.subregionFlag)
                        downleadfz.point = downlead.testPosition
                        downleadfz.project = downlead.testProject
                        downleadfz.paramet = '附着'
                        downleadfz.mode = '观察'
                        downleadfz.result = downlead.fz
                        downleadfz.standard = '不应附着电气、通信、信号或其它线路'
                        if (downlead.fz == '无附着其它电气线路') {
                            downleadfz.determine = '符合要求'
                        } else {
                            downleadfz.determine = '不符合要求'
                        }
                        this.changeData.push(downleadfz)
                    }
                    if (downlead.jgjl && downlead.testProject == '引下线') {
                        const jgjl = {}
                        jgjl.idx = this.addIdx(this.subregionFlag)
                        jgjl.point = downlead.testPosition
                        jgjl.project = downlead.testProject
                        jgjl.paramet = '间隔距离(m)'
                        jgjl.mode = '测量'
                        jgjl.standard = downlead.jgjlStandard
                        var changeObj = this.changeType(downlead.jgjl, jgjl.standard)
                        jgjl.result = this.evenRound(changeObj.changeResult, 1)
                        if (jgjl.result >= changeObj.changeStandard) {
                            jgjl.determine = '符合要求'
                        } else {
                            jgjl.determine = '不符合要求'
                        }
                        this.changeData.push(jgjl)
                    }
                    if (downlead.yjsqdljgddz && downlead.testProject == '引下线') {
                        const yjsqdljgddz = {}
                        yjsqdljgddz.idx = this.addIdx(this.subregionFlag)
                        yjsqdljgddz.point = downlead.testPosition
                        yjsqdljgddz.project = downlead.testProject
                        yjsqdljgddz.paramet = '与接闪器的连接过渡电阻(Ω)'
                        yjsqdljgddz.mode = '测量'
                        yjsqdljgddz.standard = '≤0.2'
                        var changeObj = this.changeType(downlead.yjsqdljgddz, yjsqdljgddz.standard)
                        yjsqdljgddz.result = this.evenRound(changeObj.changeResult, 3)
                        if (yjsqdljgddz.result <= changeObj.changeStandard) {
                            yjsqdljgddz.determine = '符合要求'
                        } else {
                            yjsqdljgddz.determine = '不符合要求'
                        }
                        this.changeData.push(yjsqdljgddz)
                    }
                    if (downlead.yjdtdljgddz && downlead.testProject == '引下线') {
                        const yjdtdljgddz = {}
                        yjdtdljgddz.idx = this.addIdx(this.subregionFlag)
                        yjdtdljgddz.point = downlead.testPosition
                        yjdtdljgddz.project = downlead.testProject
                        yjdtdljgddz.paramet = '与接地体的连接过渡电阻(Ω)'
                        yjdtdljgddz.mode = '测量'
                        yjdtdljgddz.standard = '≤0.2'
                        var changeObj = this.changeType(downlead.yjdtdljgddz, yjdtdljgddz.standard)
                        yjdtdljgddz.result = this.evenRound(changeObj.changeResult, 3)
                        if (yjdtdljgddz.result <= changeObj.changeStandard) {
                            yjdtdljgddz.determine = '符合要求'
                        } else {
                            yjdtdljgddz.determine = '不符合要求'
                        }
                        this.changeData.push(yjdtdljgddz)
                    }
                    if (downlead.sl && downlead.testProject == '引下线' && downlead.sl != '请选择') {
                        const sl = {}
                        sl.idx = this.addIdx(this.subregionFlag)
                        sl.point = downlead.testPosition
                        sl.project = downlead.testProject
                        sl.paramet = '数量'
                        sl.mode = '观察'
                        sl.result = downlead.sl
                        sl.standard = 'GB/T21431-2023,5.5.1.13.1'
                        sl.determine = '参考项'
                        this.changeData.push(sl)
                    }
                    if (downlead.pjjj && downlead.testProject == '引下线') {
                        const pjjj = {}
                        pjjj.idx = this.addIdx(this.subregionFlag)
                        pjjj.point = downlead.testPosition
                        pjjj.project = downlead.testProject
                        pjjj.paramet = '平均间距(m)'
                        pjjj.mode = '测量'
                        pjjj.standard = downlead.pjjjStandard
                        var changeObj = this.changeType(downlead.pjjj, pjjj.standard)
                        pjjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (pjjj.result <= changeObj.changeStandard) {
                            pjjj.determine = '符合要求'
                        } else {
                            pjjj.determine = '不符合要求'
                        }
                        this.changeData.push(pjjj)
                    }
                    if (downlead.jj && downlead.testProject == '引下线' && downlead.jj != '请选择') {
                        const jj = {}
                        jj.idx = this.addIdx(this.subregionFlag)
                        jj.point = downlead.testPosition
                        jj.project = downlead.testProject
                        jj.paramet = '间距(m)'
                        jj.mode = '测量'
                        jj.result = downlead.jj
                        jj.standard = 'GB/T21431-2023,5.5.1.13.1'
                        jj.determine = '参考项'
                        this.changeData.push(jj)
                    }

                }
                //接地装置相关数据
                if (earthing.testProject == '接地装置') { 
                    if (earthing.lx && earthing.testProject == '接地装置' && earthing.lx != '请选择') {
                        const lx = {}
                        lx.idx = this.addIdx(this.subregionFlag)
                        lx.point = earthing.testPosition
                        lx.project = earthing.testProject
                        lx.paramet = '类型'
                        lx.mode = earthing.lxjcfs
                        lx.result = earthing.lx
                        lx.standard = '自然接地体/人工接地体'
                        if (this.SubrgionName.protectionType == '第一类' && earthing.lx == '自然接地体') {
                            lx.determine = '不符合要求'
                        } else {
                            lx.determine = '符合要求'
                        }
                        this.changeData.push(lx)
                    }
                    if (earthing.bz && earthing.testProject == '接地装置' && earthing.bz != '请选择') {
                        const bz = {}
                        bz.idx = this.addIdx(this.subregionFlag)
                        bz.point = earthing.testPosition
                        bz.project = earthing.testProject
                        bz.paramet = '布置'
                        bz.mode = '观察'
                        bz.result = earthing.bz
                        bz.standard = 'GB/T21431-2023,5.5.3.2.1'
                        bz.determine = '参考项'
                        this.changeData.push(bz)
                    }
                    if (earthing.cl && earthing.testProject == '接地装置' && earthing.cl != '请选择') {
                        const cl = {}
                        cl.idx = this.addIdx(this.subregionFlag)
                        cl.point = earthing.testPosition
                        cl.project = earthing.testProject
                        cl.paramet = '材料'
                        cl.mode = earthing.cljcfs
                        cl.result = earthing.cl
                        cl.standard = 'GB50057-2010,4.2.4,4.3.5,4.3.6,4.4.5,4.4.6,5.1.1,5.4.1~5.4.3,5.4.5'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (earthing.gg && earthing.testProject == '接地装置' && earthing.gg != '请选择') {
                        const gg = {}
                        gg.idx = this.addIdx(this.subregionFlag)
                        gg.point = earthing.testPosition
                        gg.project = earthing.testProject
                        gg.mode = earthing.ggjcfs
                        if (earthing.ggUnit) {
                            gg.paramet = `规格(${earthing.ggUnit})`
                        }
                        gg.result = earthing.gg
                        gg.standard = '/'
                        gg.determine = '参考项'
                        this.changeData.push(gg)
                    }
                    if (earthing.hj && earthing.testProject == '接地装置' && earthing.hj != '请选择') {
                        const hj = {}
                        hj.idx = this.addIdx(this.subregionFlag)
                        hj.point = earthing.testPosition
                        hj.project = earthing.testProject
                        hj.paramet = '焊接'
                        hj.mode = earthing.hjjcfs
                        hj.result = earthing.hj
                        hj.standard = '放热焊接/通用焊接'
                        hj.determine = '符合要求'
                        this.changeData.push(hj)
                    }
                    if (earthing.ff && earthing.testProject == '接地装置' && earthing.ff != '请选择') {
                        const ff = {}
                        ff.idx = this.addIdx(this.subregionFlag)
                        ff.point = earthing.testPosition
                        ff.project = earthing.testProject
                        ff.paramet = '防腐'
                        ff.mode = earthing.ffjcfs
                        ff.result = earthing.ff
                        ff.standard = '镀锌/刷漆/无需防腐'
                        if(earthing.ff == '无措施'){
                        ff.determine = '不符合要求'
                        }else{
                        ff.determine = '符合要求'
                        }
                        this.changeData.push(ff)
                    }
                    if (earthing.fkbdycs && earthing.testProject == '接地装置' && earthing.fkbdycs != '请选择') {
                        const fkbdycs = {}
                        fkbdycs.idx = this.addIdx(this.subregionFlag)
                        fkbdycs.point = earthing.testPosition
                        fkbdycs.project = earthing.testProject
                        fkbdycs.paramet = '防跨步电压措施'
                        fkbdycs.mode = earthing.fkbdycsjcfs
                        fkbdycs.result = earthing.fkbdycs
                        fkbdycs.standard = 'GB50057-2010,4.5.6'
                        fkbdycs.determine = '符合要求'
                        this.changeData.push(fkbdycs)
                    }
                    if (earthing.tt && earthing.testProject == '接地装置') {
                        const tt = {}
                        tt.idx = this.addIdx(this.subregionFlag)
                        tt.point = earthing.testPosition
                        tt.project = earthing.testProject
                        tt.paramet = '填土'
                        tt.mode = earthing.ttjcfs
                        tt.standard = 'GB-T21431-2023,5.5.3.6'
                        tt.result = earthing.tt 
                        if (tt.result == "平整无沉陷") {
                            tt.determine = '符合要求'
                        } else {
                            tt.determine = '不符合要求'
                        }
                        this.changeData.push(tt)
                    }
                    if (earthing.mssd && earthing.testProject == '接地装置') {
                        const mssd = {}
                        mssd.idx = this.addIdx(this.subregionFlag)
                        mssd.point = earthing.testPosition
                        mssd.project = earthing.testProject
                        mssd.paramet = '埋设深度(m)'
                        mssd.mode = earthing.mssdjcfs
                        mssd.standard = '≥0.5' 
                        var changeObj = this.changeType(earthing.mssd, mssd.standard)
                        mssd.result = this.evenRound(changeObj.changeResult, 1)
                        if(mssd.result >= 0.5){
                            mssd.determine = "符合要求"
                        }else{
                            mssd.determine = "不符合要求"
                        }
                        this.changeData.push(mssd)
                    }
                    if (earthing.jgjl && earthing.testProject == '接地装置') {
                        const jgjl = {}
                        jgjl.idx = this.addIdx(this.subregionFlag)
                        jgjl.point = earthing.testPosition
                        jgjl.project = earthing.testProject
                        jgjl.paramet = '间隔距离(m)'
                        jgjl.mode = '测量'
                        if (earthing.jgjlStandard == '参考项') {
                            jgjl.result = earthing.jgjl
                            jgjl.standard = '/'
                            jgjl.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(earthing.jgjl, earthing.jgjlStandard)
                            jgjl.result = this.evenRound(changeObj.changeResult, 1)
                            jgjl.standard = earthing.jgjlStandard
                            if (jgjl.result >= changeObj.changeStandard) {
                                jgjl.determine = '符合要求'
                            } else {
                                jgjl.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(jgjl)
                    }
                    if (earthing.gyjd && earthing.testProject == '接地装置' && earthing.gyjd != '请选择') {
                        const gyjd = {}
                        gyjd.idx = this.addIdx(this.subregionFlag)
                        gyjd.point = earthing.testPosition
                        gyjd.project = earthing.testProject
                        gyjd.paramet = '共用接地'
                        gyjd.mode = earthing.gyjdjcfs
                        gyjd.result = earthing.gyjd
                        gyjd.standard = 'GB50057-2010,4.2.2-3,4.2.4-5,4.3.4,4.4.4'
                        gyjd.determine = '参考项'
                        this.changeData.push(gyjd)
                    }
                    if (earthing.dqgtxn && earthing.testProject == '接地装置') {
                        const dqgtxn = {}
                        dqgtxn.idx = this.addIdx(this.subregionFlag)
                        dqgtxn.point = earthing.testPosition
                        dqgtxn.project = earthing.testProject
                        dqgtxn.paramet = '电气贯通性能'
                        dqgtxn.mode = '测量'
                        dqgtxn.standard = '≤1'
                        var changeObj = this.changeType(earthing.dqgtxn, dqgtxn.standard)
                        dqgtxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (dqgtxn.result <= changeObj.changeStandard) {
                            dqgtxn.determine = '符合要求'
                        } else {
                            dqgtxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqgtxn)
                    }
                    if (earthing.jddz && earthing.testProject == '接地装置') {
                        const jddz = {}
                        jddz.idx = this.addIdx(this.subregionFlag)
                        jddz.point = earthing.testPosition
                        jddz.project = earthing.testProject
                        jddz.paramet = '接地电阻(Ω)'
                        jddz.mode = '测量'
                        jddz.standard = earthing.jddzStandard
                        var changeObj = this.changeType(earthing.jddz, jddz.standard)
                        jddz.result = this.evenRound(changeObj.changeResult, 1)
                        if (jddz.result <= changeObj.changeStandard) {
                            jddz.determine = '符合要求'
                        } else {
                            jddz.determine = '不符合要求'
                        }
                        this.changeData.push(jddz)
                    }
                }
                //磁屏蔽相关数据
                if (strike.testProject == '磁屏蔽') { 
                    if (strike.wz && strike.testProject == '磁屏蔽' && strike.wz != '请选择') {
                        const wz = {}
                        wz.idx = this.addIdx(this.subregionFlag)
                        wz.point = strike.testPosition
                        wz.project = strike.testProject
                        wz.paramet = '位置'
                        wz.mode = '观察'
                        wz.result = strike.wz
                        wz.standard = 'GB/T21431-2023,5.5.4.1.1'
                        wz.determine = '参考项'
                        this.changeData.push(wz)
                    }
                    if (strike.ddwhdqljxn && strike.testProject == '磁屏蔽') {
                        const ddwhdqljxn = {}
                        ddwhdqljxn.idx = this.addIdx(this.subregionFlag)
                        ddwhdqljxn.point = strike.testPosition
                        ddwhdqljxn.project = strike.testProject
                        ddwhdqljxn.paramet = '等电位和电气连接性能(Ω)'
                        ddwhdqljxn.mode = '测量'
                        ddwhdqljxn.standard = '≤0.2'
                        var changeObj = this.changeType(strike.ddwhdqljxn, ddwhdqljxn.standard)
                        ddwhdqljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwhdqljxn.result <= changeObj.changeStandard) {
                            ddwhdqljxn.determine = '符合要求'
                        } else {
                            ddwhdqljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwhdqljxn)
                    }
                    if (strike.dqgtxn && strike.testProject == '磁屏蔽') {
                        const dqgtxn = {}
                        dqgtxn.idx = this.addIdx(this.subregionFlag)
                        dqgtxn.point = strike.testPosition
                        dqgtxn.project = strike.testProject
                        dqgtxn.paramet = '电气贯通性能(Ω)'
                        dqgtxn.mode = '测量'
                        dqgtxn.standard = '≤1'
                        var changeObj = this.changeType(strike.dqgtxn, dqgtxn.standard)
                        dqgtxn.result = this.evenRound(changeObj.changeResult, 1)
                        if (dqgtxn.result <= changeObj.changeStandard) {
                            dqgtxn.determine = '符合要求'
                        } else {
                            dqgtxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqgtxn)
                    }
                    if (strike.pbxg && strike.testProject == '磁屏蔽' && strike.pbxg != '请选择') {
                        const pbxg = {}
                        pbxg.idx = this.addIdx(this.subregionFlag)
                        pbxg.point = strike.testPosition
                        pbxg.project = strike.testProject
                        pbxg.paramet = '屏蔽效果'
                        pbxg.mode = strike.pbxgjcfs
                        pbxg.result = strike.pbxg
                        pbxg.standard = 'GB/T21431-2023,5.5.4.4.1'
                        pbxg.determine = '符合要求'
                        this.changeData.push(pbxg)
                    }
                }
                //等电位连接相关数据
                if (equipotential.testProject == '防雷等电位连接') { 
                    if (equipotential.wz && equipotential.testProject == '防雷等电位连接') {
                        const wz = {}
                        wz.idx = this.addIdx(this.subregionFlag)
                        wz.point = equipotential.testPosition
                        wz.project = equipotential.testProject
                        wz.paramet = '位置'
                        wz.mode = '观察'
                        wz.result = equipotential.wz
                        wz.standard = 'GB/T21431-2023,5.5.5.1.1'
                        wz.determine = '参考项'
                        this.changeData.push(wz)
                    }
                    if (equipotential.jg && equipotential.testProject == '防雷等电位连接' && equipotential.jg != '请选择') {
                        const jg = {}
                        jg.idx = this.addIdx(this.subregionFlag)
                        jg.point = equipotential.testPosition
                        jg.project = equipotential.testProject
                        jg.paramet = '结构'
                        jg.mode = equipotential.jgjcfs
                        jg.result = equipotential.jg
                        jg.standard = 'S型连接/M型连接/组合型连接'
                        jg.determine = '符合要求'
                        this.changeData.push(jg)
                    }
                    if (equipotential.cl && equipotential.testProject == '防雷等电位连接') {
                        const cl = {}
                        cl.idx = this.addIdx(this.subregionFlag)
                        cl.point = equipotential.testPosition
                        cl.project = equipotential.testProject
                        cl.paramet = '材料'
                        cl.mode = equipotential.cljcfs
                        cl.result = equipotential.cl
                        cl.standard = 'GB50057-2010,5.1.1,5.1.2,5.2.1'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (equipotential.gg && equipotential.testProject == '防雷等电位连接') {
                        const gg = {}
                        gg.idx = this.addIdx(this.subregionFlag)
                        gg.point = equipotential.testPosition
                        gg.project = equipotential.testProject
                        gg.mode = equipotential.ggjcfs
                        if (equipotential.ggUnit) {
                            gg.paramet = `规格(${equipotential.ggUnit})`
                        }
                        if (equipotential.ggStandard == '参考项') {
                            gg.result = equipotential.gg
                            gg.standard = '/'
                            gg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(equipotential.gg, equipotential.ggStandard)
                            gg.result = this.evenRound(changeObj.changeResult, 0)
                            if (gg.result >= changeObj.changeStandard) {
                                gg.standard = equipotential.ggStandard
                                gg.determine = '符合要求'
                            } else {
                                gg.standard = equipotential.ggStandard
                                gg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(gg)
                    }
                    if (equipotential.ljgy && equipotential.testProject == '防雷等电位连接' && equipotential.ljgy != '请选择') {
                        const ljgy = {}
                        ljgy.idx = this.addIdx(this.subregionFlag)
                        ljgy.point = equipotential.testPosition
                        ljgy.project = equipotential.testProject
                        ljgy.paramet = '连接工艺'
                        ljgy.mode = '观察'
                        ljgy.result = equipotential.ljgy
                        ljgy.standard = 'GB/T21431-2023,5.5.5.4.1'
                        ljgy.determine = '符合要求'
                        this.changeData.push(ljgy)
                    }
                    if (equipotential.kjxn && equipotential.testProject == '防雷等电位连接') {
                        const kjxn = {}
                        kjxn.idx = this.addIdx(this.subregionFlag)
                        kjxn.point = equipotential.testPosition
                        kjxn.project = equipotential.testProject
                        kjxn.mode = '测量'
                        kjxn.standard = equipotential.kjxnStandard
                        var changeObj = this.changeType(equipotential.kjxn, kjxn.standard)
                        if (equipotential.kjxnUnit) { 
                            kjxn.paramet = `跨接性能(${equipotential.kjxnUnit})`
                            if(equipotential.kjxnUnit == 'mΩ'){
                                kjxn.result = this.evenRound(changeObj.changeResult, 0)
                            }else{
                                kjxn.result = this.evenRound(changeObj.changeResult, 3)
                            }
                        } 
                        if (kjxn.result <= changeObj.changeStandard) {
                            kjxn.determine = '符合要求'
                        } else {
                            kjxn.determine = '不符合要求'
                        }
                        this.changeData.push(kjxn)
                    }
                    if (equipotential.ddwljxn && equipotential.testProject == '防雷等电位连接') {
                        const ddwljxn = {}
                        ddwljxn.idx = this.addIdx(this.subregionFlag)
                        ddwljxn.point = equipotential.testPosition
                        ddwljxn.project = equipotential.testProject
                        ddwljxn.mode = '测量'
                        if (equipotential.ddwljxnUnit) {
                            ddwljxn.paramet = `等电位连接性能(${equipotential.ddwljxnUnit})`
                        }
                        ddwljxn.standard = equipotential.ddwljxnStandard
                        var changeObj = this.changeType(equipotential.ddwljxn, ddwljxn.standard)
                        ddwljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwljxn.result <= changeObj.changeStandard) {
                            ddwljxn.determine = '符合要求'
                        } else {
                            ddwljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwljxn)
                    }
                    if (equipotential.jdjzdjdxn && equipotential.testProject == '防雷等电位连接') {
                        const jdjzdjdxn = {}
                        jdjzdjdxn.idx = this.addIdx(this.subregionFlag)
                        jdjzdjdxn.point = equipotential.testPosition
                        jdjzdjdxn.project = equipotential.testProject
                        jdjzdjdxn.paramet = '接地基准点(ERP)接地性能(Ω)'
                        jdjzdjdxn.mode = '测量'
                        jdjzdjdxn.standard = equipotential.jdjzdjdxnStandard
                        var changeObj = this.changeType(equipotential.jdjzdjdxn, jdjzdjdxn.standard)
                        jdjzdjdxn.result = this.evenRound(changeObj.changeResult, 1)
                        if (jdjzdjdxn.result <= changeObj.changeStandard) {
                            jdjzdjdxn.determine = '符合要求'
                        } else {
                            jdjzdjdxn.determine = '不符合要求'
                        }
                        this.changeData.push(jdjzdjdxn)
                    }
                }
                //电源电涌保护器相关数据
                if (powersurge.testProject == '电源电涌保护器(SPD)') {  
                    if (powersurge.bz && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const bz = {}
                        bz.idx = this.addIdx(this.subregionFlag)
                        bz.point = powersurge.testPosition
                        bz.project = powersurge.testProject
                        bz.paramet = '布置'
                        bz.mode = '观察'
                        bz.result = powersurge.bz
                        bz.standard = 'GB/T21431-2023,5.5.6.1.1' 
                        bz.determine = '参考项'
                        this.changeData.push(bz)
                    }
                    if (powersurge.cpxh && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const cpxh = {}
                        cpxh.idx = this.addIdx(this.subregionFlag)
                        cpxh.point = powersurge.testPosition
                        cpxh.project = powersurge.testProject
                        cpxh.paramet = '产品型号'
                        cpxh.mode = '/'
                        cpxh.result = powersurge.cpxh
                        cpxh.standard = '/' 
                        cpxh.determine = '/'
                        this.changeData.push(cpxh)
                    } 
                    if(powersurge.ucL && !powersurge.ucN){ 
                        if (powersurge.ucL && powersurge.testProject == '电源电涌保护器(SPD)') {
                            const ucL = {}
                            ucL.idx = this.addIdx(this.subregionFlag)
                            ucL.point = powersurge.testPosition
                            ucL.project = powersurge.testProject
                            ucL.paramet = `最大持续工作电压Uc(${powersurge.zdcxgzdyucUnit})`
                            ucL.mode = '观察'
                            ucL.result = powersurge.ucL
                            ucL.standard = 'GB/T21431-2023,5.5.6.2.1' 
                            ucL.determine = '参考项'
                            this.changeData.push(ucL)
                        }
                    }else{
                        if (powersurge.ucL && powersurge.testProject == '电源电涌保护器(SPD)') {
                            const ucL = {}
                            ucL.idx = this.addIdx(this.subregionFlag)
                            ucL.point = powersurge.testPosition
                            ucL.project = powersurge.testProject
                            ucL.paramet = `最大持续工作电压Uc-L(${powersurge.zdcxgzdyucUnit})`
                            ucL.mode = '观察'
                            ucL.result = powersurge.ucL
                            ucL.standard = 'GB/T21431-2023,5.5.6.2.1' 
                            ucL.determine = '参考项'
                            this.changeData.push(ucL)
                        }
                        if (powersurge.ucN && powersurge.testProject == '电源电涌保护器(SPD)') {
                            const ucN = {}
                            ucN.idx = this.addIdx(this.subregionFlag)
                            ucN.point = powersurge.testPosition
                            ucN.project = powersurge.testProject
                            ucN.paramet = `最大持续工作电压Uc-N(${powersurge.zdcxgzdyucUnit})`
                            ucN.mode = '观察'
                            ucN.result = powersurge.ucN
                            ucN.standard = 'GB/T21431-2023,5.5.6.2.1' 
                            ucN.determine = '参考项'
                            this.changeData.push(ucN)
                        }
                    }
                    if (powersurge.sylx && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.sylx != '请选择') {
                        const sylx = {}
                        sylx.idx = this.addIdx(this.subregionFlag)
                        sylx.point = powersurge.testPosition
                        sylx.project = powersurge.testProject
                        sylx.paramet = '试验类型'
                        sylx.mode = '观察'
                        sylx.result = powersurge.sylx
                        sylx.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        sylx.determine = '参考项'
                        this.changeData.push(sylx)
                    }
                    if (powersurge.dybhspup && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.dybhspup != '请选择') {
                        const dybhspup = {}
                        dybhspup.idx = this.addIdx(this.subregionFlag)
                        dybhspup.point = powersurge.testPosition
                        dybhspup.project = powersurge.testProject
                        dybhspup.paramet = '电压保护水平Up(kV)'
                        dybhspup.mode = '观察'
                        dybhspup.result = powersurge.dybhspup
                        dybhspup.standard = 'GB/T21431-2023,5.5.6.2.1'  
                        dybhspup.determine = '参考项'
                        this.changeData.push(dybhspup)
                    }
                    if (powersurge.dybhspupN && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.dybhspupN != '请选择') {
                        const dybhspupN = {}
                        dybhspupN.idx = this.addIdx(this.subregionFlag)
                        dybhspupN.point = powersurge.testPosition
                        dybhspupN.project = powersurge.testProject
                        dybhspupN.paramet = '电压保护水平Up-N(kV)'
                        dybhspupN.mode = '观察'
                        dybhspupN.result = powersurge.dybhspup
                        dybhspupN.standard = 'GB/T21431-2023,5.5.6.2.1'  
                        dybhspupN.determine = '参考项'
                        this.changeData.push(dybhspupN)
                    }
                    if (powersurge.yxdybhspupf && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.yxdybhspupf != '请选择') {
                        const yxdybhspupf = {}
                        yxdybhspupf.idx = this.addIdx(this.subregionFlag)
                        yxdybhspupf.point = powersurge.testPosition
                        yxdybhspupf.project = powersurge.testProject
                        yxdybhspupf.paramet = '有效电压保护水平Up/f(kV)'
                        yxdybhspupf.mode = '观察'
                        yxdybhspupf.result = powersurge.yxdybhspupf
                        yxdybhspupf.standard = 'GB/T21431-2023,5.5.6.2.1'
                        yxdybhspupf.determine = '参考项'  
                        this.changeData.push(yxdybhspupf)
                    }
                    if (powersurge.bcfddlin && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.bcfddlin != '请选择') {
                        const bcfddlin = {}
                        bcfddlin.idx = this.addIdx(this.subregionFlag)
                        bcfddlin.point = powersurge.testPosition
                        bcfddlin.project = powersurge.testProject
                        bcfddlin.paramet = '标称放电电流In(kA)'
                        bcfddlin.mode = '观察'
                        bcfddlin.result = powersurge.bcfddlin
                        bcfddlin.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        bcfddlin.determine = '参考项'
                        this.changeData.push(bcfddlin)
                    }
                    if (powersurge.bcfddlinN && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.bcfddlinN != '请选择') {
                        const bcfddlinN = {}
                        bcfddlinN.idx = this.addIdx(this.subregionFlag)
                        bcfddlinN.point = powersurge.testPosition
                        bcfddlinN.project = powersurge.testProject
                        bcfddlinN.paramet = '标称放电电流In-N(kA)'
                        bcfddlinN.mode = '观察'
                        bcfddlinN.result = powersurge.bcfddlin
                        bcfddlinN.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        bcfddlinN.determine = '参考项'
                        this.changeData.push(bcfddlinN)
                    }
                    if (powersurge.zdfddlimax && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.zdfddlimax != '请选择') {
                        const zdfddlimax = {}
                        zdfddlimax.idx = this.addIdx(this.subregionFlag)
                        zdfddlimax.point = powersurge.testPosition
                        zdfddlimax.project = powersurge.testProject
                        zdfddlimax.paramet = '最大放电电流Imax(kA)'
                        zdfddlimax.mode = '观察'
                        zdfddlimax.result = powersurge.zdfddlimax
                        zdfddlimax.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        zdfddlimax.determine = '参考项'
                        this.changeData.push(zdfddlimax)
                    }
                    if (powersurge.zdfddlimaxN && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.zdfddlimaxN != '请选择') {
                        const zdfddlimaxN = {}
                        zdfddlimaxN.idx = this.addIdx(this.subregionFlag)
                        zdfddlimaxN.point = powersurge.testPosition
                        zdfddlimaxN.project = powersurge.testProject
                        zdfddlimaxN.paramet = '最大放电电流Imax-N(kA)'
                        zdfddlimaxN.mode = '观察'
                        zdfddlimaxN.result = powersurge.zdfddlimax
                        zdfddlimaxN.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        zdfddlimaxN.determine = '参考项'
                        this.changeData.push(zdfddlimaxN)
                    }
                    if (powersurge.cjdliimp && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.cjdliimp != '请选择') {
                        const cjdliimp = {}
                        cjdliimp.idx = this.addIdx(this.subregionFlag)
                        cjdliimp.point = powersurge.testPosition
                        cjdliimp.project = powersurge.testProject
                        cjdliimp.paramet = '冲击电流Iimp(kA)'
                        cjdliimp.mode = '观察'
                        cjdliimp.result = powersurge.cjdliimp
                        cjdliimp.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        cjdliimp.determine = '参考项'
                        this.changeData.push(cjdliimp)
                    }
                    if (powersurge.cjdliimpN && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.cjdliimpN != '请选择') {
                        const cjdliimpN = {}
                        cjdliimpN.idx = this.addIdx(this.subregionFlag)
                        cjdliimpN.point = powersurge.testPosition
                        cjdliimpN.project = powersurge.testProject
                        cjdliimpN.paramet = '冲击电流Iimp-N(kA)'
                        cjdliimpN.mode = '观察'
                        cjdliimpN.result = powersurge.cjdliimp
                        cjdliimpN.standard = 'GB/T21431-2023,5.5.6.2.1' 
                        cjdliimpN.determine = '参考项'
                        this.changeData.push(cjdliimpN)
                    }
                    if (powersurge.ljdtdcl && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdcl = {}
                        ljdtdcl.idx = this.addIdx(this.subregionFlag)
                        ljdtdcl.point = powersurge.testPosition
                        ljdtdcl.project = powersurge.testProject
                        ljdtdcl.paramet = '连接导体的材料'
                        ljdtdcl.mode = powersurge.ljdtdcljcfs
                        ljdtdcl.result = powersurge.ljdtdcl
                        ljdtdcl.standard = 'GB/T21431-2023,5.5.6.3.1' 
                        ljdtdcl.determine = '参考项'
                        this.changeData.push(ljdtdcl)
                    }
                    if (powersurge.ljdtdgg && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdgg = {}
                        ljdtdgg.idx = this.addIdx(this.subregionFlag)
                        ljdtdgg.point = powersurge.testPosition
                        ljdtdgg.project = powersurge.testProject
                        ljdtdgg.paramet = '连接导体的规格'
                        ljdtdgg.mode = powersurge.ljdtdggjcfs
                        ljdtdgg.result = powersurge.ljdtdgg
                        ljdtdgg.standard = 'GB/T21431-2023,5.5.6.3.1' 
                        ljdtdgg.determine = '参考项'
                        this.changeData.push(ljdtdgg)
                    }
                    if (powersurge.ljdtdjmmjxx && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdjmmjxx = {}
                        ljdtdjmmjxx.idx = this.addIdx(this.subregionFlag)
                        ljdtdjmmjxx.point = powersurge.testPosition
                        ljdtdjmmjxx.project = powersurge.testProject
                        ljdtdjmmjxx.paramet = '连接导体的截面面积(相线)（mm²）'
                        ljdtdjmmjxx.mode = powersurge.ljdtdjmmjxxjcfs
                        ljdtdjmmjxx.standard = powersurge.ljdtdjmmjxxStandard 
                        var changeObj = this.changeType(powersurge.ljdtdjmmjxx, ljdtdjmmjxx.standard)
                        ljdtdjmmjxx.result = changeObj.changeResult
                        if (ljdtdjmmjxx.result >= changeObj.changeStandard) {
                            ljdtdjmmjxx.determine = '符合要求'
                        } else {
                            ljdtdjmmjxx.determine = '不符合要求'
                        }
                        this.changeData.push(ljdtdjmmjxx)
                    }
                    if (powersurge.ljdtdjmmjjdx && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdjmmjjdx = {}
                        ljdtdjmmjjdx.idx = this.addIdx(this.subregionFlag)
                        ljdtdjmmjjdx.point = powersurge.testPosition
                        ljdtdjmmjjdx.project = powersurge.testProject
                        ljdtdjmmjjdx.paramet = '连接导体的截面面积(接地线)'
                        ljdtdjmmjjdx.mode = powersurge.ljdtdjmmjjdxjcfs 
                        ljdtdjmmjjdx.standard = powersurge.ljdtdjmmjjdxStandard
                        var changeObj = this.changeType(powersurge.ljdtdjmmjjdx, ljdtdjmmjjdx.standard)
                        ljdtdjmmjjdx.result = changeObj.changeResult
                        if (ljdtdjmmjjdx.result >= changeObj.changeStandard) {
                            ljdtdjmmjjdx.determine = '符合要求'
                        } else {
                            ljdtdjmmjjdx.determine = '不符合要求'
                        }
                        this.changeData.push(ljdtdjmmjjdx)
                    }
                    if (powersurge.ljdtys && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ljdtys != '请选择') {
                        const ljdtys = {}
                        ljdtys.idx = this.addIdx(this.subregionFlag)
                        ljdtys.point = powersurge.testPosition
                        ljdtys.project = powersurge.testProject
                        ljdtys.paramet = '连接导体颜色'
                        ljdtys.mode = '观察'
                        ljdtys.result = powersurge.ljdtys 
                        ljdtys.standard = 'GB/T21431-2023,5.5.6.3.1'
                        if(powersurge.ljdtys == '不符合要求'){
                        ljdtys.determine = '不符合要求'
                        }else{
                        ljdtys.determine = '符合要求'
                        }
                        this.changeData.push(ljdtys)
                    }
                    if (powersurge.ldyxcdzh && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ldyxcdzh = {}
                        ldyxcdzh.idx = this.addIdx(this.subregionFlag)
                        ldyxcdzh.point = powersurge.testPosition
                        ldyxcdzh.project = powersurge.testProject
                        ldyxcdzh.paramet = '两端引线长度之和(m)'
                        ldyxcdzh.mode = powersurge.ldyxcdzhjcfs 
                        ldyxcdzh.standard = '≤0.5' 
                        ldyxcdzh.result = powersurge.ldyxcdzh 
                        ldyxcdzh.determine = '参考项'
                        this.changeData.push(ldyxcdzh)
                    }
                    if (powersurge.ljgy && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ljgy != '请选择') {
                        const ljgy = {}
                        ljgy.idx = this.addIdx(this.subregionFlag)
                        ljgy.point = powersurge.testPosition
                        ljgy.project = powersurge.testProject
                        ljgy.paramet = '连接工艺'
                        ljgy.mode = '观察'
                        ljgy.result = powersurge.ljgy 
                        ljgy.standard = '应连接牢固'
                        if(powersurge.ljgy == '连接牢固'){
                            ljgy.determine = '符合要求'
                        }else{
                            ljgy.determine = '不符合要求'
                        }
                        this.changeData.push(ljgy)
                    }
                    if (powersurge.wg && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.wg != '请选择') {
                        const wg = {}
                        wg.idx = this.addIdx(this.subregionFlag)
                        wg.point = powersurge.testPosition
                        wg.project = powersurge.testProject
                        wg.paramet = '外观'
                        wg.mode = '观察'
                        wg.result = powersurge.wg 
                        wg.standard = '外观表面应平整、光洁、无划伤、无裂痕和烧灼痕或变形，标识应完整和清晰'
                        if(powersurge.wg == '外观良好，标识完整清晰'){
                            wg.determine = '符合要求'
                        }else{
                            wg.determine = '不符合要求'
                        }
                        this.changeData.push(wg)
                    }
                    if (powersurge.ztzsq && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ztzsq != '请选择') {
                        const ztzsq = {}
                        ztzsq.idx = this.addIdx(this.subregionFlag)
                        ztzsq.point = powersurge.testPosition
                        ztzsq.project = powersurge.testProject
                        ztzsq.paramet = '状态指示器'
                        ztzsq.mode = '观察'
                        ztzsq.result = powersurge.ztzsq 
                        ztzsq.standard = '状态指示应正常'
                        if(powersurge.ztzsq == '正常'){
                            ztzsq.determine = '符合要求'
                        }else{
                            ztzsq.determine = '不符合要求'
                        }
                        this.changeData.push(ztzsq)
                    }
                    if (powersurge.fhjs && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.fhjs != '请选择') {
                        const fhjs = {}
                        fhjs.idx = this.addIdx(this.subregionFlag)
                        fhjs.point = powersurge.testPosition
                        fhjs.project = powersurge.testProject
                        fhjs.paramet = '防护级数'
                        fhjs.mode = '观察'
                        fhjs.result = powersurge.fhjs
                        fhjs.standard = 'GB/T21431-2023,5.5.6.6.1' 
                        fhjs.determine = '参考项'
                        this.changeData.push(fhjs)
                    }
                    if (powersurge.jjph && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jjph = {}
                        jjph.idx = this.addIdx(this.subregionFlag)
                        jjph.point = powersurge.testPosition
                        jjph.project = powersurge.testProject
                        jjph.paramet = '级间配合(m)'
                        jjph.mode = powersurge.jjphjcfs
                        jjph.standard = powersurge.jjph 
                        var changeObj = this.changeType(powersurge.jjph, jjph.standard)
                        jjph.result = this.evenRound(changeObj.changeResult, 1)
                        if (jjph.result <= changeObj.changeStandard) {
                            jjph.determine = '符合要求'
                        } else {
                            jjph.determine = '不符合要求'
                        }
                        this.changeData.push(jjph)
                    }
                    if (powersurge.wbtlqgdlbh && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.wbtlqgdlbh != '请选择') {
                        const wbtlqgdlbh = {}
                        wbtlqgdlbh.idx = this.addIdx(this.subregionFlag)
                        wbtlqgdlbh.point = powersurge.testPosition
                        wbtlqgdlbh.project = powersurge.testProject
                        wbtlqgdlbh.paramet = '外部脱离器(过电流保护)'
                        wbtlqgdlbh.mode = '观察' 
                        wbtlqgdlbh.result = powersurge.wbtlqgdlbh
                        wbtlqgdlbh.standard = 'GB/T21431-2023,5.5.6.7.1'
                        if(powersurge.wbtlqgdlbh == '未安装'){
                            wbtlqgdlbh.determine = '不符合要求'
                        }else{
                            wbtlqgdlbh.determine = '符合要求'
                        }
                        this.changeData.push(wbtlqgdlbh)
                    }
                    if (powersurge.dqljxn && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const dqljxn = {}
                        dqljxn.idx = this.addIdx(this.subregionFlag)
                        dqljxn.point = powersurge.testPosition
                        dqljxn.project = powersurge.testProject
                        dqljxn.paramet = '电气连接性能(Ω) '
                        dqljxn.mode = '测量'
                        dqljxn.standard = '≤0.2' 
                        var changeObj = this.changeType(powersurge.dqljxn, dqljxn.standard)
                        dqljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (dqljxn.result <= changeObj.changeStandard) {
                            dqljxn.determine = '符合要求'
                        } else {
                            dqljxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqljxn)
                    }

                    if (powersurge.ymdyL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL1 = {}
                        ymdyL1.idx = this.addIdx(this.subregionFlag)
                        ymdyL1.point = powersurge.testPosition
                        ymdyL1.project = powersurge.testProject
                        ymdyL1.paramet = '压敏电压-L1(Vᵥ)'
                        ymdyL1.mode = '测量' 
                        ymdyL1.standard = powersurge.ymdyLStandard
                        var changeObj = this.changeType(powersurge.ymdyL1, ymdyL1.standard)
                        ymdyL1.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL1.standard == '参考项') {
                            ymdyL1.standard = '/'
                            ymdyL1.determine = '参考项'
                        } else {
                            if (powersurge.ymdyLStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL1.result <= maxu1m && ymdyL1.result >= minu1m) {
                                    ymdyL1.determine = '符合要求'
                                } else {
                                    ymdyL1.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL1.result >= changeObj.changeStandard) {
                                    ymdyL1.determine = '符合要求'
                                } else {
                                    ymdyL1.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL1)
                    }
                    if (powersurge.ymdyL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL2 = {}
                        ymdyL2.idx = this.addIdx(this.subregionFlag)
                        ymdyL2.point = powersurge.testPosition
                        ymdyL2.project = powersurge.testProject
                        ymdyL2.paramet = '压敏电压-L2(Vᵥ)'
                        ymdyL2.mode = '测量' 
                        ymdyL2.standard = powersurge.ymdyLStandard
                        var changeObj = this.changeType(powersurge.ymdyL2, ymdyL2.standard)
                        ymdyL2.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL2.standard == '参考项') {
                            ymdyL2.standard = '/'
                            ymdyL2.determine = '参考项'
                        } else {
                            if (powersurge.ymdyLStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL2.result <= maxu1m && ymdyL2.result >= minu1m) {
                                    ymdyL2.determine = '符合要求'
                                } else {
                                    ymdyL2.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL2.result >= changeObj.changeStandard) {
                                    ymdyL2.determine = '符合要求'
                                } else {
                                    ymdyL2.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL2)
                    }
                    if (powersurge.ymdyL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL3 = {}
                        ymdyL3.idx = this.addIdx(this.subregionFlag)
                        ymdyL3.point = powersurge.testPosition
                        ymdyL3.project = powersurge.testProject
                        ymdyL3.paramet = '压敏电压-L3(Vᵥ)'
                        ymdyL3.mode = '测量' 
                        ymdyL3.standard = powersurge.ymdyLStandard
                        var changeObj = this.changeType(powersurge.ymdyL3, ymdyL3.standard)
                        ymdyL3.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL3.standard == '参考项') {
                            ymdyL3.standard = '/'
                            ymdyL3.determine = '参考项'
                        } else {
                            if (powersurge.ymdyLStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL3.result <= maxu1m && ymdyL3.result >= minu1m) {
                                    ymdyL3.determine = '符合要求'
                                } else {
                                    ymdyL3.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL3.result >= changeObj.changeStandard) {
                                    ymdyL3.determine = '符合要求'
                                } else {
                                    ymdyL3.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL3)
                    }
                    if (powersurge.ymdyN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyN = {}
                        ymdyN.idx = this.addIdx(this.subregionFlag)
                        ymdyN.point = powersurge.testPosition
                        ymdyN.project = powersurge.testProject
                        ymdyN.paramet = '压敏电压-N(Vᵥ)'
                        ymdyN.mode = '测量' 
                        if (powersurge.ymdyNStandard) {
                            ymdyN.standard = powersurge.ymdyNStandard
                        } else {
                            ymdyN.standard = powersurge.ymdyNStandard
                        }
                        var changeObj = this.changeType(powersurge.ymdyN, ymdyN.standard)
                        ymdyN.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyN.standard == '参考项') {
                            ymdyN.standard = '/'
                            ymdyN.determine = '参考项'
                        } else {
                            if (powersurge.ymdyNStandard) {  //判断之前没有u1maNStandard参数数据出错问题
                                if (powersurge.ymdyNStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (ymdyN.result <= maxu1m && ymdyN.result >= minu1m) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                } else {
                                    if (ymdyN.result >= changeObj.changeStandard) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                }
                            } else {
                                if (powersurge.ymdyNStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (ymdyN.result <= maxu1m && ymdyN.result >= minu1m) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                } else {
                                    if (ymdyN.result >= changeObj.changeStandard) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                }
                            }

                        }
                        this.changeData.push(ymdyN)
                    }
                    if (powersurge.xldlL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL1 = {}
                        xldlL1.idx = this.addIdx(this.subregionFlag)
                        xldlL1.point = powersurge.testPosition
                        xldlL1.project = powersurge.testProject
                        xldlL1.paramet = '泄漏电流-L1(Iₗₑ)'
                        xldlL1.mode = '测量' 
                        xldlL1.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL1, xldlL1.standard)
                        xldlL1.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL1.standard == '参考项') {
                            xldlL1.standard = '/'
                            xldlL1.determine = '参考项'
                        } else {
                            if (xldlL1.result <= changeObj.changeStandard) {
                                xldlL1.determine = '符合要求'
                            } else {
                                xldlL1.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL1)
                    }
                    if (powersurge.xldlL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL2 = {}
                        xldlL2.idx = this.addIdx(this.subregionFlag)
                        xldlL2.point = powersurge.testPosition
                        xldlL2.project = powersurge.testProject
                        xldlL2.paramet = '泄漏电流-L2(Iₗₑ)'
                        xldlL2.mode = '测量' 
                        xldlL2.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL2, xldlL2.standard)
                        xldlL2.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL2.standard == '参考项') {
                            xldlL2.standard = '/'
                            xldlL2.determine = '参考项'
                        } else {
                            if (xldlL2.result <= changeObj.changeStandard) {
                                xldlL2.determine = '符合要求'
                            } else {
                                xldlL2.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL2)
                    }
                    if (powersurge.xldlL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL3 = {}
                        xldlL3.idx = this.addIdx(this.subregionFlag)
                        xldlL3.point = powersurge.testPosition
                        xldlL3.project = powersurge.testProject
                        xldlL3.paramet = '泄漏电流-L3(Iₗₑ)'
                        xldlL3.mode = '测量' 
                        xldlL3.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL3, xldlL3.standard)
                        xldlL3.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL3.standard == '参考项') {
                            xldlL3.standard = '/'
                            xldlL3.determine = '参考项'
                        } else {
                            if (xldlL3.result <= changeObj.changeStandard) {
                                xldlL3.determine = '符合要求'
                            } else {
                                xldlL3.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL3)
                    }
                    if (powersurge.xldlN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlN = {}
                        xldlN.idx = this.addIdx(this.subregionFlag)
                        xldlN.point = powersurge.testPosition
                        xldlN.project = powersurge.testProject
                        xldlN.paramet = '泄漏电流-N(Iₗₑ)'
                        xldlN.mode = '测量' 
                        xldlN.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlN, xldlN.standard)
                        xldlN.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlN.standard == '参考项') {
                            xldlN.standard = '/'
                            xldlN.determine = '参考项'
                        } else {
                            if (xldlN.result <= changeObj.changeStandard) {
                                xldlN.determine = '符合要求'
                            } else {
                                xldlN.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlN)
                    }
                    if (powersurge.jydzL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL1 = {}
                        jydzL1.idx = this.addIdx(this.subregionFlag)
                        jydzL1.point = powersurge.testPosition
                        jydzL1.project = powersurge.testProject
                        jydzL1.paramet = '绝缘电阻-L1(N)(MΩ)'
                        jydzL1.mode = '测量' 
                        jydzL1.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL1, jydzL1.standard)
                        jydzL1.result = powersurge.jydzL1
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL1.determine = '符合要求'
                        } else {
                            jydzL1.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL1)
                    }
                    if (powersurge.jydzL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL2 = {}
                        jydzL2.idx = this.addIdx(this.subregionFlag)
                        jydzL2.point = powersurge.testPosition
                        jydzL2.project = powersurge.testProject
                        jydzL2.paramet = '绝缘电阻-L2(N)(MΩ)'
                        jydzL2.mode = '测量' 
                        jydzL2.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL2, jydzL2.standard)
                        jydzL2.result = powersurge.jydzL2
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL2.determine = '符合要求'
                        } else {
                            jydzL2.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL2)
                    }
                    if (powersurge.jydzL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL3 = {}
                        jydzL3.idx = this.addIdx(this.subregionFlag)
                        jydzL3.point = powersurge.testPosition
                        jydzL3.project = powersurge.testProject
                        jydzL3.paramet = '绝缘电阻-L3(N)(MΩ)'
                        jydzL3.mode = '测量' 
                        jydzL3.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL3, jydzL3.standard)
                        jydzL3.result = powersurge.jydzL3
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL3.determine = '符合要求'
                        } else {
                            jydzL3.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL3)
                    }
                    if (powersurge.jydzN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzN = {}
                        jydzN.idx = this.addIdx(this.subregionFlag)
                        jydzN.point = powersurge.testPosition
                        jydzN.project = powersurge.testProject
                        jydzN.paramet = '绝缘电阻 (N)(MΩ)'
                        jydzN.mode = '测量' 
                        jydzN.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzN, jydzN.standard)
                        jydzN.result = powersurge.jydzN
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzN.determine = '符合要求'
                        } else {
                            jydzN.determine = '不符合要求'
                        }
                        this.changeData.push(jydzN)
                    }
                    if (powersurge.photo && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const photo = {}
                        let imgsrcs = powersurge.photo.split('|')
                        for (let i in imgsrcs) {
                            if (imgsrcs[i] != null && imgsrcs[i] != '') {
                                this.$axios({
                                    method: 'get',
                                    url: '/lps/hwcloud/obs/downurl',
                                    params: {
                                        objname: imgsrcs[i]
                                    }
                                }).then(res => {
                                    if (res.data != null) {
                                        // this.$set(photo, 'point' , powersurge.testPosition)
                                        // this.$set(photo, 'project', powersurge.testProject)
                                        photo.idx = this.addIdx(this.subregionFlag)
                                        photo.point = powersurge.testPosition
                                        photo.project = powersurge.testProject
                                        this.$set(photo, 'imgurl', res.data.url)
                                        this.$set(photo, 'paramet', 'SPD现场图片')
                                        this.$set(photo, 'type', 'photo')
                                        this.urlList.push(res.data.url)
                                    }
                                })
                            }
                        }
                        this.changeData.push(photo)
                    }
                }
                //防雷分区
                if (lightning.flfq && lightning.testProject == '防雷分区') {
                    const flfq = {}
                    flfq.idx = this.addIdx(this.subregionFlag)
                    // flfq.point = powersurge.testPosition
                    flfq.project = powersurge.testProject
                    flfq.paramet = '防雷分区'
                    flfq.mode = '观察'
                    flfq.result = lightning.flfq
                    flfq.standard = '/'
                    flfq.determine = '参考项'
                    this.changeData.push(flfq)
                }
            }
            this.getSpanArr(this.changeData)
            // console.log(this.changeData) 
        },
    }
}
</script>
<style scoped>
@media print {
    #page {
        display: none;
    } 

    .el-table>>>.my-cell {
      text-align: center;
      border-left: 0.5px solid rgb(32, 32, 32) !important;
    }

    .el-table>>>.el-table__cell {
      border-right: 0.5px solid rgb(32, 32, 32) !important;
      border-bottom: 0.5px solid rgb(32, 32, 32) !important;
    }

    ::v-deep .el-table th {
      border-top: 1px solid rgb(32, 32, 32) !important;
    }
}

@media screen {
  #print {
      display: none;
  }
  .el-table>>>.my-cell {
      vertical-align: top;
      text-align: center;
      height: 65px;
  }
}


</style>