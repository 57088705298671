<!--
 * @Descripttion: 
 * @version: 
 * @Author: 白一桐
 * @Date: 2023-02-07 11:05:30
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-10-09 11:35:57
-->
<!-- <template>
  <div style="width: 190mm;height:60px;margin: 0 auto;font-family:'song'; padding-bottom: 10px;margin-top:50px;">
    <div style="width:190mm;height: 30px;padding-bottom: 10px; ">
      <div style="float: left;width: 1px;margin-right: 60px;padding-left: 30px;">
        <img src="../assets/logo.png" alt="" width="30px">
      </div>
      <div style="text-align: center;float: left;font-size: 25px; line-height: 35px;"> 
        <slot name="printheader">
          <p></p>
        </slot>
      </div>
    </div>
    <div style="">
      <div style="width: 370px;margin-bottom: 15px;font-size: 19px;margin-right:140px ;">
        <slot name="reportcode"></slot>
      </div>
      <div
           style="width: 300px;font-size: 19px; float: right;">
        <slot name="page"></slot>
      </div>
    </div>
  </div>
</template> -->
<template>
  <div style="width: 190mm;height:60px;margin: 0 auto;font-family:'song'; padding-bottom: 10px;margin-top:50px;">
    <div style="width:190mm;height: 30px;padding-bottom: 10px; ">
      <div style="float: left;width: 1px;margin-right: 60px;padding-left: 30px;">
        <img src="../assets/logo.png" alt="" width="30px">
      </div>
      <div style="text-align: center;float: left;font-size: 25px; line-height: 35px;">
        <!-- <p>河北德创检测服务有限公司雷电防护装置检测报告</p> -->
        <slot name="printheader">
          <p></p>
        </slot>
      </div>
    </div>
    <div>
      <div style="width: 380px;float:left ; margin-bottom: 15px;font-size: 19px;">
        <slot name="reportcode"></slot>
      </div>
      <div style="width: 180px; float: right;font-size: 19px; ">

        <slot name="page"></slot>
        <!-- 第一页共3页 -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: ''
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style>
/* body{
  color:slateblue;
} */
</style>
