/*
* @Author: 王翎爆
* @Date: 2023-03-30 09:37:53
* @Last Modified by: 王翎爆
* @Last Modified time: 2023-04-21 17:27:36
*/
<template>
    <div>
        <div id="ftable" v-if="reportTemplate === '001'">
            <div id="fqtotal">
                <div id="fq">
                    <p>防雷分区</p>
                </div>
                <div id="fqcenter">
                    <p>{{ SubrgionName.areaName
                        }}({{ SubrgionName.areaCode }}),等电位连接测试基准点：{{ SubrgionName.eqConnectDatumPoint }}</p>
                </div>
            </div>
            <div id="tableesdorse" contenteditable="true" @blur="leaveContent" @focus="focusContent">{{ backesdorse }}
            </div>
        </div>
        <el-table :data="changeData" border style="width: 100% " ref="table"
            :header-cell-style="{ 'text-align': 'center' }" :span-method="objectSpanMethod">
            <el-table-column prop="point" label="检测点" className="my-cell">
            </el-table-column>
            <el-table-column prop="project" label="检测项目" className="my-cell">
            </el-table-column>
            <el-table-column prop="paramet" label="检测参数">
            </el-table-column>
            <el-table-column prop="result" label="检测结果">
            </el-table-column>
            <el-table-column prop="standard" label="标准值">
                <template slot-scope="scope">
                    <div v-if="scope.row.type == 'photo'" key="standard">
                        <el-image style="width: 35mm; height: 35mm" :src="scope.row.imgurl" :preview-src-list="urlList">
                        </el-image>
                    </div>
                    <input :value="scope.row.standard" v-else disabled>
                </template>
            </el-table-column>
            <el-table-column prop="determine" label="结果判定">
            </el-table-column>
            <el-table-column label="标注">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.mark" @blur="inputContent(scope.$index, scope.row)"
                        @focus="focusInputContent(scope.$index, scope.row)">
                    </el-input>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name: 'ReportSubrgion',
    props: ['SubrgionName', 'endorseMessage', 'auditMessage', 'reportTemplate'],
    data() {
        return {
            status: true,
            spanArr: [],
            pos: null,
            mark: '',
            changeData: [],
            backesdorse: '',
            inputIndex: '',
            urlList: [],
            photo: {},
            focusData: ''
        };
    },
    components: {
    },
    created() {
        this.$bus.$on('Subrgionchangeexaminecontents', this.changeexaminecontents)
    },
    mounted() {
        //var a = Date.now()
        //for(var i = 0;i<200;i++){
        //    console.log(Date.now());
        //    if(i%2 == 1){
        //        console.log(i);
        //    }else{
        //        console.log("aaaaaaaa");
        //    }
        //}
        //console.log(Date.now()-a);
        this.changeDataForm()
        // this.changeexaminecontents()
        // let result=this.evenRound(32.5,0)
        // console.log(result);
        // console.log(this.auditMessage);
    },
    methods: {
        ass(data) {
            console.log(data)
            if (data.type) {
                this.img = true
            }
        },
        //修约(四舍六入五成双)
        evenRound(num, decimalPlaces) {
            var d = decimalPlaces || 0;
            var m = Math.pow(10, d);
            var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
            var i = Math.floor(n), f = n - i;
            var e = 1e-8; // Allow for rounding errors in f
            var r = (f > 0.5 - e && f < 0.5 + e) ?
                ((i % 2 == 0) ? i : i + 1) : Math.round(n);
            var result = d ? r / m : r;
            if (decimalPlaces > 0) {
                var s_x = result.toString();
                var pos_decimal = s_x.indexOf(".");
                if (pos_decimal < 0) {
                    pos_decimal = s_x.length;
                    s_x += ".";
                }
                while (s_x.length <= pos_decimal + decimalPlaces) {
                    s_x += "0";
                }
                return s_x;
            } else {
                return result;
            }
        },
        //单元格合并
        getSpanArr(data) {
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].point === data[i - 1].point && data[i].project === data[i - 1].project) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                }
                // console.log(this.spanArr)
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0 || columnIndex === 1) {
                const _row = this.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                // console.log(`rowspan:${_row} colspan:${_col}`)
                return { // [0,0] 表示这一行不显示,被合并了  [2,1]表示行的合并数
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        //  aa(i){
        //    console.log(i);
        //    if(i>10000){
        //        return
        //    }
        //    i = i+1
        //    console.log(i);
        //    this.aa(i)
        // },
        // change(e) {
        // },
        focusContent(event) {
            this.focusData = event.target.innerText.trim()
        },
        focusInputContent(index, row) {
            this.focusData = row.mark

        },
        //获取批注框信息
        leaveContent(event) {
            let textareaContent = event.target.innerText.trim()
            // console.log(textareaContent);
            if (this.focusData != textareaContent) {
                this.$emit('change', this.endorseMessage + `.areaCode`, textareaContent)
                this.$emit('auditChange', this.auditMessage + '：', textareaContent)
            }
        },
        //获取表格批注信息
        inputContent(index, row) {
            console.log(row.point, index);
            let inputContent = row.mark
            if (this.focusData != inputContent) {
                console.log(inputContent);
                this.$emit('change', this.endorseMessage + `.sourceDataList.` + index, inputContent)
                this.$emit('auditChange', this.auditMessage + '-' + row.point + '-' + row.project + '-' + row.paramet + '：', inputContent)
            }
        },
        // 将检测结果和标准值的字符串类型改为数值类型
        changeType(result, standard) {
            // console.log(result);
            var changeResult = result.match(/\d+(\.\d+)?/g)
            // console.log(changeResult);
            if (changeResult != null) {
                changeResult = changeResult.map(Number)
                changeResult = changeResult[0]
            } else {
                changeResult = result
            }
            if (standard) {
                // console.log(standard)
                var changeStandard = standard.match(/\d+(\.\d+)?/g)
                if (changeStandard != null) {
                    // console.log(changeStandard)
                    changeStandard = changeStandard.map(Number)
                    changeStandard = changeStandard[0]
                } else {
                    changeStandard = standard
                }

            }
            return { changeResult, changeStandard }
        },
        //分区数据格式转换
        changeDataForm() {
            // console.log(this.reportTemplate);
            // console.log('分区数据：',this.SubrgionName.sourceDataList);
            if (this.reportTemplate === "001") {
                for (let i = 0; i < this.SubrgionName.sourceDataList.length; i++) {
                    let subregion = this.SubrgionName.sourceDataList[i]
                    let downlead = this.SubrgionName.sourceDataList[i]
                    let earthing = this.SubrgionName.sourceDataList[i]
                    let strike = this.SubrgionName.sourceDataList[i]
                    let powersurge = this.SubrgionName.sourceDataList[i]
                    let equipotential = this.SubrgionName.sourceDataList[i]
                    if (subregion.airTerminationType && subregion.testProject == '接闪器' && subregion.airTerminationType != '请选择') {
                        const airTerminationType = {}
                        airTerminationType.point = subregion.testPosition
                        airTerminationType.project = subregion.testProject
                        airTerminationType.paramet = '类型'
                        airTerminationType.result = subregion.airTerminationType
                        airTerminationType.standard = '/'
                        airTerminationType.determine = '参考项'
                        this.changeData.push(airTerminationType)
                    }
                    if (subregion.layingMethod && subregion.testProject == '接闪器' && subregion.layingMethod != '请选择') {
                        const layingMethod = {}
                        layingMethod.point = subregion.testPosition
                        layingMethod.project = subregion.testProject
                        layingMethod.paramet = '敷设方式'
                        layingMethod.result = subregion.layingMethod
                        layingMethod.standard = '/'
                        layingMethod.determine = '参考项'
                        this.changeData.push(layingMethod)
                    }
                    if (subregion.material && subregion.testProject == '接闪器') {
                        const material = {}
                        material.point = subregion.testPosition
                        material.project = subregion.testProject
                        material.paramet = '材料'
                        material.result = subregion.material
                        material.standard = '/'
                        material.determine = '参考项'
                        this.changeData.push(material)
                    }
                    if (subregion.specification && subregion.testProject == '接闪器') {
                        const specification = {}
                        specification.point = subregion.testPosition
                        specification.project = subregion.testProject
                        if (subregion.specification.includes('mm²')) {
                            specification.paramet = '规格(mm²)'
                        } else {
                            specification.paramet = '规格(mm)'
                        }
                        if (subregion.specificationStandard == '参考项') {
                            specification.result = subregion.specification
                            specification.standard = '/'
                            specification.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(subregion.specification, subregion.specificationStandard)
                            specification.result = this.evenRound(changeObj.changeResult, 0)
                            if (specification.result >= changeObj.changeStandard) {
                                specification.standard = subregion.specificationStandard
                                specification.determine = '符合要求'
                            } else {
                                specification.standard = subregion.specificationStandard
                                specification.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(specification)
                    }
                    if (subregion.installationMethod && subregion.testProject == '接闪器' && subregion.installationMethod != '请选择') {
                        const installationMethod = {}
                        installationMethod.point = subregion.testPosition
                        installationMethod.project = subregion.testProject
                        installationMethod.paramet = '安装方式'
                        installationMethod.result = subregion.installationMethod
                        installationMethod.standard = '/'
                        installationMethod.determine = '参考项'
                        this.changeData.push(installationMethod)
                    }
                    if (subregion.bracketDistance && subregion.testProject == '接闪器') {
                        const bracketDistance = {}
                        bracketDistance.point = subregion.testPosition
                        bracketDistance.project = subregion.testProject
                        bracketDistance.paramet = '固定支架间距(m)'
                        bracketDistance.standard = subregion.bracketDistanceStandard
                        var changeObj = this.changeType(subregion.bracketDistance, bracketDistance.standard)
                        bracketDistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (bracketDistance.result <= changeObj.changeStandard) {
                            bracketDistance.determine = '符合要求'
                        } else {
                            bracketDistance.determine = '不符合要求'
                        }
                        this.changeData.push(bracketDistance)
                    }
                    if (subregion.bracketHeight && subregion.testProject == '接闪器') {
                        const bracketHeight = {}
                        bracketHeight.point = subregion.testPosition
                        bracketHeight.project = subregion.testProject
                        bracketHeight.paramet = '固定支架高度(mm)'
                        bracketHeight.standard = subregion.bracketHeightStandard
                        var changeObj = this.changeType(subregion.bracketHeight, bracketHeight.standard)
                        bracketHeight.result = this.evenRound(changeObj.changeResult, 0)
                        if (bracketHeight.result >= changeObj.changeStandard) {
                            bracketHeight.determine = '符合要求'
                        } else {
                            bracketHeight.determine = '不符合要求'
                        }
                        this.changeData.push(bracketHeight)
                    }
                    if (subregion.verticalPull && subregion.testProject == '接闪器') {
                        const verticalPull = {}
                        verticalPull.point = subregion.testPosition
                        verticalPull.project = subregion.testProject
                        verticalPull.paramet = '网带支持件承受垂直拉力(N)'
                        verticalPull.standard = subregion.verticalPullStandard
                        var changeObj = this.changeType(subregion.verticalPull, verticalPull.standard)
                        verticalPull.result = this.evenRound(changeObj.changeResult, 0)
                        if (verticalPull.result >= changeObj.changeStandard) {
                            verticalPull.determine = '符合要求'
                        } else {
                            verticalPull.determine = '不符合要求'
                        }
                        this.changeData.push(verticalPull)
                    }
                    if (subregion.gridSize && subregion.testProject == '接闪器') {
                        const gridSize = {}
                        gridSize.point = subregion.testPosition
                        gridSize.project = subregion.testProject
                        gridSize.paramet = '网格尺寸(m)'
                        gridSize.standard = subregion.gridSizeStandard
                        var cresult = subregion.gridSize.match(/(-?\d+)(\.\d+)?/g)
                        cresult = cresult.map(Number)
                        //计算检测结果中的最大值和最小值
                        var mincresult = Math.min(...cresult)
                        var maxcresult = Math.max(...cresult)
                        var gone = this.evenRound(mincresult, 1)
                        var gtwo = this.evenRound(maxcresult, 1)
                        var cresults = gone + '×' + gtwo
                        gridSize.result = cresults
                        var cstandard = subregion.gridSizeStandard.match(/\d+(\.\d+)?/g)
                        cstandard = cstandard.map(Number)
                        //取尺寸检测结果的最大值
                        // var bignumber = gone
                        // for (let i = 0; i < cresult.length; i++) {
                        //     if (bignumber < cresult[i]) {
                        //         bignumber = this.evenRound(cresult[i], 1)
                        //     }
                        // }
                        // console.log(bignumber);
                        // 比较标准值中后两个参数的大小
                        var minnumber
                        var maxnumber
                        if (cstandard[2] > cstandard[3]) {
                            minnumber = cstandard[3]
                            maxnumber = cstandard[2]
                        } else {
                            minnumber = cstandard[2]
                            maxnumber = cstandard[3]
                        }
                        // for (let i = 0; i < cstandard.length; i++) {
                        //     if (minnumber > cstandard[i]) {
                        //         minnumber = cstandard[i]
                        //     }
                        // }
                        // 检测结果中的最大值小于标准值中第一个值，或结果最小值小于标准中最小值、最大值小于标准最大值则符合要求，否则不符合
                        if (maxcresult <= cstandard[0] || mincresult <= minnumber && maxcresult <= maxnumber) {
                            gridSize.determine = '符合要求'
                        } else {
                            gridSize.determine = '不符合要求'
                        }
                        this.changeData.push(gridSize)
                    }
                    if (subregion.welding && subregion.testProject == '接闪器' && subregion.welding != '请选择') {
                        const welding = {}
                        welding.point = subregion.testPosition
                        welding.project = subregion.testProject
                        welding.paramet = '焊接固定'
                        welding.result = subregion.welding
                        welding.standard = '/'
                        welding.determine = '参考项'
                        this.changeData.push(welding)
                    }
                    if (subregion.antiCorrosionMeasures && subregion.testProject == '接闪器' && subregion.antiCorrosionMeasures != '请选择') {
                        const antiCorrosionMeasures = {}
                        antiCorrosionMeasures.point = subregion.testPosition
                        antiCorrosionMeasures.project = subregion.testProject
                        antiCorrosionMeasures.paramet = '防腐措施'
                        antiCorrosionMeasures.result = subregion.antiCorrosionMeasures
                        antiCorrosionMeasures.standard = '/'
                        antiCorrosionMeasures.determine = '参考项'
                        this.changeData.push(antiCorrosionMeasures)
                    }
                    if (subregion.rustCondition && subregion.testProject == '接闪器' && subregion.rustCondition != '请选择') {
                        const rustCondition = {}
                        rustCondition.point = subregion.testPosition
                        rustCondition.project = subregion.testProject
                        rustCondition.paramet = '腐蚀状况'
                        rustCondition.result = subregion.rustCondition
                        rustCondition.standard = '/'
                        rustCondition.determine = '参考项'
                        this.changeData.push(rustCondition)
                    }
                    if (subregion.connectionWithDownConductors && subregion.testProject == '接闪器') {
                        const connectionWithDownConductors = {}
                        connectionWithDownConductors.point = subregion.testPosition
                        connectionWithDownConductors.project = subregion.testProject
                        connectionWithDownConductors.paramet = '与引下线的电气连接(Ω)'
                        connectionWithDownConductors.standard = subregion.connectionWithDownConductorsStandard
                        var changeObj = this.changeType(subregion.connectionWithDownConductors, connectionWithDownConductors.standard)
                        connectionWithDownConductors.result = this.evenRound(changeObj.changeResult, 3)
                        if (connectionWithDownConductors.result <= changeObj.changeStandard) {
                            connectionWithDownConductors.determine = '符合要求'
                        } else {
                            connectionWithDownConductors.determine = '不符合要求'
                        }
                        this.changeData.push(connectionWithDownConductors)
                    }
                    if (subregion.equipotentialBondingOfRoof && subregion.testProject == '接闪器') {
                        const equipotentialBondingOfRoof = {}
                        equipotentialBondingOfRoof.point = subregion.testPosition
                        equipotentialBondingOfRoof.project = subregion.testProject
                        equipotentialBondingOfRoof.paramet = '屋面设施的等电位连接(Ω)'
                        equipotentialBondingOfRoof.standard = subregion.equipotentialBondingOfRoofStandard
                        var changeObj = this.changeType(subregion.equipotentialBondingOfRoof, equipotentialBondingOfRoof.standard)
                        if (typeof (changeObj.changeResult) != 'string') {
                            equipotentialBondingOfRoof.result = this.evenRound(changeObj.changeResult, 3)
                        } else {
                            equipotentialBondingOfRoof.result = subregion.equipotentialBondingOfRoof
                        }
                        if (equipotentialBondingOfRoof.result <= changeObj.changeStandard) {
                            equipotentialBondingOfRoof.determine = '符合要求'
                        } else {
                            equipotentialBondingOfRoof.determine = '不符合要求'
                        }
                        this.changeData.push(equipotentialBondingOfRoof)
                    }
                    if (subregion.otherMetalConnections && subregion.testProject == '接闪器') {
                        const otherMetalConnections = {}
                        otherMetalConnections.point = subregion.testPosition
                        otherMetalConnections.project = subregion.testProject
                        otherMetalConnections.paramet = '与建筑物顶部外露的其他金属物的电气连接(Ω)'
                        otherMetalConnections.standard = subregion.otherMetalConnectionsStandard
                        var changeObj = this.changeType(subregion.otherMetalConnections, otherMetalConnections.standard)

                        if (subregion.otherMetalConnections.match('<:D+>')) {
                            otherMetalConnections.result = this.evenRound(changeObj.changeResult, 3)
                            if (otherMetalConnections.result <= changeObj.changeStandard) {
                                otherMetalConnections.determine = '符合要求'
                            } else {
                                otherMetalConnections.determine = '不符合要求'
                            }

                        } else {
                            otherMetalConnections.result = subregion.otherMetalConnections
                            if (changeObj.changeResult <= changeObj.changeStandard) {
                                otherMetalConnections.determine = '符合要求'
                            } else {
                                otherMetalConnections.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(otherMetalConnections)
                    }
                    if (subregion.otherLines && subregion.testProject == '接闪器' && subregion.otherLines != '请选择') {
                        const otherLines = {}
                        otherLines.point = subregion.testPosition
                        otherLines.project = subregion.testProject
                        otherLines.paramet = '附着的其他电气线路'
                        otherLines.result = subregion.otherLines
                        otherLines.standard = '/'
                        otherLines.determine = '参考项'
                        this.changeData.push(otherLines)
                    }
                    if (subregion.earthResistance && subregion.testProject == '接闪器') {
                        const earthResistance = {}
                        earthResistance.point = subregion.testPosition
                        earthResistance.project = subregion.testProject
                        earthResistance.paramet = '接地电阻(Ω)'
                        earthResistance.standard = subregion.earthResistanceStandard
                        var changeObj = this.changeType(subregion.earthResistance, earthResistance.standard)
                        earthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (earthResistance.result <= changeObj.changeStandard) {
                            earthResistance.determine = '符合要求'
                        } else {
                            earthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(earthResistance)
                    }
                    if (subregion.structure && subregion.testProject == '接闪器') {
                        const structure = {}
                        structure.point = subregion.testPosition
                        structure.project = subregion.testProject
                        structure.paramet = '结构'
                        structure.result = subregion.structure
                        structure.standard = '/'
                        structure.determine = '参考项'
                        this.changeData.push(structure)
                    } if (subregion.minSection && subregion.testProject == '接闪器') {
                        const minSection = {}
                        minSection.point = subregion.testPosition
                        minSection.project = subregion.testProject
                        minSection.paramet = '最小截面(mm²)'
                        minSection.result = this.evenRound(Number(subregion.minSection), 0)
                        minSection.standard = '/'
                        minSection.determine = '参考项'
                        this.changeData.push(minSection)
                    } if (subregion.protectedRange && subregion.testProject == '接闪器') {
                        const protectedRange = {}
                        protectedRange.point = subregion.testPosition
                        protectedRange.project = subregion.testProject
                        protectedRange.paramet = '保护范围(m)'
                        protectedRange.result = this.evenRound(Number(subregion.protectedRange), 1)
                        protectedRange.standard = '/'
                        protectedRange.determine = '参考项'
                        this.changeData.push(protectedRange)
                    } if (subregion.distanceToProtectedObject && subregion.testProject == '接闪器') {
                        const distanceToProtectedObject = {}
                        distanceToProtectedObject.point = subregion.testPosition
                        distanceToProtectedObject.project = subregion.testProject
                        distanceToProtectedObject.paramet = '距被保护物的距离(m)'
                        if (subregion.distanceToProtectedObjectStandard === '参考项') {
                            distanceToProtectedObject.result = this.evenRound(subregion.distanceToProtectedObject, 1)
                            distanceToProtectedObject.standard = '/'
                            distanceToProtectedObject.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(subregion.distanceToProtectedObject, subregion.distanceToProtectedObjectStandard)
                            distanceToProtectedObject.result = this.evenRound(changeObj.changeResult, 1)
                            distanceToProtectedObject.standard = subregion.distanceToProtectedObjectStandard
                            if (distanceToProtectedObject.result >= changeObj.changeStandard) {
                                distanceToProtectedObject.determine = '符合要求'
                            } else {
                                distanceToProtectedObject.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(distanceToProtectedObject)
                    } if (subregion.protectionAgainstFlanking && subregion.testProject == '接闪器' && subregion.protectionAgainstFlanking != '请选择') {
                        const protectionAgainstFlanking = {}
                        protectionAgainstFlanking.point = subregion.testPosition
                        protectionAgainstFlanking.project = subregion.testProject
                        protectionAgainstFlanking.paramet = '防侧击保护措施'
                        protectionAgainstFlanking.result = subregion.protectionAgainstFlanking
                        protectionAgainstFlanking.standard = '/'
                        protectionAgainstFlanking.determine = '参考项'
                        this.changeData.push(protectionAgainstFlanking)
                    } if (subregion.treeDistanceFromBuilding && subregion.testProject == '接闪器') {
                        const treeDistanceFromBuilding = {}
                        treeDistanceFromBuilding.point = subregion.testPosition
                        treeDistanceFromBuilding.project = subregion.testProject
                        treeDistanceFromBuilding.paramet = '树木距建筑物距离(m)'
                        treeDistanceFromBuilding.standard = subregion.treeDistanceFromBuildingStandard
                        var changeObj = this.changeType(subregion.treeDistanceFromBuilding, treeDistanceFromBuilding.standard)
                        treeDistanceFromBuilding.result = this.evenRound(changeObj.changeResult, 1)
                        if (treeDistanceFromBuilding.result >= changeObj.changeStandard) {
                            treeDistanceFromBuilding.determine = '符合要求'
                        } else {
                            treeDistanceFromBuilding.determine = '不符合要求'
                        }
                        this.changeData.push(treeDistanceFromBuilding)
                    } if (subregion.chimneyAirTermination && subregion.testProject == '接闪器') {
                        const chimneyAirTermination = {}
                        chimneyAirTermination.point = subregion.testPosition
                        chimneyAirTermination.project = subregion.testProject
                        chimneyAirTermination.paramet = '烟囱的接闪器'
                        chimneyAirTermination.result = subregion.chimneyAirTermination
                        chimneyAirTermination.standard = '/'
                        chimneyAirTermination.determine = '参考项'
                        this.changeData.push(chimneyAirTermination)
                    } if (subregion.lightningSurgeIncoming && subregion.testProject == '接闪器') {
                        const lightningSurgeIncoming = {}
                        lightningSurgeIncoming.point = subregion.testPosition
                        lightningSurgeIncoming.project = subregion.testProject
                        lightningSurgeIncoming.paramet = '外部设备、线路防闪电电涌侵入措施'
                        lightningSurgeIncoming.result = subregion.lightningSurgeIncoming
                        lightningSurgeIncoming.standard = '/'
                        lightningSurgeIncoming.determine = '参考项'
                        this.changeData.push(lightningSurgeIncoming)
                    }
                    //引下线相关数据
                    if (downlead.layingMethod && downlead.testProject == '引下线' && downlead.layingMethod != '请选择') {
                        const downleadlayingMethod = {}
                        downleadlayingMethod.point = downlead.testPosition
                        downleadlayingMethod.project = downlead.testProject
                        downleadlayingMethod.paramet = '敷设方式'
                        downleadlayingMethod.result = downlead.layingMethod
                        downleadlayingMethod.standard = '/'
                        downleadlayingMethod.determine = '参考项'
                        this.changeData.push(downleadlayingMethod)
                    }
                    if (downlead.material && downlead.testProject == '引下线') {
                        const downleadmaterial = {}
                        downleadmaterial.point = downlead.testPosition
                        downleadmaterial.project = downlead.testProject
                        downleadmaterial.paramet = '材料'
                        downleadmaterial.result = downlead.material
                        downleadmaterial.standard = '/'
                        downleadmaterial.determine = '参考项'
                        this.changeData.push(downleadmaterial)
                    }
                    if (downlead.specification && downlead.testProject == '引下线') {
                        const downleadspecification = {}
                        downleadspecification.point = downlead.testPosition
                        downleadspecification.project = downlead.testProject
                        if (downlead.specification.includes('mm²')) {
                            downleadspecification.paramet = '规格(mm²)'
                        } else {
                            downleadspecification.paramet = '规格(mm)'
                        }
                        if (downlead.specificationStandard == '参考项') {
                            downleadspecification.result = downlead.specification
                            downleadspecification.standard = '/'
                            downleadspecification.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(downlead.specification, downlead.specificationStandard)
                            downleadspecification.result = this.evenRound(changeObj.changeResult, 0)
                            if (downleadspecification.result >= changeObj.changeStandard) {
                                downleadspecification.standard = downlead.specificationStandard
                                downleadspecification.determine = '符合要求'
                            } else {
                                downleadspecification.standard = downlead.specificationStandard
                                downleadspecification.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(downleadspecification)
                    }
                    if (downlead.quantity && downlead.testProject == '引下线') {
                        const downleadquantity = {}
                        downleadquantity.point = downlead.testPosition
                        downleadquantity.project = downlead.testProject
                        downleadquantity.paramet = '数量'
                        downleadquantity.result = this.evenRound(Number(downlead.quantity), 0)
                        downleadquantity.standard = '/'
                        downleadquantity.determine = '参考项'
                        this.changeData.push(downleadquantity)
                    }
                    if (downlead.averageSpacing && downlead.testProject == '引下线') {
                        const downleadaverageSpacing = {}
                        downleadaverageSpacing.point = downlead.testPosition
                        downleadaverageSpacing.project = downlead.testProject
                        downleadaverageSpacing.paramet = '平均间距(m)'
                        downleadaverageSpacing.standard = downlead.averageSpacingStandard
                        var changeObj = this.changeType(downlead.averageSpacing, downleadaverageSpacing.standard)
                        downleadaverageSpacing.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadaverageSpacing.result <= changeObj.changeStandard) {
                            downleadaverageSpacing.determine = '符合要求'
                        } else {
                            downleadaverageSpacing.determine = '不符合要求'
                        }
                        this.changeData.push(downleadaverageSpacing)
                    }
                    if (downlead.spacing && downlead.testProject == '引下线') {
                        const downleadspacing = {}
                        downleadspacing.point = downlead.testPosition
                        downleadspacing.project = downlead.testProject
                        downleadspacing.paramet = '间距(m)'
                        downleadspacing.standard = downlead.spacingStandard
                        var changeObj = this.changeType(downlead.spacing, downleadspacing.standard)
                        downleadspacing.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadspacing.result <= changeObj.changeStandard) {
                            downleadspacing.determine = '符合要求'
                        } else {
                            downleadspacing.determine = '参考项'
                        }
                        this.changeData.push(downleadspacing)
                    }
                    if (downlead.welding && downlead.testProject == '引下线' && downlead.welding != '请选择') {
                        const downleadwelding = {}
                        downleadwelding.point = downlead.testPosition
                        downleadwelding.project = downlead.testProject
                        downleadwelding.paramet = '焊接固定'
                        downleadwelding.result = downlead.welding
                        downleadwelding.standard = '/'
                        downleadwelding.determine = '参考项'
                        this.changeData.push(downleadwelding)
                    }
                    if (downlead.antiCorrosionMeasures && downlead.testProject == '引下线' && downlead.antiCorrosionMeasures != '请选择') {
                        const downleadantiCorrosionMeasures = {}
                        downleadantiCorrosionMeasures.point = downlead.testPosition
                        downleadantiCorrosionMeasures.project = downlead.testProject
                        downleadantiCorrosionMeasures.paramet = '防腐措施'
                        downleadantiCorrosionMeasures.result = downlead.antiCorrosionMeasures
                        downleadantiCorrosionMeasures.standard = '/'
                        downleadantiCorrosionMeasures.determine = '参考项'
                        this.changeData.push(downleadantiCorrosionMeasures)
                    }
                    if (downlead.rustCondition && downlead.testProject == '引下线' && downlead.rustCondition != '请选择') {
                        const downleadrustCondition = {}
                        downleadrustCondition.point = downlead.testPosition
                        downleadrustCondition.project = downlead.testProject
                        downleadrustCondition.paramet = '腐蚀状况'
                        downleadrustCondition.result = downlead.rustCondition
                        downleadrustCondition.standard = '/'
                        downleadrustCondition.determine = '参考项'
                        this.changeData.push(downleadrustCondition)
                    }
                    if (downlead.transitionWithAt && downlead.testProject == '引下线') {
                        const downleadtransitionWithAt = {}
                        downleadtransitionWithAt.point = downlead.testPosition
                        downleadtransitionWithAt.project = downlead.testProject
                        downleadtransitionWithAt.paramet = '与接闪器的连接过渡电阻(Ω)'
                        downleadtransitionWithAt.standard = downlead.transitionWithAtStandard
                        var changeObj = this.changeType(downlead.transitionWithAt, downleadtransitionWithAt.standard)
                        downleadtransitionWithAt.result = this.evenRound(changeObj.changeResult, 3)
                        if (downleadtransitionWithAt.result <= changeObj.changeStandard) {
                            downleadtransitionWithAt.determine = '符合要求'
                        } else {
                            downleadtransitionWithAt.determine = '不符合要求'
                        }
                        this.changeData.push(downleadtransitionWithAt)
                    }
                    if (downlead.transitionWithEarthEiectrode && downlead.testProject == '引下线') {
                        const downleadtransitionWithEarthEiectrode = {}
                        downleadtransitionWithEarthEiectrode.point = downlead.testPosition
                        downleadtransitionWithEarthEiectrode.project = downlead.testProject
                        downleadtransitionWithEarthEiectrode.paramet = '与接地体的连接过渡电阻(Ω)'
                        downleadtransitionWithEarthEiectrode.standard = downlead.transitionWithEarthEiectrodeStandard
                        var changeObj = this.changeType(downlead.transitionWithEarthEiectrode, downleadtransitionWithEarthEiectrode.standard)
                        downleadtransitionWithEarthEiectrode.result = this.evenRound(changeObj.changeResult, 3)
                        if (downleadtransitionWithEarthEiectrode.result <= changeObj.changeStandard) {
                            downleadtransitionWithEarthEiectrode.determine = '符合要求'
                        } else {
                            downleadtransitionWithEarthEiectrode.determine = '不符合要求'
                        }
                        this.changeData.push(downleadtransitionWithEarthEiectrode)
                    }
                    if (downlead.earthResistance && downlead.testProject == '引下线') {
                        const downleadearthResistance = {}
                        downleadearthResistance.point = downlead.testPosition
                        downleadearthResistance.project = downlead.testProject
                        downleadearthResistance.paramet = '接地电阻(Ω)'
                        downleadearthResistance.standard = downlead.earthResistanceStandard
                        var changeObj = this.changeType(downlead.earthResistance, downleadearthResistance.standard)
                        downleadearthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadearthResistance.result <= changeObj.changeStandard) {
                            downleadearthResistance.determine = '符合要求'
                        } else {
                            downleadearthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(downleadearthResistance)
                    }
                    if (downlead.bracketDistance && downlead.testProject == '引下线') {
                        const downleadbracketDistance = {}
                        downleadbracketDistance.point = downlead.testPosition
                        downleadbracketDistance.project = downlead.testProject
                        downleadbracketDistance.paramet = '固定支架间距(m)'
                        downleadbracketDistance.standard = downlead.bracketDistanceStandard
                        var changeObj = this.changeType(downlead.bracketDistance, downleadbracketDistance.standard)
                        downleadbracketDistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadbracketDistance.result <= changeObj.changeStandard) {
                            downleadbracketDistance.determine = '符合要求'
                        } else {
                            downleadbracketDistance.determine = '不符合要求'
                        }
                        this.changeData.push(downleadbracketDistance)
                    }
                    if (downlead.bracketHeight && downlead.testProject == '引下线') {
                        const downleadbracketHeight = {}
                        downleadbracketHeight.point = downlead.testPosition
                        downleadbracketHeight.project = downlead.testProject
                        downleadbracketHeight.paramet = '固定支架高度(mm)'
                        downleadbracketHeight.result = this.evenRound(Number(downlead.bracketHeight), 0)
                        downleadbracketHeight.standard = '/'
                        downleadbracketHeight.determine = '参考项'
                        this.changeData.push(downleadbracketHeight)
                    }
                    if (downlead.verticalPull && downlead.testProject == '引下线') {
                        const downleadverticalPull = {}
                        downleadverticalPull.point = downlead.testPosition
                        downleadverticalPull.project = downlead.testProject
                        downleadverticalPull.paramet = '网带支持件承受垂直拉力(N)'
                        downleadverticalPull.standard = downlead.verticalPullStandard
                        var changeObj = this.changeType(downlead.verticalPull, downleadverticalPull.standard)
                        downleadverticalPull.result = this.evenRound(changeObj.changeResult, 0)
                        if (downleadverticalPull.result >= changeObj.changeStandard) {
                            downleadverticalPull.determine = '符合要求'
                        } else {
                            downleadverticalPull.determine = '不符合要求'
                        }
                        this.changeData.push(downleadverticalPull)
                    }
                    if (downlead.otherLines && downlead.testProject == '引下线' && downlead.otherLines != '请选择') {
                        const downleadotherLines = {}
                        downleadotherLines.point = downlead.testPosition
                        downleadotherLines.project = downlead.testProject
                        downleadotherLines.paramet = '附着的其他电气线路'
                        downleadotherLines.result = downlead.otherLines
                        downleadotherLines.standard = '/'
                        downleadotherLines.determine = '参考项'
                        this.changeData.push(downleadotherLines)
                    }
                    if (downlead.minDistance && downlead.testProject == '引下线') {
                        const downleadminDistance = {}
                        downleadminDistance.point = downlead.testPosition
                        downleadminDistance.project = downlead.testProject
                        downleadminDistance.paramet = '与电气和电子线路最小距离(m)'
                        downleadminDistance.standard = downlead.minDistanceStandard
                        var changeObj = this.changeType(downlead.minDistance, downleadminDistance.standard)
                        downleadminDistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadminDistance.result >= changeObj.changeStandard) {
                            downleadminDistance.determine = '符合要求'
                        } else {
                            downleadminDistance.determine = '不符合要求'
                        }
                        this.changeData.push(downleadminDistance)
                    }
                    if (downlead.distanceToInsulation && downlead.testProject == '引下线') {
                        const downleaddistanceToInsulation = {}
                        downleaddistanceToInsulation.point = downlead.testPosition
                        downleaddistanceToInsulation.project = downlead.testProject
                        downleaddistanceToInsulation.paramet = '与保温层间距(m)'
                        downleaddistanceToInsulation.standard = downlead.distanceToInsulationStandard
                        var changeObj = this.changeType(downlead.distanceToInsulation, downleaddistanceToInsulation.standard)
                        downleaddistanceToInsulation.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleaddistanceToInsulation.result > changeObj.changeStandard) {
                            downleaddistanceToInsulation.determine = '符合要求'
                        } else {
                            downleaddistanceToInsulation.determine = '不符合要求'
                        }
                        this.changeData.push(downleaddistanceToInsulation)
                    }
                    if (downlead.mechanicalProtectionMeasures && downlead.testProject == '引下线' && downlead.mechanicalProtectionMeasures != '请选择') {
                        const downleadmechanicalProtectionMeasures = {}
                        downleadmechanicalProtectionMeasures.point = downlead.testPosition
                        downleadmechanicalProtectionMeasures.project = downlead.testProject
                        downleadmechanicalProtectionMeasures.paramet = '受机械损伤保护措施'
                        downleadmechanicalProtectionMeasures.result = downlead.mechanicalProtectionMeasures
                        downleadmechanicalProtectionMeasures.standard = '/'
                        downleadmechanicalProtectionMeasures.determine = '参考项'
                        this.changeData.push(downleadmechanicalProtectionMeasures)
                    }
                    if (downlead.distanceFromEntrance && downlead.testProject == '引下线') {
                        const downleaddistanceFromEntrance = {}
                        downleaddistanceFromEntrance.point = downlead.testPosition
                        downleaddistanceFromEntrance.project = downlead.testProject
                        downleaddistanceFromEntrance.paramet = '距离出入口或人行道边沿距离(m)'
                        downleaddistanceFromEntrance.standard = downlead.distanceFromEntranceStandard
                        var changeObj = this.changeType(downlead.distanceFromEntrance, downleaddistanceFromEntrance.standard)
                        downleaddistanceFromEntrance.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleaddistanceFromEntrance.result >= changeObj.changeStandard) {
                            downleaddistanceFromEntrance.determine = '符合要求'
                        } else {
                            downleaddistanceFromEntrance.determine = '不符合要求'
                        }
                        this.changeData.push(downleaddistanceFromEntrance)
                    }
                    //接地装置相关数据
                    if (earthing.earthingDeviceCategory && earthing.testProject == '接地装置' && earthing.earthingDeviceCategory != '请选择') {
                        const earthingDeviceCategory = {}
                        earthingDeviceCategory.point = earthing.testPosition
                        earthingDeviceCategory.project = earthing.testProject
                        earthingDeviceCategory.paramet = '共用/独立接地'
                        earthingDeviceCategory.result = earthing.earthingDeviceCategory
                        earthingDeviceCategory.standard = '/'
                        earthingDeviceCategory.determine = '参考项'
                        this.changeData.push(earthingDeviceCategory)
                    }
                    if (earthing.earthConductorConnectionSituation && earthing.testProject == '接地装置') {
                        const earthConductorConnectionSituation = {}
                        earthConductorConnectionSituation.point = earthing.testPosition
                        earthConductorConnectionSituation.project = earthing.testProject
                        earthConductorConnectionSituation.paramet = '相邻两接地装置电气导通(Ω)'
                        earthConductorConnectionSituation.standard = earthing.earthConductorConnectionSituationStandard
                        var changeObj = this.changeType(earthing.earthConductorConnectionSituation, earthConductorConnectionSituation.standard)
                        earthConductorConnectionSituation.result = this.evenRound(changeObj.changeResult, 3)
                        earthConductorConnectionSituation.determine = '参考项'
                        this.changeData.push(earthConductorConnectionSituation)
                    }
                    if (earthing.distanceToTheProtectedObject && earthing.testProject == '接地装置') {
                        const distanceToTheProtectedObject = {}
                        distanceToTheProtectedObject.point = earthing.testPosition
                        distanceToTheProtectedObject.project = earthing.testProject
                        distanceToTheProtectedObject.paramet = '至被保护物及与其有联系的金属物的间隔距离(m)'
                        if (earthing.distanceToTheProtectedObjectStandard === '参考项') {
                            distanceToTheProtectedObject.result = this.evenRound(earthing.distanceToTheProtectedObject, 1)
                            distanceToTheProtectedObject.standard = '/'
                            distanceToTheProtectedObject.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(earthing.distanceToTheProtectedObject, earthing.distanceToTheProtectedObjectStandard)
                            distanceToTheProtectedObject.result = this.evenRound(changeObj.changeResult, 1)
                            distanceToTheProtectedObject.standard = earthing.distanceToTheProtectedObjectStandard
                            if (changeObj.changeStandard) {
                                if (distanceToTheProtectedObject.result >= changeObj.changeStandard) {
                                    distanceToTheProtectedObject.determine = '符合要求'
                                } else {
                                    distanceToTheProtectedObject.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(distanceToTheProtectedObject)
                    }
                    if (earthing.earthResistance && earthing.testProject == '接地装置') {
                        const earthResistance = {}
                        earthResistance.point = earthing.testPosition
                        earthResistance.project = earthing.testProject
                        earthResistance.paramet = '接地电阻(Ω)'
                        earthResistance.standard = earthing.earthResistanceStandard
                        var changeObj = this.changeType(earthing.earthResistance, earthResistance.standard)
                        earthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (earthResistance.result <= changeObj.changeStandard) {
                            earthResistance.determine = '符合要求'
                        } else {
                            earthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(earthResistance)
                    }
                    if (earthing.materialNatural && earthing.testProject == '接地装置') {
                        const materialNatural = {}
                        materialNatural.point = earthing.testPosition
                        materialNatural.project = earthing.testProject
                        materialNatural.paramet = '自然接地体的材料'
                        materialNatural.result = earthing.materialNatural
                        materialNatural.standard = '/'
                        materialNatural.determine = '参考项'
                        this.changeData.push(materialNatural)
                    }
                    if (earthing.specificationNatural && earthing.testProject == '接地装置') {
                        const specificationNatural = {}
                        specificationNatural.point = earthing.testPosition
                        specificationNatural.project = earthing.testProject
                        specificationNatural.paramet = '自然接地体的规格'
                        specificationNatural.result = earthing.specificationNatural
                        specificationNatural.standard = '/'
                        specificationNatural.determine = '参考项'
                        this.changeData.push(specificationNatural)
                    }
                    if (earthing.materialArtificial && earthing.testProject == '接地装置') {
                        const materialArtificial = {}
                        materialArtificial.point = earthing.testPosition
                        materialArtificial.project = earthing.testProject
                        materialArtificial.paramet = '人工接地体的材料'
                        materialArtificial.result = earthing.materialArtificial
                        materialArtificial.standard = '/'
                        materialArtificial.determine = '参考项'
                        this.changeData.push(materialArtificial)
                    }
                    if (earthing.specificationArtificial && earthing.testProject == '接地装置') {
                        const specificationArtificial = {}
                        specificationArtificial.point = earthing.testPosition
                        specificationArtificial.project = earthing.testProject
                        specificationArtificial.paramet = '人工接地体的规格'
                        specificationArtificial.result = earthing.specificationArtificial
                        specificationArtificial.standard = '/'
                        specificationArtificial.determine = '参考项'
                        this.changeData.push(specificationArtificial)
                    }
                    if (earthing.buriedDepth && earthing.testProject == '接地装置') {
                        const buriedDepth = {}
                        buriedDepth.point = earthing.testPosition
                        buriedDepth.project = earthing.testProject
                        buriedDepth.paramet = '人工接地体的埋设深度(m)'
                        buriedDepth.standard = earthing.buriedDepthStandard
                        var changeObj = this.changeType(earthing.buriedDepth, buriedDepth.standard)
                        buriedDepth.result = this.evenRound(changeObj.changeResult, 1)
                        if (buriedDepth.result > changeObj.changeStandard) {
                            buriedDepth.determine = '符合要求'
                        } else {
                            buriedDepth.determine = '不符合要求'
                        }
                        this.changeData.push(buriedDepth)
                    }
                    if (earthing.earthElectrodeSpacing && earthing.testProject == '接地装置') {
                        const earthElectrodeSpacing = {}
                        earthElectrodeSpacing.point = earthing.testPosition
                        earthElectrodeSpacing.project = earthing.testProject
                        earthElectrodeSpacing.paramet = '人工接地体的间距(m)'
                        earthElectrodeSpacing.result = this.evenRound(Number(earthing.earthElectrodeSpacing), 1)
                        earthElectrodeSpacing.standard = '/'
                        earthElectrodeSpacing.determine = '参考项'
                        this.changeData.push(earthElectrodeSpacing)
                    }
                    if (earthing.soilResistivity && earthing.testProject == '接地装置') {
                        const soilResistivity = {}
                        soilResistivity.point = earthing.testPosition
                        soilResistivity.project = earthing.testProject
                        soilResistivity.paramet = '土壤电阻率(Ωm)'
                        soilResistivity.result = this.evenRound(Number(earthing.soilResistivity), 1)
                        soilResistivity.standard = '/'
                        soilResistivity.determine = '参考项'
                        this.changeData.push(soilResistivity)
                    }
                    if (earthing.intervalDistance && earthing.testProject == '接地装置') {
                        const intervalDistance = {}
                        intervalDistance.point = earthing.testPosition
                        intervalDistance.project = earthing.testProject
                        intervalDistance.paramet = '间隔距离(m)'
                        intervalDistance.result = this.evenRound(Number(earthing.intervalDistance), 1)
                        intervalDistance.standard = '/'
                        intervalDistance.determine = '参考项'
                        this.changeData.push(intervalDistance)
                    }
                    if (earthing.antiCorrosionMeasures && earthing.testProject == '接地装置' && earthing.antiCorrosionMeasures != '请选择') {
                        const antiCorrosionMeasures = {}
                        antiCorrosionMeasures.point = earthing.testPosition
                        antiCorrosionMeasures.project = earthing.testProject
                        antiCorrosionMeasures.paramet = '防腐措施'
                        antiCorrosionMeasures.result = earthing.antiCorrosionMeasures
                        antiCorrosionMeasures.standard = '/'
                        antiCorrosionMeasures.determine = '参考项'
                        this.changeData.push(antiCorrosionMeasures)
                    }
                    if (earthing.rustCondition && earthing.testProject == '接地装置' && earthing.rustCondition != '请选择') {
                        const rustCondition = {}
                        rustCondition.point = earthing.testPosition
                        rustCondition.project = earthing.testProject
                        rustCondition.paramet = '腐蚀状况'
                        rustCondition.result = earthing.rustCondition
                        rustCondition.standard = '/'
                        rustCondition.determine = '参考项'
                        this.changeData.push(rustCondition)
                    }
                    if (earthing.connectionMethod && earthing.testProject == '接地装置') {
                        const connectionMethod = {}
                        connectionMethod.point = earthing.testPosition
                        connectionMethod.project = earthing.testProject
                        connectionMethod.paramet = '连接方式'
                        connectionMethod.result = earthing.connectionMethod
                        connectionMethod.standard = '/'
                        connectionMethod.determine = '参考项'
                        this.changeData.push(connectionMethod)
                    }
                    //等电位连接相关数据
                    if (equipotential.installationLocation && equipotential.testProject == '等电位连接') {
                        const installationLocation = {}
                        installationLocation.point = equipotential.testPosition
                        installationLocation.project = equipotential.testProject
                        installationLocation.paramet = '等电位连接设施安放位置'
                        installationLocation.result = equipotential.installationLocation
                        installationLocation.standard = '/'
                        installationLocation.determine = '参考项'
                        this.changeData.push(installationLocation)
                    }
                    if (equipotential.lebMaterial && equipotential.testProject == '等电位连接') {
                        const lebMaterial = {}
                        lebMaterial.point = equipotential.testPosition
                        lebMaterial.project = equipotential.testProject
                        lebMaterial.paramet = '等电位连接导体材料'
                        lebMaterial.result = equipotential.lebMaterial
                        lebMaterial.standard = '/'
                        lebMaterial.determine = '参考项'
                        this.changeData.push(lebMaterial)
                    }
                    if (equipotential.lebSpecification && equipotential.testProject == '等电位连接') {
                        const lebSpecification = {}
                        lebSpecification.point = equipotential.testPosition
                        lebSpecification.project = equipotential.testProject
                        lebSpecification.paramet = '等电位连接导体规格尺寸'
                        lebSpecification.result = equipotential.lebSpecification
                        lebSpecification.standard = '/'
                        lebSpecification.determine = '参考项'
                        this.changeData.push(lebSpecification)
                    }
                    if (equipotential.lebEarthResistance && equipotential.testProject == '等电位连接') {
                        const lebEarthResistance = {}
                        lebEarthResistance.point = equipotential.testPosition
                        lebEarthResistance.project = equipotential.testProject
                        lebEarthResistance.paramet = '等电位连接设施接地电阻(Ω)'
                        lebEarthResistance.standard = equipotential.lebEarthResistanceStandard
                        var changeObj = this.changeType(equipotential.lebEarthResistance, lebEarthResistance.standard)
                        lebEarthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (lebEarthResistance.result <= changeObj.changeStandard) {
                            lebEarthResistance.determine = '符合要求'
                        } else {
                            lebEarthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(lebEarthResistance)
                    }
                    if (equipotential.equipotentialForm && equipotential.testProject == '等电位连接' && equipotential.equipotentialForm != '请选择') {
                        const equipotentialForm = {}
                        equipotentialForm.point = equipotential.testPosition
                        equipotentialForm.project = equipotential.testProject
                        equipotentialForm.paramet = '电子设备等电位连接基本形式'
                        equipotentialForm.result = equipotential.equipotentialForm
                        equipotentialForm.standard = '/'
                        equipotentialForm.determine = '参考项'
                        this.changeData.push(equipotentialForm)
                    }
                    if (equipotential.transitionResistor && equipotential.testProject == '等电位连接') {
                        const transitionResistor = {}
                        transitionResistor.point = equipotential.testPosition
                        transitionResistor.project = equipotential.testProject
                        transitionResistor.paramet = '等电位连接过渡电阻(Ω)'
                        transitionResistor.standard = equipotential.transitionResistorStandard
                        var changeObj = this.changeType(equipotential.transitionResistor, transitionResistor.standard)
                        if (equipotential.transitionResistorUnit == 'mΩ') {
                            transitionResistor.result = this.evenRound(equipotential.transitionResistor / 1000, 3)
                            transitionResistor.standard = changeObj.changeStandard / 1000
                            if (transitionResistor.result <= transitionResistor.standard) {
                                transitionResistor.standard = '≤' + changeObj.changeStandard / 1000
                                transitionResistor.determine = '符合要求'
                            } else {
                                transitionResistor.standard = '≤' + changeObj.changeStandard / 1000
                                transitionResistor.determine = '不符合要求'
                            }
                        } else {
                            transitionResistor.result = this.evenRound(changeObj.changeResult, 3)
                            if (transitionResistor.result <= changeObj.changeStandard) {
                                transitionResistor.determine = '符合要求'
                            } else {
                                transitionResistor.determine = '不符合要求'
                            }
                        }

                        this.changeData.push(transitionResistor)
                    }
                    //雷击电磁脉冲屏蔽相关数据
                    if (strike.largeSizeMetalConnection && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const largeSizeMetalConnection = {}
                        largeSizeMetalConnection.point = strike.testPosition
                        largeSizeMetalConnection.project = strike.testProject
                        largeSizeMetalConnection.paramet = '大尺寸金属件等电位连接(Ω)'
                        largeSizeMetalConnection.standard = strike.largeSizeMetalConnectionStandard
                        var changeObj = this.changeType(strike.largeSizeMetalConnection, largeSizeMetalConnection.standard)
                        largeSizeMetalConnection.result = this.evenRound(changeObj.changeResult, 3)
                        if (largeSizeMetalConnection.result <= changeObj.changeStandard) {
                            largeSizeMetalConnection.determine = '符合要求'
                        } else {
                            largeSizeMetalConnection.determine = '不符合要求'
                        }
                        this.changeData.push(largeSizeMetalConnection)
                    }
                    if (strike.largeSizeMetalEarthResistance && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const largeSizeMetalEarthResistance = {}
                        largeSizeMetalEarthResistance.point = strike.testPosition
                        largeSizeMetalEarthResistance.project = strike.testProject
                        largeSizeMetalEarthResistance.paramet = '大尺寸金属件接地电阻(Ω)'
                        largeSizeMetalEarthResistance.standard = strike.largeSizeMetalEarthResistanceStandard
                        var changeObj = this.changeType(strike.largeSizeMetalEarthResistance, largeSizeMetalEarthResistance.standard)
                        largeSizeMetalEarthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (largeSizeMetalEarthResistance.result <= changeObj.changeStandard) {
                            largeSizeMetalEarthResistance.determine = '符合要求'
                        } else {
                            largeSizeMetalEarthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(largeSizeMetalEarthResistance)
                    }
                    if (strike.shieldGroundedAtBothEnds && strike.testProject == '雷击电磁脉冲屏蔽' && strike.shieldGroundedAtBothEnds != '请选择') {
                        const shieldGroundedAtBothEnds = {}
                        shieldGroundedAtBothEnds.point = strike.testPosition
                        shieldGroundedAtBothEnds.project = strike.testProject
                        shieldGroundedAtBothEnds.paramet = '金属屏蔽层两端接地'
                        shieldGroundedAtBothEnds.result = strike.shieldGroundedAtBothEnds
                        shieldGroundedAtBothEnds.standard = '/'
                        shieldGroundedAtBothEnds.determine = '参考项'
                        this.changeData.push(shieldGroundedAtBothEnds)
                    }
                    if (strike.transitionResistanceOfMetalObjects && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const transitionResistanceOfMetalObjects = {}
                        transitionResistanceOfMetalObjects.point = strike.testPosition
                        transitionResistanceOfMetalObjects.project = strike.testProject
                        transitionResistanceOfMetalObjects.paramet = '和所连接金属物的等电位连接过渡电阻(Ω)'
                        transitionResistanceOfMetalObjects.standard = strike.transitionResistanceOfMetalObjectsStandard
                        var changeObj = this.changeType(strike.transitionResistanceOfMetalObjects, transitionResistanceOfMetalObjects.standard)
                        transitionResistanceOfMetalObjects.result = this.evenRound(changeObj.changeResult, 3)
                        if (transitionResistanceOfMetalObjects.result <= changeObj.changeStandard) {
                            transitionResistanceOfMetalObjects.determine = '符合要求'
                        } else {
                            transitionResistanceOfMetalObjects.determine = '不符合要求'
                        }
                        this.changeData.push(transitionResistanceOfMetalObjects)
                    }
                    if (strike.shieldedCableEarthResistance && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const shieldedCableEarthResistance = {}
                        shieldedCableEarthResistance.point = strike.testPosition
                        shieldedCableEarthResistance.project = strike.testProject
                        shieldedCableEarthResistance.paramet = '屏蔽电缆接地电阻(Ω)'
                        shieldedCableEarthResistance.standard = strike.shieldedCableEarthResistanceStandard
                        var changeObj = this.changeType(strike.shieldedCableEarthResistance, shieldedCableEarthResistance.standard)
                        shieldedCableEarthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (shieldedCableEarthResistance.result <= changeObj.changeStandard) {
                            shieldedCableEarthResistance.determine = '符合要求'
                        } else {
                            shieldedCableEarthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(shieldedCableEarthResistance)
                    }
                    if (strike.oneEndOfShield && strike.testProject == '雷击电磁脉冲屏蔽' && strike.oneEndOfShield != '请选择') {
                        const oneEndOfShield = {}
                        oneEndOfShield.point = strike.testPosition
                        oneEndOfShield.project = strike.testProject
                        oneEndOfShield.paramet = '金属屏蔽层一端接地'
                        oneEndOfShield.result = strike.oneEndOfShield
                        oneEndOfShield.standard = '/'
                        oneEndOfShield.determine = '参考项'
                        this.changeData.push(oneEndOfShield)
                    }
                    if (strike.doubleShielding && strike.testProject == '雷击电磁脉冲屏蔽' && strike.doubleShielding != '请选择') {
                        const doubleShielding = {}
                        doubleShielding.point = strike.testPosition
                        doubleShielding.project = strike.testProject
                        doubleShielding.paramet = '双层屏蔽'
                        doubleShielding.result = strike.doubleShielding
                        doubleShielding.standard = '/'
                        doubleShielding.determine = '参考项'
                        this.changeData.push(doubleShielding)
                    }
                    if (strike.protectorGroundedAtBothEnds && strike.testProject == '雷击电磁脉冲屏蔽' && strike.protectorGroundedAtBothEnds != '请选择') {
                        const protectorGroundedAtBothEnds = {}
                        protectorGroundedAtBothEnds.point = strike.testPosition
                        protectorGroundedAtBothEnds.project = strike.testProject
                        protectorGroundedAtBothEnds.paramet = '非屏蔽电缆的金属保护物两端接地'
                        protectorGroundedAtBothEnds.result = strike.protectorGroundedAtBothEnds
                        protectorGroundedAtBothEnds.standard = '/'
                        protectorGroundedAtBothEnds.determine = '参考项'
                        this.changeData.push(protectorGroundedAtBothEnds)
                    }
                    if (strike.shieldMaterial && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const shieldMaterial = {}
                        shieldMaterial.point = strike.testPosition
                        shieldMaterial.project = strike.testProject
                        shieldMaterial.paramet = '屏蔽材料'
                        shieldMaterial.result = strike.shieldMaterial
                        shieldMaterial.standard = '/'
                        shieldMaterial.determine = '参考项'
                        this.changeData.push(shieldMaterial)
                    }
                    if (strike.shieldSpecification && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const shieldSpecification = {}
                        shieldSpecification.point = strike.testPosition
                        shieldSpecification.project = strike.testProject
                        shieldSpecification.paramet = '屏蔽材料规格尺寸'
                        shieldSpecification.result = strike.shieldSpecification
                        shieldSpecification.standard = '/'
                        shieldSpecification.determine = '参考项'
                        this.changeData.push(shieldSpecification)
                    }
                    if (strike.electromagneticShieldingEffectiveness && strike.testProject == '雷击电磁脉冲屏蔽') {
                        const electromagneticShieldingEffectiveness = {}
                        electromagneticShieldingEffectiveness.point = strike.testPosition
                        electromagneticShieldingEffectiveness.project = strike.testProject
                        electromagneticShieldingEffectiveness.paramet = '电磁屏蔽效能'
                        electromagneticShieldingEffectiveness.result = strike.electromagneticShieldingEffectiveness
                        electromagneticShieldingEffectiveness.standard = '/'
                        electromagneticShieldingEffectiveness.determine = '参考项'
                        this.changeData.push(electromagneticShieldingEffectiveness)
                    }
                    if (strike.metalEquipotentialConnection && strike.testProject == '雷击电磁脉冲屏蔽' && strike.metalEquipotentialConnection != '请选择') {
                        const metalEquipotentialConnection = {}
                        metalEquipotentialConnection.point = strike.testPosition
                        metalEquipotentialConnection.project = strike.testProject
                        metalEquipotentialConnection.paramet = '门窗、幕墙、屋面及立面金属表面、大尺寸金属构件等是否等电位连接'
                        metalEquipotentialConnection.result = strike.metalEquipotentialConnection
                        metalEquipotentialConnection.standard = '/'
                        metalEquipotentialConnection.determine = '参考项'
                        this.changeData.push(metalEquipotentialConnection)
                    }
                    //电源电涌保护器相关数据
                    if (powersurge.modelNumber && powersurge.testProject == '电源电涌保护器') {
                        const modelNumber = {}
                        modelNumber.point = powersurge.testPosition
                        modelNumber.project = powersurge.testProject
                        modelNumber.paramet = '型号'
                        modelNumber.result = powersurge.modelNumber
                        modelNumber.standard = '/'
                        modelNumber.determine = '参考项'
                        this.changeData.push(modelNumber)
                    }
                    if (powersurge.outwardAppearance && powersurge.testProject == '电源电涌保护器' && powersurge.outwardAppearance != '请选择') {
                        const outwardAppearance = {}
                        outwardAppearance.point = powersurge.testPosition
                        outwardAppearance.project = powersurge.testProject
                        outwardAppearance.paramet = '外观'
                        outwardAppearance.result = powersurge.outwardAppearance
                        outwardAppearance.standard = '/'
                        outwardAppearance.determine = '参考项'
                        this.changeData.push(outwardAppearance)
                    }
                    if (powersurge.level && powersurge.testProject == '电源电涌保护器' && powersurge.level != '请选择') {
                        const level = {}
                        level.point = powersurge.testPosition
                        level.project = powersurge.testProject
                        level.paramet = '级数'
                        level.result = powersurge.level
                        level.standard = '/'
                        level.determine = '参考项'
                        this.changeData.push(level)
                    }
                    if (powersurge.testType && powersurge.testProject == '电源电涌保护器' && powersurge.testType != '请选择') {
                        const testType = {}
                        testType.point = powersurge.testPosition
                        testType.project = powersurge.testProject
                        testType.paramet = '试验类型'
                        testType.result = powersurge.testType
                        testType.standard = '/'
                        testType.determine = '参考项'
                        this.changeData.push(testType)
                    }
                    if (powersurge.backupProtection && powersurge.testProject == '电源电涌保护器' && powersurge.backupProtection != '请选择') {
                        const backupProtection = {}
                        backupProtection.point = powersurge.testPosition
                        backupProtection.project = powersurge.testProject
                        backupProtection.paramet = '后备保护装置'
                        backupProtection.result = powersurge.backupProtection
                        backupProtection.standard = '/'
                        backupProtection.determine = '参考项'
                        this.changeData.push(backupProtection)
                    }
                    if (powersurge.deviceType && powersurge.testProject == '电源电涌保护器' && powersurge.deviceType != '请选择') {
                        const deviceType = {}
                        deviceType.point = powersurge.testPosition
                        deviceType.project = powersurge.testProject
                        deviceType.paramet = '器件类型'
                        deviceType.result = powersurge.deviceType
                        deviceType.standard = '/'
                        deviceType.determine = '参考项'
                        this.changeData.push(deviceType)
                    }
                    if (powersurge.protectedMode && powersurge.testProject == '电源电涌保护器' && powersurge.protectedMode != '请选择') {
                        const protectedMode = {}
                        protectedMode.point = powersurge.testPosition
                        protectedMode.project = powersurge.testProject
                        protectedMode.paramet = '保护模式'
                        protectedMode.result = powersurge.protectedMode
                        protectedMode.standard = '/'
                        protectedMode.determine = '参考项'
                        this.changeData.push(protectedMode)
                    }
                    if (powersurge.statusIndicator && powersurge.testProject == '电源电涌保护器' && powersurge.statusIndicator != '请选择') {
                        const statusIndicator = {}
                        statusIndicator.point = powersurge.testPosition
                        statusIndicator.project = powersurge.testProject
                        statusIndicator.paramet = '状态指示器'
                        statusIndicator.result = powersurge.statusIndicator
                        statusIndicator.standard = '/'
                        statusIndicator.determine = '参考项'
                        this.changeData.push(statusIndicator)
                    }
                    if (powersurge.conductorColor && powersurge.testProject == '电源电涌保护器' && powersurge.conductorColor != '请选择') {
                        const conductorColor = {}
                        conductorColor.point = powersurge.testPosition
                        conductorColor.project = powersurge.testProject
                        conductorColor.paramet = '连接导体颜色'
                        conductorColor.result = powersurge.conductorColor
                        conductorColor.standard = '/'
                        conductorColor.determine = '参考项'
                        this.changeData.push(conductorColor)
                    }
                    if (powersurge.sumOfLengths && powersurge.testProject == '电源电涌保护器') {
                        const sumOfLengths = {}
                        sumOfLengths.point = powersurge.testPosition
                        sumOfLengths.project = powersurge.testProject
                        sumOfLengths.paramet = '两端引线长度之和(m)'
                        sumOfLengths.standard = powersurge.sumOfLengthsStandard
                        var changeObj = this.changeType(powersurge.sumOfLengths, sumOfLengths.standard)
                        sumOfLengths.result = this.evenRound(changeObj.changeResult, 1)
                        if (sumOfLengths.result <= changeObj.changeStandard) {
                            sumOfLengths.determine = '符合要求'
                        } else {
                            sumOfLengths.determine = '不符合要求'
                        }
                        this.changeData.push(sumOfLengths)
                    }
                    if (powersurge.spdOfDistance && powersurge.testProject == '电源电涌保护器') {
                        const spdOfDistance = {}
                        spdOfDistance.point = powersurge.testPosition
                        spdOfDistance.project = powersurge.testProject
                        spdOfDistance.paramet = 'SPD各级距离(m)'
                        spdOfDistance.standard = powersurge.spdOfDistanceStandard
                        var changeObj = this.changeType(powersurge.spdOfDistance, spdOfDistance.standard)
                        if (powersurge.spdOfDistance.match('<:D+>')) {
                            spdOfDistance.result = this.evenRound(changeObj.changeResult, 1)
                            if (spdOfDistance.result >= changeObj.changeStandard) {
                                spdOfDistance.determine = '符合要求'
                            } else {
                                spdOfDistance.determine = '不符合要求'
                            }
                        } else {
                            spdOfDistance.result = powersurge.spdOfDistance
                            spdOfDistance.determine = '符合要求'
                        }

                        this.changeData.push(spdOfDistance)
                    }
                    if (powersurge.powerLineEntryMethod && powersurge.testProject == '电源电涌保护器' && powersurge.powerLineEntryMethod != '请选择') {
                        const powerLineEntryMethod = {}
                        powerLineEntryMethod.point = powersurge.testPosition
                        powerLineEntryMethod.project = powersurge.testProject
                        powerLineEntryMethod.paramet = '电源线路入户方式'
                        powerLineEntryMethod.result = powersurge.powerLineEntryMethod
                        powerLineEntryMethod.standard = '/'
                        powerLineEntryMethod.determine = '参考项'
                        this.changeData.push(powerLineEntryMethod)
                    }
                    if (powersurge.electricalSystemGroundingType && powersurge.testProject == '电源电涌保护器' && powersurge.electricalSystemGroundingType != '请选择') {
                        const electricalSystemGroundingType = {}
                        electricalSystemGroundingType.point = powersurge.testPosition
                        electricalSystemGroundingType.project = powersurge.testProject
                        electricalSystemGroundingType.paramet = '配电系统接地型式'
                        electricalSystemGroundingType.result = powersurge.electricalSystemGroundingType
                        electricalSystemGroundingType.standard = '/'
                        electricalSystemGroundingType.determine = '参考项'
                        this.changeData.push(electricalSystemGroundingType)
                    }
                    if (powersurge.uc && powersurge.testProject == '电源电涌保护器') {
                        const uc = {}
                        uc.point = powersurge.testPosition
                        uc.project = powersurge.testProject
                        uc.paramet = `最大持续运行电压Uc( ${powersurge.ucUnit})`
                        uc.result = powersurge.uc
                        uc.standard = '/'
                        uc.determine = '参考项'
                        this.changeData.push(uc)
                    }
                    if (powersurge.ucN && powersurge.testProject == '电源电涌保护器') {
                        const ucN = {}
                        ucN.point = powersurge.testPosition
                        ucN.project = powersurge.testProject
                        ucN.paramet = `最大持续运行电压(N相)Uc( ${powersurge.ucNUnit})`
                        ucN.result = powersurge.ucN
                        ucN.standard = '/'
                        ucN.determine = '参考项'
                        this.changeData.push(ucN)
                    }
                    if (powersurge.up && powersurge.testProject == '电源电涌保护器') {
                        const up = {}
                        up.point = powersurge.testPosition
                        up.project = powersurge.testProject
                        up.paramet = '电压保护水平Up(kV)'
                        up.result = powersurge.up
                        up.standard = '/'
                        up.determine = '参考项'
                        this.changeData.push(up)
                    }
                    if (powersurge.upN && powersurge.testProject == '电源电涌保护器') {
                        const upN = {}
                        upN.point = powersurge.testPosition
                        upN.project = powersurge.testProject
                        upN.paramet = '电压保护水平(N相)Up(kV)'
                        upN.result = powersurge.upN
                        upN.standard = '/'
                        upN.determine = '参考项'
                        this.changeData.push(upN)
                    }
                    if (powersurge.uw && powersurge.testProject == '电源电涌保护器') {
                        const uw = {}
                        uw.point = powersurge.testPosition
                        uw.project = powersurge.testProject
                        uw.paramet = '被保护设备的耐冲击过电压额定值UW(kV)'
                        uw.result = powersurge.uw
                        uw.standard = '/'
                        uw.determine = '参考项'
                        this.changeData.push(uw)
                    }
                    if (powersurge.upf && powersurge.testProject == '电源电涌保护器') {
                        const upf = {}
                        upf.point = powersurge.testPosition
                        upf.project = powersurge.testProject
                        upf.paramet = '有效电压保护水平Up/f(kV)'
                        upf.result = powersurge.upf
                        upf.standard = '/'
                        upf.determine = '参考项'
                        this.changeData.push(upf)
                    }
                    if (powersurge.wireMaterialSpecifications && powersurge.testProject == '电源电涌保护器') {
                        const wireMaterialSpecifications = {}
                        wireMaterialSpecifications.point = powersurge.testPosition
                        wireMaterialSpecifications.project = powersurge.testProject
                        wireMaterialSpecifications.paramet = '连接导线材料规格'
                        wireMaterialSpecifications.result = powersurge.wireMaterialSpecifications
                        wireMaterialSpecifications.standard = '/'
                        wireMaterialSpecifications.determine = '参考项'
                        this.changeData.push(wireMaterialSpecifications)
                    }
                    if (powersurge.wireTransitionResistor && powersurge.testProject == '电源电涌保护器') {
                        const wireTransitionResistor = {}
                        wireTransitionResistor.point = powersurge.testPosition
                        wireTransitionResistor.project = powersurge.testProject
                        wireTransitionResistor.paramet = '连接导线的过渡电阻(Ω)'
                        wireTransitionResistor.standard = powersurge.wireTransitionResistorStandard
                        var changeObj = this.changeType(powersurge.wireTransitionResistor, wireTransitionResistor.standard)
                        wireTransitionResistor.result = this.evenRound(changeObj.changeResult, 3)
                        if (wireTransitionResistor.result <= changeObj.changeStandard) {
                            wireTransitionResistor.determine = '符合要求'
                        } else {
                            wireTransitionResistor.determine = '不符合要求'
                        }
                        this.changeData.push(wireTransitionResistor)
                    }
                    if (powersurge.impulseCurrent && powersurge.testProject == '电源电涌保护器') {
                        const impulseCurrent = {}
                        impulseCurrent.point = powersurge.testPosition
                        impulseCurrent.project = powersurge.testProject
                        impulseCurrent.paramet = '冲击电流Iimp(kA)'
                        impulseCurrent.result = powersurge.impulseCurrent
                        impulseCurrent.standard = '/'
                        impulseCurrent.determine = '参考项'
                        this.changeData.push(impulseCurrent)
                    }
                    if (powersurge.impulseCurrentN && powersurge.testProject == '电源电涌保护器') {
                        const impulseCurrentN = {}
                        impulseCurrentN.point = powersurge.testPosition
                        impulseCurrentN.project = powersurge.testProject
                        impulseCurrentN.paramet = '冲击电流(N相)Iimp(kA)'
                        impulseCurrentN.result = powersurge.impulseCurrentN
                        impulseCurrentN.standard = '/'
                        impulseCurrentN.determine = '参考项'
                        this.changeData.push(impulseCurrentN)
                    }
                    if (powersurge.iTotal && powersurge.testProject == '电源电涌保护器') {
                        const iTotal = {}
                        iTotal.point = powersurge.testPosition
                        iTotal.project = powersurge.testProject
                        iTotal.paramet = '总放电电流ITotal(kA)'
                        iTotal.result = powersurge.iTotal
                        iTotal.standard = '/'
                        iTotal.determine = '参考项'
                        this.changeData.push(iTotal)
                    }
                    if (powersurge.nominalDischargeEurrent && powersurge.testProject == '电源电涌保护器') {
                        const nominalDischargeEurrent = {}
                        nominalDischargeEurrent.point = powersurge.testPosition
                        nominalDischargeEurrent.project = powersurge.testProject
                        nominalDischargeEurrent.paramet = '标称放电电流In(kA)'
                        nominalDischargeEurrent.result = powersurge.nominalDischargeEurrent
                        nominalDischargeEurrent.standard = '/'
                        nominalDischargeEurrent.determine = '参考项'
                        this.changeData.push(nominalDischargeEurrent)
                    }
                    if (powersurge.nominalDischargeEurrentN && powersurge.testProject == '电源电涌保护器') {
                        const nominalDischargeEurrentN = {}
                        nominalDischargeEurrentN.point = powersurge.testPosition
                        nominalDischargeEurrentN.project = powersurge.testProject
                        nominalDischargeEurrentN.paramet = '标称放电电流(N相)In(kA)'
                        nominalDischargeEurrentN.result = powersurge.nominalDischargeEurrentN
                        nominalDischargeEurrentN.standard = '/'
                        nominalDischargeEurrentN.determine = '参考项'
                        this.changeData.push(nominalDischargeEurrentN)
                    }
                    if (powersurge.maxDischargeEurrent && powersurge.testProject == '电源电涌保护器') {
                        const maxDischargeEurrent = {}
                        maxDischargeEurrent.point = powersurge.testPosition
                        maxDischargeEurrent.project = powersurge.testProject
                        maxDischargeEurrent.paramet = '最大放电电流Imax(kA)'
                        maxDischargeEurrent.result = powersurge.maxDischargeEurrent
                        maxDischargeEurrent.standard = '/'
                        maxDischargeEurrent.determine = '参考项'
                        this.changeData.push(maxDischargeEurrent)
                    }
                    if (powersurge.maxDischargeEurrentN && powersurge.testProject == '电源电涌保护器') {
                        const maxDischargeEurrentN = {}
                        maxDischargeEurrentN.point = powersurge.testPosition
                        maxDischargeEurrentN.project = powersurge.testProject
                        maxDischargeEurrentN.paramet = '最大放电电流(N相)Imax(kA)'
                        maxDischargeEurrentN.result = powersurge.maxDischargeEurrentN
                        maxDischargeEurrentN.standard = '/'
                        maxDischargeEurrentN.determine = '参考项'
                        this.changeData.push(maxDischargeEurrentN)
                    }
                    if (powersurge.terminalEarthResistance && powersurge.testProject == '电源电涌保护器') {
                        const terminalEarthResistance = {}
                        terminalEarthResistance.point = powersurge.testPosition
                        terminalEarthResistance.project = powersurge.testProject
                        terminalEarthResistance.paramet = '接地端接地电阻(Ω)'
                        terminalEarthResistance.standard = powersurge.terminalEarthResistanceStandard
                        var changeObj = this.changeType(powersurge.terminalEarthResistance, terminalEarthResistance.standard)
                        terminalEarthResistance.result = this.evenRound(changeObj.changeResult, 1)
                        if (terminalEarthResistance.result <= changeObj.changeStandard) {
                            terminalEarthResistance.determine = '符合要求'
                        } else {
                            terminalEarthResistance.determine = '不符合要求'
                        }
                        this.changeData.push(terminalEarthResistance)
                    }
                    if (powersurge.u1maL1 && powersurge.testProject == '电源电涌保护器') {
                        const u1maL1 = {}
                        u1maL1.point = powersurge.testPosition
                        u1maL1.project = powersurge.testProject
                        u1maL1.paramet = '压敏电压U1 mA (L1)(V)'
                        u1maL1.standard = powersurge.u1maStandard
                        var changeObj = this.changeType(powersurge.u1maL1, u1maL1.standard)
                        u1maL1.result = this.evenRound(changeObj.changeResult, 0)
                        if (u1maL1.standard == '参考项') {
                            u1maL1.standard = '/'
                            u1maL1.determine = '参考项'
                        } else {
                            if (powersurge.u1maStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (u1maL1.result <= maxu1m && u1maL1.result >= minu1m) {
                                    u1maL1.determine = '符合要求'
                                } else {
                                    u1maL1.determine = '不符合要求'
                                }
                            } else {
                                if (u1maL1.result >= changeObj.changeStandard) {
                                    u1maL1.determine = '符合要求'
                                } else {
                                    u1maL1.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(u1maL1)
                    }
                    if (powersurge.u1maL2 && powersurge.testProject == '电源电涌保护器') {
                        const u1maL2 = {}
                        u1maL2.point = powersurge.testPosition
                        u1maL2.project = powersurge.testProject
                        u1maL2.paramet = '压敏电压U1 mA (L2)(V)'
                        u1maL2.standard = powersurge.u1maStandard
                        var changeObj = this.changeType(powersurge.u1maL2, u1maL2.standard)
                        u1maL2.result = this.evenRound(changeObj.changeResult, 0)
                        if (u1maL2.standard == '参考项') {
                            u1maL2.standard = '/'
                            u1maL2.determine = '参考项'
                        } else {
                            if (powersurge.u1maStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (u1maL2.result <= maxu1m && u1maL2.result >= minu1m) {
                                    u1maL2.determine = '符合要求'
                                } else {
                                    u1maL2.determine = '不符合要求'
                                }
                            } else {
                                if (u1maL2.result >= changeObj.changeStandard) {
                                    u1maL2.determine = '符合要求'
                                } else {
                                    u1maL2.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(u1maL2)
                    }
                    if (powersurge.u1maL3 && powersurge.testProject == '电源电涌保护器') {
                        const u1maL3 = {}
                        u1maL3.point = powersurge.testPosition
                        u1maL3.project = powersurge.testProject
                        u1maL3.paramet = '压敏电压U1 mA (L3)(V)'
                        u1maL3.standard = powersurge.u1maStandard
                        var changeObj = this.changeType(powersurge.u1maL3, u1maL3.standard)
                        u1maL3.result = this.evenRound(changeObj.changeResult, 0)
                        if (u1maL3.standard == '参考项') {
                            u1maL3.standard = '/'
                            u1maL3.determine = '参考项'
                        } else {
                            if (powersurge.u1maStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (u1maL3.result <= maxu1m && u1maL3.result >= minu1m) {
                                    u1maL3.determine = '符合要求'
                                } else {
                                    u1maL3.determine = '不符合要求'
                                }
                            } else {
                                if (u1maL3.result >= changeObj.changeStandard) {
                                    u1maL3.determine = '符合要求'
                                } else {
                                    u1maL3.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(u1maL3)
                    }
                    if (powersurge.u1maN && powersurge.testProject == '电源电涌保护器') {
                        const u1maN = {}
                        u1maN.point = powersurge.testPosition
                        u1maN.project = powersurge.testProject
                        u1maN.paramet = '压敏电压U1 mA (N)(V)'
                        if (powersurge.u1maNStandard) {
                            u1maN.standard = powersurge.u1maNStandard
                        } else {
                            u1maN.standard = powersurge.u1maStandard
                        }
                        var changeObj = this.changeType(powersurge.u1maN, u1maN.standard)
                        u1maN.result = this.evenRound(changeObj.changeResult, 0)
                        if (u1maN.standard == '参考项') {
                            u1maN.standard = '/'
                            u1maN.determine = '参考项'
                        } else {
                            if (powersurge.u1maNStandard) {  //判断之前没有u1maNStandard参数数据出错问题
                                if (powersurge.u1maNStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (u1maN.result <= maxu1m && u1maN.result >= minu1m) {
                                        u1maN.determine = '符合要求'
                                    } else {
                                        u1maN.determine = '不符合要求'
                                    }
                                } else {
                                    if (u1maN.result >= changeObj.changeStandard) {
                                        u1maN.determine = '符合要求'
                                    } else {
                                        u1maN.determine = '不符合要求'
                                    }
                                }
                            } else {
                                if (powersurge.u1maStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (u1maN.result <= maxu1m && u1maN.result >= minu1m) {
                                        u1maN.determine = '符合要求'
                                    } else {
                                        u1maN.determine = '不符合要求'
                                    }
                                } else {
                                    if (u1maN.result >= changeObj.changeStandard) {
                                        u1maN.determine = '符合要求'
                                    } else {
                                        u1maN.determine = '不符合要求'
                                    }
                                }
                            }

                        }
                        this.changeData.push(u1maN)
                    }
                    if (powersurge.iieL1 && powersurge.testProject == '电源电涌保护器') {
                        const iieL1 = {}
                        iieL1.point = powersurge.testPosition
                        iieL1.project = powersurge.testProject
                        iieL1.paramet = '泄漏电流Iie (L1)(μA)'
                        iieL1.standard = powersurge.iieStandard
                        var changeObj = this.changeType(powersurge.iieL1, iieL1.standard)
                        iieL1.result = this.evenRound(changeObj.changeResult, 1)
                        if (iieL1.standard == '参考项') {
                            iieL1.standard = '/'
                            iieL1.determine = '参考项'
                        } else {
                            if (iieL1.result <= changeObj.changeStandard) {
                                iieL1.determine = '符合要求'
                            } else {
                                iieL1.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(iieL1)
                    }
                    if (powersurge.iieL2 && powersurge.testProject == '电源电涌保护器') {
                        const iieL2 = {}
                        iieL2.point = powersurge.testPosition
                        iieL2.project = powersurge.testProject
                        iieL2.paramet = '泄漏电流Iie (L2)(μA)'
                        iieL2.standard = powersurge.iieStandard
                        var changeObj = this.changeType(powersurge.iieL2, iieL2.standard)
                        iieL2.result = this.evenRound(changeObj.changeResult, 1)
                        if (iieL2.standard == '参考项') {
                            iieL2.standard = '/'
                            iieL2.determine = '参考项'
                        } else {
                            if (iieL2.result <= changeObj.changeStandard) {
                                iieL2.determine = '符合要求'
                            } else {
                                iieL2.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(iieL2)
                    }
                    if (powersurge.iieL3 && powersurge.testProject == '电源电涌保护器') {
                        const iieL3 = {}
                        iieL3.point = powersurge.testPosition
                        iieL3.project = powersurge.testProject
                        iieL3.paramet = '泄漏电流Iie (L3)(μA)'
                        iieL3.standard = powersurge.iieStandard
                        var changeObj = this.changeType(powersurge.iieL3, iieL3.standard)
                        iieL3.result = this.evenRound(changeObj.changeResult, 1)
                        if (iieL3.standard == '参考项') {
                            iieL3.standard = '/'
                            iieL3.determine = '参考项'
                        } else {
                            if (iieL3.result <= changeObj.changeStandard) {
                                iieL3.determine = '符合要求'
                            } else {
                                iieL3.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(iieL3)
                    }
                    if (powersurge.iieN && powersurge.testProject == '电源电涌保护器') {
                        const iieN = {}
                        iieN.point = powersurge.testPosition
                        iieN.project = powersurge.testProject
                        iieN.paramet = '泄漏电流Iie (N)(μA)'
                        iieN.standard = powersurge.iieStandard
                        var changeObj = this.changeType(powersurge.iieN, iieN.standard)
                        iieN.result = this.evenRound(changeObj.changeResult, 1)
                        if (iieN.standard == '参考项') {
                            iieN.standard = '/'
                            iieN.determine = '参考项'
                        } else {
                            if (iieN.result <= changeObj.changeStandard) {
                                iieN.determine = '符合要求'
                            } else {
                                iieN.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(iieN)
                    }
                    if (powersurge.insulationResistanceL1 && powersurge.testProject == '电源电涌保护器') {
                        const insulationResistanceL1 = {}
                        insulationResistanceL1.point = powersurge.testPosition
                        insulationResistanceL1.project = powersurge.testProject
                        insulationResistanceL1.paramet = '绝缘电阻 (L1)(MΩ)'
                        insulationResistanceL1.standard = powersurge.insulationResistanceStandard
                        var changeObj = this.changeType(powersurge.insulationResistanceL1, insulationResistanceL1.standard)
                        insulationResistanceL1.result = powersurge.insulationResistanceL1
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            insulationResistanceL1.determine = '符合要求'
                        } else {
                            insulationResistanceL1.determine = '不符合要求'
                        }
                        this.changeData.push(insulationResistanceL1)
                    }
                    if (powersurge.insulationResistanceL2 && powersurge.testProject == '电源电涌保护器') {
                        const insulationResistanceL2 = {}
                        insulationResistanceL2.point = powersurge.testPosition
                        insulationResistanceL2.project = powersurge.testProject
                        insulationResistanceL2.paramet = '绝缘电阻 (L2)(MΩ)'
                        insulationResistanceL2.standard = powersurge.insulationResistanceStandard
                        var changeObj = this.changeType(powersurge.insulationResistanceL2, insulationResistanceL2.standard)
                        insulationResistanceL2.result = powersurge.insulationResistanceL2
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            insulationResistanceL2.determine = '符合要求'
                        } else {
                            insulationResistanceL2.determine = '不符合要求'
                        }
                        this.changeData.push(insulationResistanceL2)
                    }
                    if (powersurge.insulationResistanceL3 && powersurge.testProject == '电源电涌保护器') {
                        const insulationResistanceL3 = {}
                        insulationResistanceL3.point = powersurge.testPosition
                        insulationResistanceL3.project = powersurge.testProject
                        insulationResistanceL3.paramet = '绝缘电阻 (L3)(MΩ)'
                        insulationResistanceL3.standard = powersurge.insulationResistanceStandard
                        var changeObj = this.changeType(powersurge.insulationResistanceL3, insulationResistanceL3.standard)
                        insulationResistanceL3.result = powersurge.insulationResistanceL3
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            insulationResistanceL3.determine = '符合要求'
                        } else {
                            insulationResistanceL3.determine = '不符合要求'
                        }
                        this.changeData.push(insulationResistanceL3)
                    }
                    if (powersurge.insulationResistanceN && powersurge.testProject == '电源电涌保护器') {
                        const insulationResistanceN = {}
                        insulationResistanceN.point = powersurge.testPosition
                        insulationResistanceN.project = powersurge.testProject
                        insulationResistanceN.paramet = '绝缘电阻 (N)(MΩ)'
                        insulationResistanceN.standard = powersurge.insulationResistanceStandard
                        var changeObj = this.changeType(powersurge.insulationResistanceN, insulationResistanceN.standard)
                        insulationResistanceN.result = powersurge.insulationResistanceN
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            insulationResistanceN.determine = '符合要求'
                        } else {
                            insulationResistanceN.determine = '不符合要求'
                        }
                        this.changeData.push(insulationResistanceN)
                    }
                    if (powersurge.photo && powersurge.testProject == '电源电涌保护器') {
                        const photo = {}
                        let imgsrcs = powersurge.photo.split('|')
                        for (let i in imgsrcs) {
                            if (imgsrcs[i] != null && imgsrcs[i] != '') {
                                this.$axios({
                                    method: 'get',
                                    url: '/lps/hwcloud/obs/downurl',
                                    params: {
                                        objname: imgsrcs[i]
                                    }
                                }).then(res => {
                                    if (res.data != null) {
                                        // this.$set(photo, 'point' , powersurge.testPosition)
                                        // this.$set(photo, 'project', powersurge.testProject)
                                        photo.point = powersurge.testPosition
                                        photo.project = powersurge.testProject
                                        this.$set(photo, 'imgurl', res.data.url)
                                        this.$set(photo, 'paramet', 'SPD现场图片')
                                        this.$set(photo, 'type', 'photo')
                                        this.urlList.push(res.data.url)
                                    }
                                })
                            }
                        }
                        this.changeData.push(photo)
                    }
                }
            } else {
                for (let i = 0; i < this.SubrgionName.sourceDataList.length; i++) {
                    let subregion = this.SubrgionName.sourceDataList[i]
                    let downlead = this.SubrgionName.sourceDataList[i]
                    let earthing = this.SubrgionName.sourceDataList[i]
                    let strike = this.SubrgionName.sourceDataList[i]
                    let powersurge = this.SubrgionName.sourceDataList[i]
                    let equipotential = this.SubrgionName.sourceDataList[i]
                    let lightning = this.SubrgionName.sourceDataList[i]
                    if (subregion.lx && subregion.testProject == '接闪器' && subregion.lx != '请选择') {
                        const lx = {}
                        lx.point = subregion.testPosition
                        lx.project = subregion.testProject
                        lx.paramet = '类型'
                        lx.result = subregion.lx
                        lx.standard = '/'
                        lx.determine = '参考项'
                        this.changeData.push(lx)
                    }
                    if (subregion.fs && subregion.testProject == '接闪器' && subregion.fs != '请选择') {
                        const fs = {}
                        fs.point = subregion.testPosition
                        fs.project = subregion.testProject
                        fs.paramet = '方式'
                        fs.result = subregion.fs
                        fs.standard = '/'
                        fs.determine = '参考项'
                        this.changeData.push(fs)
                    }
                    if (subregion.fswz && subregion.testProject == '接闪器' && subregion.fswz != '请选择') {
                        const fswz = {}
                        fswz.point = subregion.testPosition
                        fswz.project = subregion.testProject
                        fswz.paramet = '敷设位置'
                        fswz.result = subregion.fswz
                        fswz.standard = '/'
                        fswz.determine = '参考项'
                        this.changeData.push(fswz)
                    }
                    if (subregion.cl && subregion.testProject == '接闪器') {
                        const cl = {}
                        cl.point = subregion.testPosition
                        cl.project = subregion.testProject
                        cl.paramet = '材料'
                        cl.result = subregion.cl
                        cl.standard = '/'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (subregion.gg && subregion.testProject == '接闪器') {
                        const gg = {}
                        gg.point = subregion.testPosition
                        gg.project = subregion.testProject
                        if (subregion.gg.includes('mm²')) {
                            gg.paramet = '规格(mm²)'
                        } else {
                            gg.paramet = '规格(mm)'
                        }
                        if (subregion.ggStandard == '参考项') {
                            gg.result = subregion.gg
                            gg.standard = '/'
                            gg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(subregion.gg, subregion.ggStandard)
                            gg.result = this.evenRound(changeObj.changeResult, 0)
                            if (gg.result >= changeObj.changeStandard) {
                                gg.standard = subregion.ggStandard
                                gg.determine = '符合要求'
                            } else {
                                gg.standard = subregion.ggStandard
                                gg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(gg)
                    }
                    if (subregion.azgy && subregion.testProject == '接闪器' && subregion.azgy != '请选择') {
                        const azgy = {}
                        azgy.point = subregion.testPosition
                        azgy.project = subregion.testProject
                        azgy.paramet = '安装工艺'
                        azgy.result = subregion.azgy
                        azgy.standard = '/'
                        azgy.determine = '参考项'
                        this.changeData.push(azgy)
                    }
                    if (subregion.xz && subregion.testProject == '接闪器' && subregion.xz != '请选择') {
                        const xz = {}
                        xz.point = subregion.testPosition
                        xz.project = subregion.testProject
                        xz.paramet = '现状'
                        xz.result = subregion.xz
                        xz.standard = '/'
                        xz.determine = '参考项'
                        this.changeData.push(xz)
                    }
                    if (subregion.gdzjdjj && subregion.testProject == '接闪器') {
                        const gdzjdjj = {}
                        gdzjdjj.point = subregion.testPosition
                        gdzjdjj.project = subregion.testProject
                        gdzjdjj.paramet = '固定支架间距(m)'
                        gdzjdjj.standard = subregion.gdzjdjjStandard
                        var changeObj = this.changeType(subregion.gdzjdjj, gdzjdjj.standard)
                        gdzjdjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (gdzjdjj.result <= changeObj.changeStandard) {
                            gdzjdjj.determine = '符合要求'
                        } else {
                            gdzjdjj.determine = '不符合要求'
                        }
                        this.changeData.push(gdzjdjj)
                    }
                    if (subregion.djksz && subregion.testProject == '接闪器') {
                        const djksz = {}
                        djksz.point = subregion.testPosition
                        djksz.project = subregion.testProject
                        djksz.paramet = '固定支架高度(mm)'
                        djksz.standard = '≥150'
                        var changeObj = this.changeType(subregion.djksz, djksz.standard)
                        djksz.result = this.evenRound(changeObj.changeResult, 0)
                        if (djksz.result >= changeObj.changeStandard) {
                            djksz.determine = '符合要求'
                        } else {
                            djksz.determine = '不符合要求'
                        }
                        this.changeData.push(djksz)
                    }
                    if (subregion.gdzjdczll && subregion.testProject == '接闪器') {
                        const gdzjdczll = {}
                        gdzjdczll.point = subregion.testPosition
                        gdzjdczll.project = subregion.testProject
                        gdzjdczll.paramet = '固定支架的垂直拉力(N)'
                        gdzjdczll.standard = '≥49'
                        var changeObj = this.changeType(subregion.gdzjdczll, gdzjdczll.standard)
                        gdzjdczll.result = this.evenRound(changeObj.changeResult, 0)
                        if (gdzjdczll.result >= changeObj.changeStandard) {
                            gdzjdczll.determine = '符合要求'
                        } else {
                            gdzjdczll.determine = '不符合要求'
                        }
                        this.changeData.push(gdzjdczll)
                    }
                    if (subregion.wgcc && subregion.testProject == '接闪器') {
                        const wgcc = {}
                        wgcc.point = subregion.testPosition
                        wgcc.project = subregion.testProject
                        wgcc.paramet = '网格尺寸(m)'
                        wgcc.standard = subregion.wgccStandard
                        var cresult = subregion.wgcc.match(/(-?\d+)(\.\d+)?/g)
                        cresult = cresult.map(Number)
                        //计算检测结果中的最大值和最小值
                        var mincresult = Math.min(...cresult)
                        var maxcresult = Math.max(...cresult)
                        var gone = this.evenRound(mincresult, 1)
                        var gtwo = this.evenRound(maxcresult, 1)
                        var cresults = gone + '×' + gtwo
                        wgcc.result = cresults
                        if (subregion.wgccStandard) {
                            var cstandard = subregion.wgccStandard.match(/\d+(\.\d+)?/g)
                            cstandard = cstandard.map(Number)
                            var minnumber
                            var maxnumber
                            if (cstandard[2] > cstandard[3]) {
                                minnumber = cstandard[3]
                                maxnumber = cstandard[2]
                            } else {
                                minnumber = cstandard[2]
                                maxnumber = cstandard[3]
                            }
                            // 检测结果中的最大值小于标准值中第一个值，或结果最小值小于标准中最小值、最大值小于标准最大值则符合要求，否则不符合
                            if (maxcresult <= cstandard[0] || mincresult <= minnumber && maxcresult <= maxnumber) {
                                wgcc.determine = '符合要求'
                            } else {
                                wgcc.determine = '不符合要求'
                            } var minnumber
                            var maxnumber
                            if (cstandard[2] > cstandard[3]) {
                                minnumber = cstandard[3]
                                maxnumber = cstandard[2]
                            } else {
                                minnumber = cstandard[2]
                                maxnumber = cstandard[3]
                            }
                            // 检测结果中的最大值小于标准值中第一个值，或结果最小值小于标准中最小值、最大值小于标准最大值则符合要求，否则不符合
                            if (maxcresult <= cstandard[0] || mincresult <= minnumber && maxcresult <= maxnumber) {
                                wgcc.determine = '符合要求'
                            } else {
                                wgcc.determine = '不符合要求'
                            } var minnumber
                            var maxnumber
                            if (cstandard[2] > cstandard[3]) {
                                minnumber = cstandard[3]
                                maxnumber = cstandard[2]
                            } else {
                                minnumber = cstandard[2]
                                maxnumber = cstandard[3]
                            }
                            // 检测结果中的最大值小于标准值中第一个值，或结果最小值小于标准中最小值、最大值小于标准最大值则符合要求，否则不符合
                            if (maxcresult <= cstandard[0] || mincresult <= minnumber && maxcresult <= maxnumber) {
                                wgcc.determine = '符合要求'
                            } else {
                                wgcc.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(wgcc)
                    }
                    if (subregion.xz && subregion.testProject == '接闪器' && subregion.xz != '请选择') {
                        const xz = {}
                        xz.point = subregion.testPosition
                        xz.project = subregion.testProject
                        xz.paramet = '伸缩缝处的跨接'
                        xz.result = subregion.xz
                        xz.standard = '/'
                        xz.determine = '参考项'
                        this.changeData.push(xz)
                    }
                    if (subregion.xs && subregion.testProject == '接闪器' && subregion.xs != '请选择') {
                        const xs = {}
                        xs.point = subregion.testPosition
                        xs.project = subregion.testProject
                        xs.paramet = '防腐措施'
                        xs.result = subregion.xs
                        xs.standard = '/'
                        xs.determine = '参考项'
                        this.changeData.push(xs)
                    }
                    if (subregion.xs && subregion.testProject == '接闪器' && subregion.xs != '请选择') {
                        const xs = {}
                        xs.point = subregion.testPosition
                        xs.project = subregion.testProject
                        xs.paramet = '锈蚀'
                        xs.result = subregion.xs
                        xs.standard = '/'
                        xs.determine = '参考项'
                        this.changeData.push(xs)
                    }
                    if (subregion.ddwljxn && subregion.testProject == '接闪器') {
                        const ddwljxn = {}
                        ddwljxn.point = subregion.testPosition
                        ddwljxn.project = subregion.testProject
                        ddwljxn.paramet = '等电位连接性能(Ω)'
                        ddwljxn.standard = subregion.ddwljxnStandard
                        var changeObj = this.changeType(subregion.ddwljxn, ddwljxn.standard)
                        ddwljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwljxn.result <= changeObj.changeStandard) {
                            ddwljxn.determine = '符合要求'
                        } else {
                            ddwljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwljxn)
                    }
                    if (subregion.fz && subregion.testProject == '接闪器' && subregion.fz != '请选择') {
                        const fz = {}
                        fz.point = subregion.testPosition
                        fz.project = subregion.testProject
                        fz.paramet = '附着'
                        fz.result = subregion.fz
                        fz.standard = '/'
                        fz.determine = '参考项'
                        this.changeData.push(fz)
                    }
                    if (subregion.jgjl && subregion.testProject == '接闪器') {
                        const jgjl = {}
                        jgjl.point = subregion.testPosition
                        jgjl.project = subregion.testProject
                        jgjl.paramet = '间隔距离(m)'
                        jgjl.result = subregion.jgjl
                        jgjl.standard = '/'
                        jgjl.determine = '参考项'
                        this.changeData.push(jgjl)
                    }
                    if (subregion.bhfw && subregion.testProject == '接闪器') {
                        const bhfw = {}
                        bhfw.point = subregion.testPosition
                        bhfw.project = subregion.testProject
                        bhfw.paramet = '保护范围(m)'
                        bhfw.result = subregion.bhfw
                        bhfw.standard = '/'
                        bhfw.determine = '参考项'
                        this.changeData.push(bhfw)
                    }
                    if (subregion.fcjcs && subregion.testProject == '接闪器' && subregion.fcjcs != '请选择') {
                        const fcjcs = {}
                        fcjcs.point = subregion.testPosition
                        fcjcs.project = subregion.testProject
                        fcjcs.paramet = '防侧击措施'
                        fcjcs.result = subregion.fcjcs
                        fcjcs.standard = '/'
                        fcjcs.determine = '参考项'
                        this.changeData.push(fcjcs)
                    }
                    //引下线相关数据
                    if (downlead.lx && downlead.testProject == '引下线' && downlead.lx != '请选择') {
                        const downleadlx = {}
                        downleadlx.point = downlead.testPosition
                        downleadlx.project = downlead.testProject
                        downleadlx.paramet = '类型'
                        downleadlx.result = downlead.lx
                        downleadlx.standard = '/'
                        downleadlx.determine = '参考项'
                        this.changeData.push(downleadlx)
                    }
                    if (downlead.fs && downlead.testProject == '引下线' && downlead.fs != '请选择') {
                        const downleadfs = {}
                        downleadfs.point = downlead.testPosition
                        downleadfs.project = downlead.testProject
                        downleadfs.paramet = '敷设'
                        downleadfs.result = downlead.fs
                        downleadfs.standard = '/'
                        downleadfs.determine = '参考项'
                        this.changeData.push(downleadfs)
                    }
                    if (downlead.cl && downlead.testProject == '引下线') {
                        const downleadcl = {}
                        downleadcl.point = downlead.testPosition
                        downleadcl.project = downlead.testProject
                        downleadcl.paramet = '材料'
                        downleadcl.result = downlead.cl
                        downleadcl.standard = '/'
                        downleadcl.determine = '参考项'
                        this.changeData.push(downleadcl)
                    }
                    if (downlead.gg && downlead.testProject == '引下线') {
                        const downleadgg = {}
                        downleadgg.point = downlead.testPosition
                        downleadgg.project = downlead.testProject
                        if (downlead.gg.includes('mm²')) {
                            downleadgg.paramet = '规格(mm²)'
                        } else {
                            downleadgg.paramet = '规格(mm)'
                        }
                        if (downlead.ggStandard == '参考项') {
                            downleadgg.result = downlead.gg
                            downleadgg.standard = '/'
                            downleadgg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(downlead.gg, downlead.ggStandard)
                            downleadgg.result = this.evenRound(changeObj.changeResult, 0)
                            if (downleadgg.result >= changeObj.changeStandard) {
                                downleadgg.standard = downlead.ggStandard
                                downleadgg.determine = '符合要求'
                            } else {
                                downleadgg.standard = downlead.ggStandard
                                downleadgg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(downleadgg)
                    }
                    if (downlead.azgy && downlead.testProject == '引下线') {
                        const downleadazgy = {}
                        downleadazgy.point = downlead.testPosition
                        downleadazgy.project = downlead.testProject
                        downleadazgy.paramet = '安装工艺'
                        downleadazgy.result = downlead.azgy
                        downleadazgy.standard = '/'
                        downleadazgy.determine = '参考项'
                        this.changeData.push(downleadazgy)
                    }
                    if (downlead.xz && downlead.testProject == '引下线' && downlead.xz != '请选择') {
                        const downleadxz = {}
                        downleadxz.point = downlead.testPosition
                        downleadxz.project = downlead.testProject
                        downleadxz.paramet = '现状'
                        downleadxz.result = downlead.xz
                        downleadxz.standard = '/'
                        downleadxz.determine = '参考项'
                        this.changeData.push(downleadxz)
                    }
                    if (downlead.xs && downlead.testProject == '引下线' && downlead.xs != '请选择') {
                        const downleadxs = {}
                        downleadxs.point = downlead.testPosition
                        downleadxs.project = downlead.testProject
                        downleadxs.paramet = '锈蚀'
                        downleadxs.result = downlead.xs
                        downleadxs.standard = '/'
                        downleadxs.determine = '参考项'
                        this.changeData.push(downleadxs)
                    }
                    if (downlead.yjsqdljgddz && downlead.testProject == '引下线') {
                        const yjsqdljgddz = {}
                        yjsqdljgddz.point = downlead.testPosition
                        yjsqdljgddz.project = downlead.testProject
                        yjsqdljgddz.paramet = '与接闪器的连接过渡电阻(Ω)'
                        yjsqdljgddz.standard = '≤0.2'
                        var changeObj = this.changeType(downlead.yjsqdljgddz, yjsqdljgddz.standard)
                        yjsqdljgddz.result = this.evenRound(changeObj.changeResult, 3)
                        if (yjsqdljgddz.result <= changeObj.changeStandard) {
                            yjsqdljgddz.determine = '符合要求'
                        } else {
                            yjsqdljgddz.determine = '不符合要求'
                        }
                        this.changeData.push(yjsqdljgddz)
                    }
                    if (downlead.yjdtdljgddz && downlead.testProject == '引下线') {
                        const yjdtdljgddz = {}
                        yjdtdljgddz.point = downlead.testPosition
                        yjdtdljgddz.project = downlead.testProject
                        yjdtdljgddz.paramet = '与接地体的连接过渡电阻(Ω)'
                        yjdtdljgddz.standard = '≤0.2'
                        var changeObj = this.changeType(downlead.yjdtdljgddz, yjdtdljgddz.standard)
                        yjdtdljgddz.result = this.evenRound(changeObj.changeResult, 3)
                        if (yjdtdljgddz.result <= changeObj.changeStandard) {
                            yjdtdljgddz.determine = '符合要求'
                        } else {
                            yjdtdljgddz.determine = '不符合要求'
                        }
                        this.changeData.push(yjdtdljgddz)
                    }
                    if (downlead.gdzjdjj && downlead.testProject == '引下线') {
                        const downleadgdzjdjj = {}
                        downleadgdzjdjj.point = downlead.testPosition
                        downleadgdzjdjj.project = downlead.testProject
                        downleadgdzjdjj.paramet = '固定支架的间距(m)'
                        downleadgdzjdjj.standard = downlead.gdzjdjjStandard
                        var changeObj = this.changeType(downlead.gdzjdjj, downleadgdzjdjj.standard)
                        downleadgdzjdjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (downleadgdzjdjj.result <= changeObj.changeStandard) {
                            downleadgdzjdjj.determine = '符合要求'
                        } else {
                            downleadgdzjdjj.determine = '不符合要求'
                        }
                        this.changeData.push(downleadgdzjdjj)
                    }
                    if (downlead.gdzjdczll && downlead.testProject == '引下线') {
                        const downleadgdzjdczll = {}
                        downleadgdzjdczll.point = downlead.testPosition
                        downleadgdzjdczll.project = downlead.testProject
                        downleadgdzjdczll.paramet = '固定支架的垂直拉力(N)'
                        downleadgdzjdczll.standard = '≥49'
                        var changeObj = this.changeType(downlead.gdzjdczll, downleadgdzjdczll.standard)
                        downleadgdzjdczll.result = this.evenRound(changeObj.changeResult, 0)
                        if (downleadgdzjdczll.result >= changeObj.changeStandard) {
                            downleadgdzjdczll.determine = '符合要求'
                        } else {
                            downleadgdzjdczll.determine = '不符合要求'
                        }
                        this.changeData.push(downleadgdzjdczll)
                    }
                    if (downlead.djksz && downlead.testProject == '引下线') {
                        const downleaddjksz = {}
                        downleaddjksz.point = downlead.testPosition
                        downleaddjksz.project = downlead.testProject
                        downleaddjksz.paramet = '断接卡设置'
                        downleaddjksz.result = downlead.djksz
                        downleaddjksz.standard = '/'
                        downleaddjksz.determine = '参考项'
                        this.changeData.push(downleaddjksz)
                    }
                    if (downlead.bhcs && downlead.testProject == '引下线' && downlead.bhcs != '请选择') {
                        const downleadbhcs = {}
                        downleadbhcs.point = downlead.testPosition
                        downleadbhcs.project = downlead.testProject
                        downleadbhcs.paramet = '保护措施'
                        downleadbhcs.result = downlead.bhcs
                        downleadbhcs.standard = '/'
                        downleadbhcs.determine = '参考项'
                        this.changeData.push(downleadbhcs)
                    }
                    if (downlead.fjcdyhpcsldycs && downlead.testProject == '引下线' && downlead.fjcdyhpcsldycs != '请选择') {
                        const fjcdyhpcsldycs = {}
                        fjcdyhpcsldycs.point = downlead.testPosition
                        fjcdyhpcsldycs.project = downlead.testProject
                        fjcdyhpcsldycs.paramet = '防接触电压和旁侧闪络电压措施'
                        fjcdyhpcsldycs.result = downlead.fjcdyhpcsldycs
                        fjcdyhpcsldycs.standard = '/'
                        fjcdyhpcsldycs.determine = '参考项'
                        this.changeData.push(fjcdyhpcsldycs)
                    }
                    if (downlead.fz && downlead.testProject == '引下线' && downlead.fz != '请选择') {
                        const downleadfz = {}
                        downleadfz.point = downlead.testPosition
                        downleadfz.project = downlead.testProject
                        downleadfz.paramet = '附着'
                        downleadfz.result = downlead.fz
                        downleadfz.standard = '/'
                        downleadfz.determine = '参考项'
                        this.changeData.push(downleadfz)
                    }
                    if (downlead.jgjl && downlead.testProject == '引下线') {
                        const jgjl = {}
                        jgjl.point = downlead.testPosition
                        jgjl.project = downlead.testProject
                        jgjl.paramet = '间隔距离(m)'
                        jgjl.standard = downlead.jgjlStandard
                        var changeObj = this.changeType(downlead.jgjl, jgjl.standard)
                        jgjl.result = this.evenRound(changeObj.changeResult, 1)
                        if (jgjl.result >= changeObj.changeStandard) {
                            jgjl.determine = '符合要求'
                        } else {
                            jgjl.determine = '不符合要求'
                        }
                        this.changeData.push(jgjl)
                    }
                    if (downlead.sl && downlead.testProject == '引下线' && downlead.sl != '请选择') {
                        const sl = {}
                        sl.point = downlead.testPosition
                        sl.project = downlead.testProject
                        sl.paramet = '数量'
                        sl.result = downlead.sl
                        sl.standard = '/'
                        sl.determine = '参考项'
                        this.changeData.push(sl)
                    }
                    if (downlead.pjjj && downlead.testProject == '引下线') {
                        const pjjj = {}
                        pjjj.point = downlead.testPosition
                        pjjj.project = downlead.testProject
                        pjjj.paramet = '平均间距(m)'
                        pjjj.standard = downlead.pjjjStandard
                        var changeObj = this.changeType(downlead.pjjj, pjjj.standard)
                        pjjj.result = this.evenRound(changeObj.changeResult, 1)
                        if (pjjj.result <= changeObj.changeStandard) {
                            pjjj.determine = '符合要求'
                        } else {
                            pjjj.determine = '不符合要求'
                        }
                        this.changeData.push(pjjj)
                    }
                    if (downlead.jj && downlead.testProject == '引下线' && downlead.jj != '请选择') {
                        const jj = {}
                        jj.point = downlead.testPosition
                        jj.project = downlead.testProject
                        jj.paramet = '间距(m)'
                        jj.result = downlead.jj
                        jj.standard = '/'
                        jj.determine = '参考项'
                        this.changeData.push(jj)
                    }
                    //接地装置相关数据
                    if (earthing.lx && earthing.testProject == '接地装置' && earthing.lx != '请选择') {
                        const lx = {}
                        lx.point = earthing.testPosition
                        lx.project = earthing.testProject
                        lx.paramet = '类型'
                        lx.result = earthing.lx
                        lx.standard = '/'
                        lx.determine = '参考项'
                        this.changeData.push(lx)
                    }
                    if (earthing.bz && earthing.testProject == '接地装置' && earthing.bz != '请选择') {
                        const bz = {}
                        bz.point = earthing.testPosition
                        bz.project = earthing.testProject
                        bz.paramet = '布置'
                        bz.result = earthing.bz
                        bz.standard = '/'
                        bz.determine = '参考项'
                        this.changeData.push(bz)
                    }
                    if (earthing.cl && earthing.testProject == '接地装置' && earthing.cl != '请选择') {
                        const cl = {}
                        cl.point = earthing.testPosition
                        cl.project = earthing.testProject
                        cl.paramet = '材料'
                        cl.result = earthing.cl
                        cl.standard = '/'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (earthing.gg && earthing.testProject == '接地装置' && earthing.gg != '请选择') {
                        const gg = {}
                        gg.point = earthing.testPosition
                        gg.project = earthing.testProject
                        if (earthing.ggUnit) {
                            gg.paramet = `规格(${earthing.ggUnit})`
                        }
                        gg.result = earthing.gg
                        gg.standard = '/'
                        gg.determine = '参考项'
                        this.changeData.push(gg)
                    }
                    if (earthing.hj && earthing.testProject == '接地装置' && earthing.hj != '请选择') {
                        const hj = {}
                        hj.point = earthing.testPosition
                        hj.project = earthing.testProject
                        hj.paramet = '焊接'
                        hj.result = earthing.hj
                        hj.standard = '/'
                        hj.determine = '参考项'
                        this.changeData.push(hj)
                    }
                    if (earthing.ff && earthing.testProject == '接地装置' && earthing.ff != '请选择') {
                        const ff = {}
                        ff.point = earthing.testPosition
                        ff.project = earthing.testProject
                        ff.paramet = '防腐'
                        ff.result = earthing.ff
                        ff.standard = '/'
                        ff.determine = '参考项'
                        this.changeData.push(ff)
                    }
                    if (earthing.fkbdycs && earthing.testProject == '接地装置' && earthing.fkbdycs != '请选择') {
                        const fkbdycs = {}
                        fkbdycs.point = earthing.testPosition
                        fkbdycs.project = earthing.testProject
                        fkbdycs.paramet = '防跨步电压措施'
                        fkbdycs.result = earthing.fkbdycs
                        fkbdycs.standard = '/'
                        fkbdycs.determine = '参考项'
                        this.changeData.push(fkbdycs)
                    }
                    if (earthing.tt && earthing.testProject == '接地装置') {
                        const tt = {}
                        tt.point = earthing.testPosition
                        tt.project = earthing.testProject
                        tt.paramet = '填土'
                        tt.standard = '≥0.5'
                        var changeObj = this.changeType(earthing.tt, tt.standard)
                        tt.result = this.evenRound(changeObj.changeResult, 1)
                        if (tt.result >= changeObj.changeStandard) {
                            tt.determine = '符合要求'
                        } else {
                            tt.determine = '不符合要求'
                        }
                        this.changeData.push(tt)
                    }
                    if (earthing.jgjl && earthing.testProject == '接地装置') {
                        const jgjl = {}
                        jgjl.point = earthing.testPosition
                        jgjl.project = earthing.testProject
                        jgjl.paramet = '间隔距离(m)'
                        if (earthing.jgjlStandard == '参考项') {
                            jgjl.result = earthing.jgjl
                            jgjl.standard = '/'
                            jgjl.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(earthing.jgjl, earthing.jgjlStandard)
                            jgjl.result = this.evenRound(changeObj.changeResult, 1)
                            jgjl.standard = earthing.jgjlStandard
                            if (jgjl.result >= changeObj.changeStandard) {
                                jgjl.determine = '符合要求'
                            } else {
                                jgjl.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(jgjl)
                    }
                    if (earthing.gyjd && earthing.testProject == '接地装置' && earthing.gyjd != '请选择') {
                        const gyjd = {}
                        gyjd.point = earthing.testPosition
                        gyjd.project = earthing.testProject
                        gyjd.paramet = '共用接地'
                        gyjd.result = earthing.gyjd
                        gyjd.standard = '/'
                        gyjd.determine = '参考项'
                        this.changeData.push(gyjd)
                    }
                    if (earthing.dqgtxn && earthing.testProject == '接地装置') {
                        const dqgtxn = {}
                        dqgtxn.point = earthing.testPosition
                        dqgtxn.project = earthing.testProject
                        dqgtxn.paramet = '电气贯通性能'
                        dqgtxn.standard = '≤1'
                        var changeObj = this.changeType(earthing.dqgtxn, dqgtxn.standard)
                        dqgtxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (dqgtxn.result <= changeObj.changeStandard) {
                            dqgtxn.determine = '符合要求'
                        } else {
                            dqgtxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqgtxn)
                    }
                    if (earthing.jddz && earthing.testProject == '接地装置') {
                        const jddz = {}
                        jddz.point = earthing.testPosition
                        jddz.project = earthing.testProject
                        jddz.paramet = '接地电阻(Ω)'
                        jddz.standard = earthing.jddzStandard
                        var changeObj = this.changeType(earthing.jddz, jddz.standard)
                        jddz.result = this.evenRound(changeObj.changeResult, 1)
                        if (jddz.result <= changeObj.changeStandard) {
                            jddz.determine = '符合要求'
                        } else {
                            jddz.determine = '不符合要求'
                        }
                        this.changeData.push(jddz)
                    }
                    //等电位连接相关数据
                    if (equipotential.wz && equipotential.testProject == '防雷等电位连接') {
                        const wz = {}
                        wz.point = equipotential.testPosition
                        wz.project = equipotential.testProject
                        wz.paramet = '位置'
                        wz.result = equipotential.wz
                        wz.standard = '/'
                        wz.determine = '参考项'
                        this.changeData.push(wz)
                    }
                    if (equipotential.jg && equipotential.testProject == '防雷等电位连接') {
                        const jg = {}
                        jg.point = equipotential.testPosition
                        jg.project = equipotential.testProject
                        jg.paramet = '结构'
                        jg.result = equipotential.jg
                        jg.standard = '/'
                        jg.determine = '参考项'
                        this.changeData.push(jg)
                    }
                    if (equipotential.cl && equipotential.testProject == '防雷等电位连接') {
                        const cl = {}
                        cl.point = equipotential.testPosition
                        cl.project = equipotential.testProject
                        cl.paramet = '材料'
                        cl.result = equipotential.cl
                        cl.standard = '/'
                        cl.determine = '参考项'
                        this.changeData.push(cl)
                    }
                    if (equipotential.gg && equipotential.testProject == '防雷等电位连接') {
                        const gg = {}
                        gg.point = equipotential.testPosition
                        gg.project = equipotential.testProject
                        if (equipotential.ggUnit) {
                            gg.paramet = `规格(${equipotential.ggUnit})`
                        }
                        if (equipotential.ggStandard == '参考项') {
                            gg.result = equipotential.gg
                            gg.standard = '/'
                            gg.determine = '参考项'
                        } else {
                            var changeObj = this.changeType(equipotential.gg, equipotential.ggStandard)
                            gg.result = this.evenRound(changeObj.changeResult, 0)
                            if (gg.result >= changeObj.changeStandard) {
                                gg.standard = equipotential.ggStandard
                                gg.determine = '符合要求'
                            } else {
                                gg.standard = equipotential.ggStandard
                                gg.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(gg)
                    }
                    if (equipotential.ljgy && equipotential.testProject == '防雷等电位连接' && equipotential.ljgy != '请选择') {
                        const ljgy = {}
                        ljgy.point = equipotential.testPosition
                        ljgy.project = equipotential.testProject
                        ljgy.paramet = '连接工艺'
                        ljgy.result = equipotential.ljgy
                        ljgy.standard = '/'
                        ljgy.determine = '参考项'
                        this.changeData.push(ljgy)
                    }
                    if (equipotential.kjxn && equipotential.testProject == '防雷等电位连接') {
                        const kjxn = {}
                        kjxn.point = equipotential.testPosition
                        kjxn.project = equipotential.testProject
                        if (equipotential.kjxnUnit) {
                            kjxn.paramet = `跨接性能(${equipotential.kjxnUnit})`
                        }
                        kjxn.standard = equipotential.kjxnStandard
                        var changeObj = this.changeType(equipotential.kjxn, kjxn.standard)
                        kjxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (kjxn.result <= changeObj.changeStandard) {
                            kjxn.determine = '符合要求'
                        } else {
                            kjxn.determine = '不符合要求'
                        }
                        this.changeData.push(kjxn)
                    }
                    if (equipotential.ddwljxn && equipotential.testProject == '防雷等电位连接') {
                        const ddwljxn = {}
                        ddwljxn.point = equipotential.testPosition
                        ddwljxn.project = equipotential.testProject
                        if (equipotential.ddwljxnUnit) {
                            ddwljxn.paramet = `等电位连接性能(${equipotential.ddwljxnUnit})`
                        }
                        ddwljxn.standard = equipotential.ddwljxnStandard
                        var changeObj = this.changeType(equipotential.ddwljxn, ddwljxn.standard)
                        ddwljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwljxn.result <= changeObj.changeStandard) {
                            ddwljxn.determine = '符合要求'
                        } else {
                            ddwljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwljxn)
                    }
                    if (equipotential.jdjzdjdxn && equipotential.testProject == '防雷等电位连接') {
                        const jdjzdjdxn = {}
                        jdjzdjdxn.point = equipotential.testPosition
                        jdjzdjdxn.project = equipotential.testProject
                        jdjzdjdxn.paramet = '接地基准点(ERP)接地性能(Ω)'
                        jdjzdjdxn.standard = equipotential.jdjzdjdxnStandard
                        var changeObj = this.changeType(equipotential.jdjzdjdxn, jdjzdjdxn.standard)
                        jdjzdjdxn.result = this.evenRound(changeObj.changeResult, 1)
                        if (jdjzdjdxn.result <= changeObj.changeStandard) {
                            jdjzdjdxn.determine = '符合要求'
                        } else {
                            jdjzdjdxn.determine = '不符合要求'
                        }
                        this.changeData.push(jdjzdjdxn)
                    }
                    //磁屏蔽相关数据
                    if (strike.wz && strike.testProject == '磁屏蔽' && strike.wz != '请选择') {
                        const wz = {}
                        wz.point = strike.testPosition
                        wz.project = strike.testProject
                        wz.paramet = '位置'
                        wz.result = strike.wz
                        wz.standard = '/'
                        wz.determine = '参考项'
                        this.changeData.push(wz)
                    }
                    if (strike.ddwhdqljxn && strike.testProject == '磁屏蔽') {
                        const ddwhdqljxn = {}
                        ddwhdqljxn.point = strike.testPosition
                        ddwhdqljxn.project = strike.testProject
                        ddwhdqljxn.paramet = '等电位和电气连接性能(Ω)'
                        ddwhdqljxn.standard = '≤0.2'
                        var changeObj = this.changeType(strike.ddwhdqljxn, ddwhdqljxn.standard)
                        ddwhdqljxn.result = this.evenRound(changeObj.changeResult, 3)
                        if (ddwhdqljxn.result <= changeObj.changeStandard) {
                            ddwhdqljxn.determine = '符合要求'
                        } else {
                            ddwhdqljxn.determine = '不符合要求'
                        }
                        this.changeData.push(ddwhdqljxn)
                    }
                    if (strike.dqgtxn && strike.testProject == '磁屏蔽') {
                        const dqgtxn = {}
                        dqgtxn.point = strike.testPosition
                        dqgtxn.project = strike.testProject
                        dqgtxn.paramet = '电气贯通性能(Ω)'
                        dqgtxn.standard = '≤1'
                        var changeObj = this.changeType(strike.dqgtxn, dqgtxn.standard)
                        dqgtxn.result = this.evenRound(changeObj.changeResult, 1)
                        if (dqgtxn.result <= changeObj.changeStandard) {
                            dqgtxn.determine = '符合要求'
                        } else {
                            dqgtxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqgtxn)
                    }
                    if (strike.pbxg && strike.testProject == '磁屏蔽' && strike.pbxg != '请选择') {
                        const pbxg = {}
                        pbxg.point = strike.testPosition
                        pbxg.project = strike.testProject
                        pbxg.paramet = '屏蔽效果'
                        pbxg.result = strike.pbxg
                        pbxg.standard = '/'
                        pbxg.determine = '参考项'
                        this.changeData.push(pbxg)
                    }
                    //电源电涌保护器相关数据
                    if (powersurge.bz && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const bz = {}
                        bz.point = powersurge.testPosition
                        bz.project = powersurge.testProject
                        bz.paramet = '布置'
                        bz.result = powersurge.bz
                        bz.standard = '/'
                        bz.determine = '参考项'
                        this.changeData.push(bz)
                    }
                    if (powersurge.zdcxgzdyuc && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const zdcxgzdyuc = {}
                        zdcxgzdyuc.point = powersurge.testPosition
                        zdcxgzdyuc.project = powersurge.testProject
                        zdcxgzdyuc.paramet = `最大持续工作电压Uc( ${powersurge.zdcxgzdyucUnit})`
                        zdcxgzdyuc.result = powersurge.zdcxgzdyuc
                        zdcxgzdyuc.standard = '/'
                        zdcxgzdyuc.determine = '参考项'
                        this.changeData.push(zdcxgzdyuc)
                    }
                    if (powersurge.sylx && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.sylx != '请选择') {
                        const sylx = {}
                        sylx.point = powersurge.testPosition
                        sylx.project = powersurge.testProject
                        sylx.paramet = '试验类型'
                        sylx.result = powersurge.sylx
                        sylx.standard = '/'
                        sylx.determine = '参考项'
                        this.changeData.push(sylx)
                    }
                    if (powersurge.dybhspup && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.dybhspup != '请选择') {
                        const dybhspup = {}
                        dybhspup.point = powersurge.testPosition
                        dybhspup.project = powersurge.testProject
                        dybhspup.paramet = '电压保护水平Up(kV)'
                        dybhspup.result = powersurge.dybhspup
                        dybhspup.standard = '/'
                        dybhspup.determine = '参考项'
                        this.changeData.push(dybhspup)
                    }
                    if (powersurge.yxdybhspupf && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.yxdybhspupf != '请选择') {
                        const yxdybhspupf = {}
                        yxdybhspupf.point = powersurge.testPosition
                        yxdybhspupf.project = powersurge.testProject
                        yxdybhspupf.paramet = '有效电压保护水平Up/f(kV)'
                        yxdybhspupf.result = powersurge.yxdybhspupf
                        yxdybhspupf.standard = '/'
                        yxdybhspupf.determine = '参考项'
                        this.changeData.push(yxdybhspupf)
                    }
                    if (powersurge.bcfddlin && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.bcfddlin != '请选择') {
                        const bcfddlin = {}
                        bcfddlin.point = powersurge.testPosition
                        bcfddlin.project = powersurge.testProject
                        bcfddlin.paramet = '标称放电电流In(kA)'
                        bcfddlin.result = powersurge.bcfddlin
                        bcfddlin.standard = '/'
                        bcfddlin.determine = '参考项'
                        this.changeData.push(bcfddlin)
                    }
                    if (powersurge.zdfddlimax && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.zdfddlimax != '请选择') {
                        const zdfddlimax = {}
                        zdfddlimax.point = powersurge.testPosition
                        zdfddlimax.project = powersurge.testProject
                        zdfddlimax.paramet = '最大放电电流Imax(kA)'
                        zdfddlimax.result = powersurge.zdfddlimax
                        zdfddlimax.standard = '/'
                        zdfddlimax.determine = '参考项'
                        this.changeData.push(zdfddlimax)
                    }
                    if (powersurge.cjdliimp && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.cjdliimp != '请选择') {
                        const cjdliimp = {}
                        cjdliimp.point = powersurge.testPosition
                        cjdliimp.project = powersurge.testProject
                        cjdliimp.paramet = '冲击电流Iimp(kA)'
                        cjdliimp.result = powersurge.cjdliimp
                        cjdliimp.standard = '/'
                        cjdliimp.determine = '参考项'
                        this.changeData.push(cjdliimp)
                    }
                    if (powersurge.ljdtdcl && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdcl = {}
                        ljdtdcl.point = powersurge.testPosition
                        ljdtdcl.project = powersurge.testProject
                        ljdtdcl.paramet = '连接导体的材料'
                        ljdtdcl.result = powersurge.ljdtdcl
                        ljdtdcl.standard = '/'
                        ljdtdcl.determine = '参考项'
                        this.changeData.push(ljdtdcl)
                    }
                    if (powersurge.ljdtdgg && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdgg = {}
                        ljdtdgg.point = powersurge.testPosition
                        ljdtdgg.project = powersurge.testProject
                        ljdtdgg.paramet = '连接导体的规格'
                        ljdtdgg.result = powersurge.ljdtdgg
                        ljdtdgg.standard = '/'
                        ljdtdgg.determine = '参考项'
                        this.changeData.push(ljdtdgg)
                    }
                    if (powersurge.ljdtdjmmjxx && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdjmmjxx = {}
                        ljdtdjmmjxx.point = powersurge.testPosition
                        ljdtdjmmjxx.project = powersurge.testProject
                        ljdtdjmmjxx.paramet = '连接导体的截面面积(相线)（mm²）'
                        ljdtdjmmjxx.standard = powersurge.ljdtdjmmjxxStandard
                        var changeObj = this.changeType(powersurge.ljdtdjmmjxx, ljdtdjmmjxx.standard)
                        ljdtdjmmjxx.result = changeObj.changeResult
                        if (ljdtdjmmjxx.result >= changeObj.changeStandard) {
                            ljdtdjmmjxx.determine = '符合要求'
                        } else {
                            ljdtdjmmjxx.determine = '不符合要求'
                        }
                        this.changeData.push(ljdtdjmmjxx)
                    }
                    if (powersurge.ljdtdjmmjjdx && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ljdtdjmmjjdx = {}
                        ljdtdjmmjjdx.point = powersurge.testPosition
                        ljdtdjmmjjdx.project = powersurge.testProject
                        ljdtdjmmjjdx.paramet = '连接导体的截面面积(接地线)'
                        ljdtdjmmjjdx.standard = powersurge.ljdtdjmmjjdxStandard
                        var changeObj = this.changeType(powersurge.ljdtdjmmjjdx, ljdtdjmmjjdx.standard)
                        ljdtdjmmjjdx.result = changeObj.changeResult
                        if (ljdtdjmmjjdx.result >= changeObj.changeStandard) {
                            ljdtdjmmjjdx.determine = '符合要求'
                        } else {
                            ljdtdjmmjjdx.determine = '不符合要求'
                        }
                        this.changeData.push(ljdtdjmmjjdx)
                    }
                    if (powersurge.ljdtys && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ljdtys != '请选择') {
                        const ljdtys = {}
                        ljdtys.point = powersurge.testPosition
                        ljdtys.project = powersurge.testProject
                        ljdtys.paramet = '连接导体颜色'
                        ljdtys.result = powersurge.ljdtys
                        ljdtys.standard = '/'
                        ljdtys.determine = '参考项'
                        this.changeData.push(ljdtys)
                    }
                    if (powersurge.ldyxcdzh && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ldyxcdzh = {}
                        ldyxcdzh.point = powersurge.testPosition
                        ldyxcdzh.project = powersurge.testProject
                        ldyxcdzh.paramet = '两端引线长度之和(m)'
                        ldyxcdzh.standard = '≤0.5'
                        var changeObj = this.changeType(powersurge.ldyxcdzh, ldyxcdzh.standard)
                        ldyxcdzh.result = this.evenRound(changeObj.changeResult, 1)
                        if (ldyxcdzh.result <= changeObj.changeStandard) {
                            ldyxcdzh.determine = '符合要求'
                        } else {
                            ldyxcdzh.determine = '不符合要求'
                        }
                        this.changeData.push(ldyxcdzh)
                    }
                    if (powersurge.ljgy && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ljgy != '请选择') {
                        const ljgy = {}
                        ljgy.point = powersurge.testPosition
                        ljgy.project = powersurge.testProject
                        ljgy.paramet = '连接工艺'
                        ljgy.result = powersurge.ljgy
                        ljgy.standard = '/'
                        ljgy.determine = '参考项'
                        this.changeData.push(ljgy)
                    }
                    if (powersurge.wg && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.wg != '请选择') {
                        const wg = {}
                        wg.point = powersurge.testPosition
                        wg.project = powersurge.testProject
                        wg.paramet = '外观'
                        wg.result = powersurge.wg
                        wg.standard = '/'
                        wg.determine = '参考项'
                        this.changeData.push(wg)
                    }
                    if (powersurge.ztzsq && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.ztzsq != '请选择') {
                        const ztzsq = {}
                        ztzsq.point = powersurge.testPosition
                        ztzsq.project = powersurge.testProject
                        ztzsq.paramet = '状态指示器'
                        ztzsq.result = powersurge.ztzsq
                        ztzsq.standard = '/'
                        ztzsq.determine = '参考项'
                        this.changeData.push(ztzsq)
                    }
                    if (powersurge.fhjs && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.fhjs != '请选择') {
                        const fhjs = {}
                        fhjs.point = powersurge.testPosition
                        fhjs.project = powersurge.testProject
                        fhjs.paramet = '防护级数'
                        fhjs.result = powersurge.fhjs
                        fhjs.standard = '/'
                        fhjs.determine = '参考项'
                        this.changeData.push(fhjs)
                    }
                    if (powersurge.jjph && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jjph = {}
                        jjph.point = powersurge.testPosition
                        jjph.project = powersurge.testProject
                        jjph.paramet = '级间配合(m)'
                        jjph.standard = powersurge.jjph
                        var changeObj = this.changeType(powersurge.jjph, jjph.standard)
                        jjph.result = this.evenRound(changeObj.changeResult, 1)
                        if (jjph.result <= changeObj.changeStandard) {
                            jjph.determine = '符合要求'
                        } else {
                            jjph.determine = '不符合要求'
                        }
                        this.changeData.push(jjph)
                    }
                    if (powersurge.wbtlqgdlbh && powersurge.testProject == '电源电涌保护器(SPD)' && powersurge.wbtlqgdlbh != '请选择') {
                        const wbtlqgdlbh = {}
                        wbtlqgdlbh.point = powersurge.testPosition
                        wbtlqgdlbh.project = powersurge.testProject
                        wbtlqgdlbh.paramet = '外部脱离器(过电流保护)'
                        wbtlqgdlbh.result = powersurge.wbtlqgdlbh
                        wbtlqgdlbh.standard = '/'
                        wbtlqgdlbh.determine = '参考项'
                        this.changeData.push(wbtlqgdlbh)
                    }
                    if (powersurge.dqljxn && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const dqljxn = {}
                        dqljxn.point = powersurge.testPosition
                        dqljxn.project = powersurge.testProject
                        dqljxn.paramet = '电气连接性能(Ω) '
                        dqljxn.standard = '≤0.2'
                        var changeObj = this.changeType(powersurge.dqljxn, dqljxn.standard)
                        dqljxn.result = this.evenRound(changeObj.changeResult, 1)
                        if (dqljxn.result <= changeObj.changeStandard) {
                            dqljxn.determine = '符合要求'
                        } else {
                            dqljxn.determine = '不符合要求'
                        }
                        this.changeData.push(dqljxn)
                    }

                    if (powersurge.ymdyL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL1 = {}
                        ymdyL1.point = powersurge.testPosition
                        ymdyL1.project = powersurge.testProject
                        ymdyL1.paramet = '压敏电压-L1(Vᵥ)'
                        ymdyL1.standard = powersurge.ymdyStandard
                        var changeObj = this.changeType(powersurge.ymdyL1, ymdyL1.standard)
                        ymdyL1.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL1.standard == '参考项') {
                            ymdyL1.standard = '/'
                            ymdyL1.determine = '参考项'
                        } else {
                            if (powersurge.ymdyStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL1.result <= maxu1m && ymdyL1.result >= minu1m) {
                                    ymdyL1.determine = '符合要求'
                                } else {
                                    ymdyL1.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL1.result >= changeObj.changeStandard) {
                                    ymdyL1.determine = '符合要求'
                                } else {
                                    ymdyL1.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL1)
                    }
                    if (powersurge.ymdyL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL2 = {}
                        ymdyL2.point = powersurge.testPosition
                        ymdyL2.project = powersurge.testProject
                        ymdyL2.paramet = '压敏电压-L2(Vᵥ)'
                        ymdyL2.standard = powersurge.ymdyStandard
                        var changeObj = this.changeType(powersurge.ymdyL2, ymdyL2.standard)
                        ymdyL2.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL2.standard == '参考项') {
                            ymdyL2.standard = '/'
                            ymdyL2.determine = '参考项'
                        } else {
                            if (powersurge.ymdyStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL2.result <= maxu1m && ymdyL2.result >= minu1m) {
                                    ymdyL2.determine = '符合要求'
                                } else {
                                    ymdyL2.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL2.result >= changeObj.changeStandard) {
                                    ymdyL2.determine = '符合要求'
                                } else {
                                    ymdyL2.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL2)
                    }
                    if (powersurge.ymdyL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyL3 = {}
                        ymdyL3.point = powersurge.testPosition
                        ymdyL3.project = powersurge.testProject
                        ymdyL3.paramet = '压敏电压-L3(Vᵥ)'
                        ymdyL3.standard = powersurge.ymdyStandard
                        var changeObj = this.changeType(powersurge.ymdyL3, ymdyL3.standard)
                        ymdyL3.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyL3.standard == '参考项') {
                            ymdyL3.standard = '/'
                            ymdyL3.determine = '参考项'
                        } else {
                            if (powersurge.ymdyStandard.includes('%')) {
                                let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                if (ymdyL3.result <= maxu1m && ymdyL3.result >= minu1m) {
                                    ymdyL3.determine = '符合要求'
                                } else {
                                    ymdyL3.determine = '不符合要求'
                                }
                            } else {
                                if (ymdyL3.result >= changeObj.changeStandard) {
                                    ymdyL3.determine = '符合要求'
                                } else {
                                    ymdyL3.determine = '不符合要求'
                                }
                            }
                        }
                        this.changeData.push(ymdyL3)
                    }
                    if (powersurge.ymdyN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const ymdyN = {}
                        ymdyN.point = powersurge.testPosition
                        ymdyN.project = powersurge.testProject
                        ymdyN.paramet = '压敏电压-N(Vᵥ)'
                        if (powersurge.ymdyStandard) {
                            ymdyN.standard = powersurge.ymdyStandard
                        } else {
                            ymdyN.standard = powersurge.ymdyStandard
                        }
                        var changeObj = this.changeType(powersurge.ymdyN, ymdyN.standard)
                        ymdyN.result = this.evenRound(changeObj.changeResult, 0)
                        if (ymdyN.standard == '参考项') {
                            ymdyN.standard = '/'
                            ymdyN.determine = '参考项'
                        } else {
                            if (powersurge.ymdyStandard) {  //判断之前没有u1maNStandard参数数据出错问题
                                if (powersurge.ymdyStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (ymdyN.result <= maxu1m && ymdyN.result >= minu1m) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                } else {
                                    if (ymdyN.result >= changeObj.changeStandard) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                }
                            } else {
                                if (powersurge.ymdyStandard.includes('%')) {
                                    let maxu1m = changeObj.changeStandard + changeObj.changeStandard * 1 / 10
                                    let minu1m = changeObj.changeStandard - changeObj.changeStandard * 1 / 10
                                    if (ymdyN.result <= maxu1m && ymdyN.result >= minu1m) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                } else {
                                    if (ymdyN.result >= changeObj.changeStandard) {
                                        ymdyN.determine = '符合要求'
                                    } else {
                                        ymdyN.determine = '不符合要求'
                                    }
                                }
                            }

                        }
                        this.changeData.push(ymdyN)
                    }
                    if (powersurge.xldlL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL1 = {}
                        xldlL1.point = powersurge.testPosition
                        xldlL1.project = powersurge.testProject
                        xldlL1.paramet = '泄漏电流-L1(Iₗₑ)'
                        xldlL1.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL1, xldlL1.standard)
                        xldlL1.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL1.standard == '参考项') {
                            xldlL1.standard = '/'
                            xldlL1.determine = '参考项'
                        } else {
                            if (xldlL1.result <= changeObj.changeStandard) {
                                xldlL1.determine = '符合要求'
                            } else {
                                xldlL1.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL1)
                    }
                    if (powersurge.xldlL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL2 = {}
                        xldlL2.point = powersurge.testPosition
                        xldlL2.project = powersurge.testProject
                        xldlL2.paramet = '泄漏电流-L2(Iₗₑ)'
                        xldlL2.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL2, xldlL2.standard)
                        xldlL2.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL2.standard == '参考项') {
                            xldlL2.standard = '/'
                            xldlL2.determine = '参考项'
                        } else {
                            if (xldlL2.result <= changeObj.changeStandard) {
                                xldlL2.determine = '符合要求'
                            } else {
                                xldlL2.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL2)
                    }
                    if (powersurge.xldlL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlL3 = {}
                        xldlL3.point = powersurge.testPosition
                        xldlL3.project = powersurge.testProject
                        xldlL3.paramet = '泄漏电流-L3(Iₗₑ)'
                        xldlL3.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlL3, xldlL3.standard)
                        xldlL3.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlL3.standard == '参考项') {
                            xldlL3.standard = '/'
                            xldlL3.determine = '参考项'
                        } else {
                            if (xldlL3.result <= changeObj.changeStandard) {
                                xldlL3.determine = '符合要求'
                            } else {
                                xldlL3.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlL3)
                    }
                    if (powersurge.xldlN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const xldlN = {}
                        xldlN.point = powersurge.testPosition
                        xldlN.project = powersurge.testProject
                        xldlN.paramet = '泄漏电流-N(Iₗₑ)'
                        xldlN.standard = powersurge.xldlStandard
                        var changeObj = this.changeType(powersurge.xldlN, xldlN.standard)
                        xldlN.result = this.evenRound(changeObj.changeResult, 1)
                        if (xldlN.standard == '参考项') {
                            xldlN.standard = '/'
                            xldlN.determine = '参考项'
                        } else {
                            if (xldlN.result <= changeObj.changeStandard) {
                                xldlN.determine = '符合要求'
                            } else {
                                xldlN.determine = '不符合要求'
                            }
                        }
                        this.changeData.push(xldlN)
                    }
                    if (powersurge.jydzL1 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL1 = {}
                        jydzL1.point = powersurge.testPosition
                        jydzL1.project = powersurge.testProject
                        jydzL1.paramet = '绝缘电阻-L1(Rᵢ)'
                        jydzL1.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL1, jydzL1.standard)
                        jydzL1.result = powersurge.jydzL1
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL1.determine = '符合要求'
                        } else {
                            jydzL1.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL1)
                    }
                    if (powersurge.jydzL2 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL2 = {}
                        jydzL2.point = powersurge.testPosition
                        jydzL2.project = powersurge.testProject
                        jydzL2.paramet = '绝缘电阻-L2(Rᵢ)'
                        jydzL2.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL2, jydzL2.standard)
                        jydzL2.result = powersurge.jydzL2
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL2.determine = '符合要求'
                        } else {
                            jydzL2.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL2)
                    }
                    if (powersurge.jydzL3 && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzL3 = {}
                        jydzL3.point = powersurge.testPosition
                        jydzL3.project = powersurge.testProject
                        jydzL3.paramet = '绝缘电阻-L3(Rᵢ)'
                        jydzL3.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzL3, jydzL3.standard)
                        jydzL3.result = powersurge.jydzL3
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzL3.determine = '符合要求'
                        } else {
                            jydzL3.determine = '不符合要求'
                        }
                        this.changeData.push(jydzL3)
                    }
                    if (powersurge.jydzN && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const jydzN = {}
                        jydzN.point = powersurge.testPosition
                        jydzN.project = powersurge.testProject
                        jydzN.paramet = '绝缘电阻 (N)(MΩ)'
                        jydzN.standard = '≥50'
                        var changeObj = this.changeType(powersurge.jydzN, jydzN.standard)
                        jydzN.result = powersurge.jydzN
                        if (changeObj.changeResult >= changeObj.changeStandard) {
                            jydzN.determine = '符合要求'
                        } else {
                            jydzN.determine = '不符合要求'
                        }
                        this.changeData.push(jydzN)
                    }
                    if (powersurge.photo && powersurge.testProject == '电源电涌保护器(SPD)') {
                        const photo = {}
                        let imgsrcs = powersurge.photo.split('|')
                        for (let i in imgsrcs) {
                            if (imgsrcs[i] != null && imgsrcs[i] != '') {
                                this.$axios({
                                    method: 'get',
                                    url: '/lps/hwcloud/obs/downurl',
                                    params: {
                                        objname: imgsrcs[i]
                                    }
                                }).then(res => {
                                    if (res.data != null) {
                                        // this.$set(photo, 'point' , powersurge.testPosition)
                                        // this.$set(photo, 'project', powersurge.testProject)
                                        photo.point = powersurge.testPosition
                                        photo.project = powersurge.testProject
                                        this.$set(photo, 'imgurl', res.data.url)
                                        this.$set(photo, 'paramet', 'SPD现场图片')
                                        this.$set(photo, 'type', 'photo')
                                        this.urlList.push(res.data.url)
                                    }
                                })
                            }
                        }
                        this.changeData.push(photo)
                    }
                    //防雷分区
                    if (lightning.flfq && lightning.testProject == '防雷分区') {
                        const flfq = {}
                        flfq.point = powersurge.testPosition
                        flfq.project = powersurge.testProject
                        flfq.paramet = '防雷分区'
                        flfq.result = lightning.flfq
                        flfq.standard = '/'
                        flfq.determine = '参考项'
                        this.changeData.push(flfq)
                    }
                }
            }

            this.getSpanArr(this.changeData)
            // console.log('转换后数组：', this.changeData);
        },
        //批注信息回传展示
        changeexaminecontents(examinecontents) {
            let endorses = this.endorseMessage.match(/\d+(\.\d+)?/g)
            for (let item in examinecontents) {
                var items = item.match(/\d+(\.\d+)?/g)
                if (item.includes('areaCode')) {
                    if (endorses[0] == items[0] && endorses[1] == items[1]) {
                        this.backesdorse = examinecontents[item]
                    }
                }
                if (item.includes('sourceDataList')) {
                    for (let i in this.changeData) {
                        if (this.reportTemplate === '001') {
                            if (endorses[0] == items[0] && endorses[1] == items[1] && items[2] == i) {
                                this.$set(this.changeData[i], 'mark', examinecontents[item])
                                // this.changeData[i].mark = this.examinecontents[item] input框不能修改
                            }
                        } else {
                            if (endorses[0] == items[0] && items[1] == i) {
                                this.$set(this.changeData[i], 'mark', examinecontents[item])
                                // this.changeData[i].mark = this.examinecontents[item] input框不能修改
                            }
                        }

                    }
                }
            }
        }
    }
}
</script>
<style scoped>
#ftable {
    width: 99.8%;
    min-height: 48px;
    border: 1px solid gainsboro;
    position: relative;
}

#fqtotal {
    width: 80.1%;
    display: inline-block;
}

#fq {
    width: 18.3%;
    /* min-height: 48px; */
    vertical-align: top;
    display: inline-block;
}

#fq p {
    text-align: center;
    font-family: 黑体;
    font-weight: 700;
    font-size: 17px;
    padding-top: 15px;
}

#fqcenter p {
    font-family: 黑体;
    font-weight: 700;
    font-size: 17px;
    padding-top: 15px;
}

#fqcenter {
    border-left: 1px solid gainsboro;
    width: 79%;
    min-height: 48px;
    display: inline-block;
}

#tableesdorse {
    border-left: 1px solid gainsboro;
    /* border-right: 1px solid gainsboro; */
    width: 19.6%;
    height: 100%;
    position: absolute;
    display: inline-block;
    margin-top: -1px;
    padding-left: 1px;
    /* box-sizing: border-box; */
    /* margin-left: -10px; */
}

.el-table>>>.my-cell {
    vertical-align: top;
}
</style>