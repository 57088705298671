<!--
 * @Author: 白一桐
 * @Date: 2023-03-29 08:52:31
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-07-18 10:26:57
-->
<template>
  <div style="width:100%">
    <Menu></Menu>
    <div id="head">
      报告管理
      <el-button type="primary" plain size="small" id="btn" @click="search">筛选</el-button>
    </div>
    <div>
      <div style="float:left ;margin-left: 20px;width: 65%;">
        <template>
          <el-table :data="tableData" style="cursor: pointer;" stripe :span-method="objectSpanMethod">
            <el-table-column prop="entryname" label="项目名称" min-width="100" align="center"> </el-table-column>
            <el-table-column prop="mainpsn" label="主要检测人员" align="center"> </el-table-column>
            <el-table-column label="任务下发日期" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.oderdate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目进度" align="center">
              <el-table-column label="场所名称" align="center" min-width="140">
                <template slot-scope="scope">
                  <span>{{ scope.row.sitename }}</span>
                </template>
              </el-table-column>
              <el-table-column label="报告编号" align="center" min-width="140">
                <template slot-scope="scope">
                  <span @click="handleEdit(scope.row)">{{ scope.row.trptno }}</span>
                </template>
              </el-table-column>

              <el-table-column label="检测" align="center" width="95">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == '00'" style="color:brown">{{
                    scope.row.status_meta
                  }}</span>
                  <span v-else-if="scope.row.status == '01'" style="color:darkgoldenrod">{{
                    scope.row.status_meta
                  }}</span>
                  <span v-else-if="scope.row.status == '02'" style="color: olive">{{
                    scope.row.status_meta
                  }}</span>
                  <span v-else-if="scope.row.status == '03'" style="color: green">{{
                    scope.row.status_meta
                  }}</span>

                </template>
              </el-table-column>
              <el-table-column label="审核" align="center" min-width="105">
                <template slot-scope="scope">
                  <span v-if="scope.row.examinestat == '10'" style="color:orange">
                    {{ scope.row.examinestat_meta }}
                    <i class="el-icon-edit" style="margin-left:10px" @click="toExamine(scope.row)" v-if="isi"></i></span>
                  <span v-else-if="scope.row.examinestat == '11'" style="color: darkred">
                    {{ scope.row.examinestat_meta }}
                    <i class="el-icon-edit" style="margin-left:10px" @click="toExamine(scope.row)" v-if="isi"></i></span>
                  <span v-else-if="scope.row.examinestat == '12'" style="color: #4c956c">
                    {{ scope.row.examinestat_meta }}</span>
                  <span v-else-if="scope.row.examinestat == '13'" style="color:palevioletred">
                    {{ scope.row.examinestat_meta }}
                    <i class="el-icon-edit" style="margin-left:10px" @click="toExamine(scope.row)" v-if="isi"></i></span>
                  <span v-else-if="scope.row.examinestat == '99'" style="color:red">
                    {{ scope.row.examinestat_meta }}</span>
                  <span v-else style="color:orchid">
                    预览
                    <i class="el-icon-edit" style="margin-left:10px" @click="toPreview(scope.row)"></i></span>
                </template>
              </el-table-column>
              <el-table-column label="批准" align="center" min-width="105">
                <template slot-scope="scope">
                  <span v-if="scope.row.confirmstat == '20'" style="color:orange">{{
                    scope.row.confirmstat_meta
                  }}</span>
                  <span v-else-if="scope.row.confirmstat == '21'" style="color:darkred">{{
                    scope.row.confirmstat_meta
                  }}</span>
                  <span v-else-if="scope.row.confirmstat == '22'" style="color: #4c956c">{{
                    scope.row.confirmstat_meta
                  }}</span>
                  <span v-else-if="scope.row.confirmstat == '23'" style="color:palevioletred">{{
                    scope.row.confirmstat_meta
                  }}</span>
                  <span v-else-if="scope.row.confirmstat == '99'" style="color:red">{{
                    scope.row.confirmstat_meta
                  }}</span>
                  <i class="el-icon-edit" style="margin-left:10px" v-if="scope.row.confirmstat_meta != '' && isi" @click="toConfirm(scope.row)"></i>
                  <i class="el-icon-notebook-2" style="margin-left:10px" v-if="scope.row.confirmstat == '22' && workid == '2'" @click="toReportrawData(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row)">流程轨迹</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <!--分页器 -->

        <div class="block" style="margin-top: 15px;margin-right: 30px;">
          <!-- <el-pagination align="right" @size-change='handleSizeChange' @current-change='handleCurrentChange'
            :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next,total" background :total="total"
            hide-on-single-page>
          </el-pagination> -->
          <el-pagination align="right" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <!-- 弹出层表单 -->
        <el-dialog :visible.sync="dialogFormVisible" style="width:1000px; margin-top:5%;margin-left: 25%;">
          <el-form ref="form" label-width="100px">
            <el-form-item label="报告编号：">
              <el-input placeholder="请输入报告编号" style="width:280px" v-model="from.code"></el-input>
            </el-form-item>

            <el-form-item label="项目名称：">
              <el-input placeholder="请输入项目名称" style="width:280px" v-model="from.entryname"></el-input>
            </el-form-item>
            <el-form-item label="审核状态：">
              <el-select v-model="from.examinestat" placeholder="请选择审核状态" style="width:280px">
                <el-option label="请选择" value=""></el-option>
                <el-option label="待审核" value="10"></el-option>
                <el-option label="审核中" value="11"></el-option>
                <el-option label="审核通过" value="12"></el-option>
                <el-option label="审核不通过" value="13"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="批准状态：">
              <el-select v-model="from.confirmstat" placeholder="请选择批准状态" style="width:280px">
                <el-option label="请选择" value=""></el-option>
                <el-option label="待批准" value="20"></el-option>
                <el-option label="批准中" value="21"></el-option>
                <el-option label="批准通过" value="22"></el-option>
                <el-option label="批准不通过" value="23"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit" style="margin-left:260px">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>

      <!--     对话框        -->
      <div id="dialogBox" v-if="dialogBox">
        <div style="width:400px;height: 40px;background-color:white;z-index: 999;position: absolute;">
          <div style="float: right;">
            <i class="el-icon-circle-close" style="font-size: 25px;margin-right: 10px;margin-top: 10px;" @click="closeDialogBox()"></i>
          </div>
          <div style="height: 30px;">
            <div style="margin:10px;text-align: center;">
              <h3>流程轨迹</h3>
            </div>
          </div>
        </div>
        <div style="height:750px;margin-top: 40px;">
          <div class="procedure" v-for="(item, index) in procedureData" :key="index">
            <div style="float:left;">
              <img src="../../assets/user1.png" alt="" style="width:40px;height: 40px;padding-right:9px ;">
            </div>
            <div style="float:left; cursor: pointer;">
              <div style="height: 20px;font-size: 14px; display: inline-block;color: darkgray;">
                {{ item.assignee }} {{ item.endtime }}
              </div>
              <div class="dialog" v-if="item.endtime">
                <div @click="opendocx(item)">
                  <i class="el-icon-document" style="font-size: 40px;"></i>
                  {{ item.taskname }}
                </div>
              </div>
              <div v-else class="dialog1">
                <div>
                  <i class=" el-icon-document" style="font-size: 40px;"></i>
                  {{ item.taskname }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
export default {
  data() {
    return {
      dialogFormVisible: false, //表单隐藏
      dialogBox: false, //隐藏对话框
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页码
      pageSize: 10, //每页显示条数
      firstLevelIndexArr: [], // 一个空的数组，用于存放第一列每一行记录的合并数  控制第一列的合并
      firstLevelIndexPos: 0, // firstLevelIndexArr 的索引
      from: {
        //搜索表格
        code: '',
        entryname: '',
        examinestat: '',
        confirmstat: ''
      },
      procedureData: [], //流程图的数据
      isi: true,
      workid: '',
      typenum:'',
    }
  },
  components: {
    Menu
  },
  mounted() {
    this.powerManage()
    this.requestData()
    //刷新
    const bc = new BroadcastChannel('reportReload')
    bc.onmessage = event => {
      console.log(event)
      location.reload()
    }
  },
  methods: {
    powerManage() {
      let work = this.$cookies.get('user').works
      if (work.length != 0) {
        var max = work[0].id
        for (let i = 0; i < work.length; i++) {
          if (max < work[i].id) {
            max = work[i].id
          }
        }
        if (max == 1 || max == 2) {
          this.isi = false
        }
        if (max == 3 || max == 4 || max == 5) {
          this.isi = true
        }
        this.workid = max
      }
    },
    //请求数据
    requestData() {
      // console.log("-------", this.workid)
      // this.powerManage()
      if (this.workid == 2) {
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/toorder',
          params: {
            examinestat: this.from.examinestat,
            confirmstat: this.from.confirmstat,
            entryname: this.from.entryname,
            workflag: 1,
            trptno: this.from.code,
            current: this.currentPage,
            size: this.pageSize
          }
        })
          .then(res => {
            this.tableData = res.data.rows
            this.getSpanArr(this.tableData) //调用合并单元格函数
            this.total = res.data.total
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/toorder',
          params: {
            examinestat: this.from.examinestat,
            confirmstat: this.from.confirmstat,
            entryname: this.from.entryname,
            trptno: this.from.code,
            current: this.currentPage,
            size: this.pageSize
          }
        })
          .then(res => { 
            this.tableData = res.data.rows
            this.getSpanArr(this.tableData) //调用合并单元格函数
            this.total = res.data.total
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    //对话框页面
    handleEdit(data) {
      // console.log(id); //派工单id
      console.log(data)
      this.typenum = data.type
      this.dialogBox = true
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/process?trptid=' + data.id
      })
        .then(res => {
          // console.log(res.data)
          this.procedureData = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeDialogBox() {
      //关闭对话框页面
      this.dialogBox = false
      this.procedureData = []
    },
    toExamine(data) {
      //跳转审核页面
      if(data.type === '004'){
        let routeData = this.$router.resolve({
          path: '/reportPageNew',
          query: {
            id: data.id,
            type: data.type
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }else{
        let routeData = this.$router.resolve({
          path: '/reportPage',
          query: {
            id: data.id,
            type: data.type
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }
    },
    toConfirm(data) {
      console.log(data)
      if(data.type == '004'){
        let routeData = this.$router.resolve({
          path: '/reportConfirmNew', 
          query: {
            id: data.id,
            type: data.type
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }else{
        let routeData = this.$router.resolve({
          path: '/reportConfirm', 
          query: {
            id: data.id,
            type: data.type
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }
    },
    toReportrawData(data) {
      let routeData = this.$router.resolve({
        path: '/reportrawData',
        query: {
          id: data.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    toPreview(data) {
      //查看最新的暂存报告
      // console.log(data)
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/content/temp?testreportid=' + data.id
      })
        .then(res => { 
          let predata = res.data.rows
          if (predata != '') {
            // console.log("you")
            let lastData = predata[predata.length - 1] 
            if(data.type == "004"){
              let routeData = this.$router.resolve({
                path: '/previewNew',
                query: {
                  id: lastData.id,
                  type: data.type 
                }
              }) 
              window.open(routeData.href, '_blank')
            }else{
              let routeData = this.$router.resolve({
                path: '/preview',
                query: {
                  id: lastData.id,
                  type: data.type
                }
              })
              //必要操作，否则不会打开新页面
              window.open(routeData.href, '_blank')
            }
            
          } else {
            // console.log("wu")
            this.$message('请先在手机端暂存再查看')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    opendocx(data) {
      //打开文档子页面
      // console.log("docx", data)
      if (data.endtime && data.taskname == '修改检测报告') {
        let routeData = this.$router.resolve({
          path: '/editRecord',
          query: {
            taskid: data.taskid
          }
        })
        window.open(routeData.href, '_blank')
      } else if (data.endtime && data.taskname == '审核检测报告') {
        let routeData = this.$router.resolve({
          path: '/auditList',
          query: {
            taskid: data.taskid
          }
        })
        window.open(routeData.href, '_blank')
      } else if (data.endtime && data.taskname == '批准检测报告') {
        let routeData = this.$router.resolve({
          path: '/ratifyList',
          query: {
            taskid: data.taskid
          }
        })
        window.open(routeData.href, '_blank')
      } else if (data.endtime && data.taskname == '提交检测报告') {
        // console.log( this.typenum)
        if(this.typenum == '004'){
          let routeData = this.$router.resolve({
            path: '/previewNew',
            query: {
              id: data.businesskey,
              taskid: data.taskid,
              type: data.type 
            }
          }) 
          window.open(routeData.href, '_blank')
        }else{
          let routeData = this.$router.resolve({
            path: '/preview',
            query: {
              id: data.businesskey,
              taskid: data.taskid
            }
          })
          window.open(routeData.href, '_blank')
        }
      }
    },

    search() {
      //筛选按钮弹出悬浮层
      this.dialogFormVisible = true
    },
    onSubmit() {
      //提交筛选
      // console.log(this.from)
      if (this.workid == 2) {
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/toorder',
          params: {
            entryname: this.from.entryname,
            trptno: this.from.code,
            workflag: 1,
            examinestat: this.from.examinestat,
            confirmstat: this.from.confirmstat,
            current: 1,
            size: this.pageSize
          }
        })
          .then(res => {
            this.tableData = res.data.rows
            // console.log(this.tableData)
            this.getSpanArr(this.tableData) //调用合并单元格函数
            this.total = res.data.total
            // this.currentPage = res.data.pages
            this.dialogFormVisible = false
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/toorder',
          params: {
            entryname: this.from.entryname,
            trptno: this.from.code,
            examinestat: this.from.examinestat,
            confirmstat: this.from.confirmstat,
            current: 1,
            size: this.pageSize
          }
        })
          .then(res => {
            this.tableData = res.data.rows
            // console.log(this.tableData)
            this.getSpanArr(this.tableData) //调用合并单元格函数
            this.total = res.data.total
            // this.currentPage = res.data.pages
            this.dialogFormVisible = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    handleCurrentChange(val) {
      //分页器
      this.currentPage = val
      this.requestData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.requestData()
    },
    // ===============合并单元格开始===============
    getSpanArr(data) {
      // firstLevelIndexArr/secondLevelIndexArr来存放要合并的格数，同时还要设认一个变量firstLevelIndexPos/secondLevelIndexPos来记录
      this.firstLevelIndexArr = []
      this.secondLevelIndexArr = []
      this.indexDescArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.firstLevelIndexArr.push(1)
          this.firstLevelIndexPos = 0
        } else {
          // 判断当前元素与上一个元素是否相同(第1和第2列)
          if (data[i].dspid === data[i - 1].dspid) {
            this.firstLevelIndexArr[this.firstLevelIndexPos] += 1
            this.firstLevelIndexArr.push(0)
          } else {
            this.firstLevelIndexArr.push(1)
            this.firstLevelIndexPos = i
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2) {
        const _row = this.firstLevelIndexArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
    // ====================合并单元格end/===============
  }
}
</script>

<style scoped>
.dialog {
  position: relative;
  padding-left: 15px;
  box-sizing: border-box;
  padding-top: 10px;
  width: 260px;
  height: 70px;
  line-height: 2;
  /* background: rgb(148, 172, 150); */
  background: #4c956c;
  color: #fff;
  border-radius: 9px;
}

.dialog::before {
  content: '';
  position: absolute;
  border: 8px solid;
  border-color: transparent #4c956c transparent transparent;
  left: -16px;
  top: 8px;
}

.dialog1 {
  position: relative;
  padding-left: 15px;
  box-sizing: border-box;
  padding-top: 10px;
  width: 260px;
  height: 70px;
  line-height: 2;
  background: #7b7f86;
  color: #fff;
  border-radius: 10px;
}

.dialog1::before {
  content: '';
  position: absolute;
  border: 8px solid;
  border-color: transparent #7b7f86 transparent transparent;
  left: -16px;
  top: 8px;
}

#head {
  margin-left: 40px;
  padding: 10px;
}

#dialogBox {
  width: 400px;
  height: 800px;
  border: 1px solid rgb(205, 203, 203);
  float: right;
  margin-right: 30px;
  overflow-y: scroll;
}

.procedure {
  width: 320px;
  height: 100px;
  /* border: 1px solid gainsboro; */
  margin: 10px;
  padding: 10px;
}
</style>
