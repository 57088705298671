<!--
 * @Author: 白一桐
 * @Date: 2024-07-04 10:23:38
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-08-08 17:31:17
-->
<template>
  <div id="borderbox" style="page-break-after: always;">
    <div style="width: 210mm;height: 297mm; border:1px solid rgb(76, 76, 76);">
      <div id="homebox">
        <div id="homebox">
          <div class="homeleft">受检单位名称</div>
          <div class="homeright">
            <p>{{ reportingData.insunit }} </p>
          </div>
        </div> 
          <div class="homeleft">委托单位名称</div>
          <div class="homeright">
            <p>{{ reportingData.client }} </p>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft">联系人</div>
          <div class="homeright"  style="width: 260px;border-right:1px solid rgb(76, 76, 76) ;">
            <div>
              {{ reportingData.contact }}
            </div> 
          </div>
          <div class="homeleft" >联系方式</div>
          <div class="homeright">
            <p>{{ reportingData.contactPhone }}</p>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft">检测类别</div>
          <div class="homeright"  style="width: 260px;border-right:1px solid rgb(76, 76, 76) ;">
            <div>
              {{ reportingData.testType }}
            </div> 
          </div>
          <div class="homeleft" >下次检测日期</div>
          <div class="homeright">
            <p>{{ reportingData.nextWork }}</p>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft" style="line-height: 100px;" >依据标准</div>
          <div class="homeright1"> 
            <div v-html="reportingData.standardbr"></div>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft"  style="line-height: 100px;">检测仪器设备</div>
          <div class="homeright1" style="min-height: 280px;">
            <!-- <p>{{ reportingData.equipment }} </p> -->
            <div id="tables"> 
              <el-table size="mini" :cell-style="{ textAlign: 'center' }"
                        :header-cell-style="{ background: '#f2f2f2', color: '#666666', textAlign: 'center' }"
                        :border="true"
                        :data="reportingData.equipmentArr" style="width: 600px;">
                <el-table-column label="仪器名称" prop="eqName"></el-table-column>
                <el-table-column label="仪器型号" prop="eqModel"></el-table-column>
                <el-table-column label="仪器编号" prop="eqNo"></el-table-column>
              </el-table>
              <!-- table end -->
            </div>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft">环境状况</div>
          <div class="homeright"> 
            <p> 
              <span v-if="reportingData.temperature">温度：{{ reportingData.temperature }}; </span>
              <span v-if="reportingData.humidity">湿度:{{ reportingData.humidity }};</span>
              <span v-if="reportingData.weather">天气状况:{{ reportingData.weather }};</span>
              <span v-if="reportingData.soil">土壤状况:{{ reportingData.soil }};</span>
            </p>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft">备注</div>
          <div class="homeright">
            <p v-if="reportingData.otherInfo">{{ reportingData.otherInfo }} </p>
            <p v-else>/</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft" style="line-height: 150px;">检测人（签字）</div>
          <div class="homeright2" style="height: 150px;width: 200px;border-right:1px solid rgb(76, 76, 76) ;">
            <!-- <p>{{ reportingData. }} </p> -->
          </div>
          <div class="homeleft" style="line-height: 150px;">现场检测日期</div>
          <div class="homeright2" style="height: 160px;line-height: 35px;text-align: center;padding-top: 40px;box-sizing: border-box;padding-left:90px;"> 
            <div v-if="reportingData.customDate">
              {{ reportingData.customDate }}
            </div>
            <div v-else>
              <div v-if="reportingData.workStart != this.reportingData.workEnd">
                <p>{{ reportingData.workStart }}</p>
                <p>~</p>
                <p>{{this.reportingData.workEnd }}</p>
              </div>
            </div>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft" style="line-height:85px;">校核人（签字）</div>
          <div class="homeright2" style="width: 200px;border-right:1px solid rgb(76, 76, 76) ;"> 
          </div>
          <div class="homeleft" style="line-height: 85px;">校核日期</div>
          <div class="homeright2">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日</p>
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft" style="line-height: 85px;">批准人（签字）</div>
          <div class="homeright2" style="width: 200px;border-right:1px solid rgb(76, 76, 76) ;"> 
          </div>
          <div class="homeleft" style="line-height: 85px;">批准、发布日期</div>
          <div class="homeright2">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日</p>
          </div>
        </div> 
        <div id="homebox" style="height: 50px;display:inline-flex;line-height: 50px;border: none;padding-left: 20px;">
          <div >
            检测机构地址：
          </div>
          <div style="width: 420px;">
            <p>河北省石家庄市鹿泉区鹿岛V谷科技工业园15号楼三层</p>
          </div>
          <div >
            联系电话：
          </div>
          <div >
            <p>4008-0311-93</p>
          </div>
        </div>

    </div>
    <div id="marks">
      <el-input @blur="pushMsg('受检单位名称')" v-model="markData.insunit" class="inputs" placeholder="请输入批注[受检单位名称]" ></el-input>
      <el-input @blur="pushMsg('委托单位名称')" v-model="markData.client" class="inputs" placeholder="请输入批注[委托单位名称]"></el-input>
      <el-input @blur="pushMsg('委托单位地址')" v-model="markData.clientAddress" class="inputs" placeholder="请输入批注[委托单位地址]"></el-input>
      <el-input @blur="pushMsg('检测日期')" v-model="markData.workStart" class="inputs" placeholder="请输入批注[检测日期]"></el-input>
      <el-input @blur="pushMsg('依据标准')" v-model="markData.standard" class="inputs1" placeholder="请输入批注[依据标准]"></el-input>
      <el-input @blur="pushMsg('检测仪器设备')" v-model="markData.equipment" class="inputs1" placeholder="请输入批注[检测仪器设备]"></el-input>
      <el-input @blur="pushMsg('环境状况')" v-model="markData.environment" class="inputs" placeholder="请输入批注[环境状况]"></el-input>
      <el-input @blur="pushMsg('备注')" v-model="markData.otherInfo" class="inputs" placeholder="请输入批注[备注]"></el-input> 

    </div>
  </div>
</template>

<script>
export default {
  props: ['reportingData',],
  data(){
    return{
      boxpageData:'',
      markData:{
        insunit:'',
        client:'',
        clientAddress:'',
        workStart:'',
        sourceDataList:'',
        equipment:'',
        standard:'',
        environment:'',
        otherInfo:'', 
      },
      markMsg:{},
    }
  },
  mounted(){

  },
  created(){
    this.$bus.$on('reportBasicContents', this.reportBasicContents) 
  },
  methods:{
    getboxpage() {
      this.boxpageData = document.getElementById("borderbox").innerHTML
    },
    pushMsg(name){ 
      const keys = Object.keys(this.markData);
      keys.forEach(key => {
        if(this.markData[key] != ''){ 
         this.$emit('change', `${key}`, `${this.markData[key]}`)
         this.$emit('auditChange',name+ `:` , `${this.markData[key]}`)
        }
       
      });
    },
     //批注信息回显
    reportBasicContents(examinecontents) { 
      for (let i in examinecontents) {
        // console.log(items)
        if(i.indexOf("siteList") == -1){
          var items = i.split(":") 
          const keys = Object.keys(this.markData);
            keys.forEach(key => {
              if(items[0] == key){ 
                this.markData[key] = examinecontents[i] 
              }
            }) 
        } 
      } 
    },
  }
}
</script>

<style scoped>
@media print {
  #marks{
    display: none;
  }
}
@media screen {
  #borderbox{
    display:flex; 
    margin-left: 25%;
    /* width: 840px; */
    width: 1040px;
    padding: 40px;
    min-height: 317mm;
    border: 1px solid gainsboro;
    box-shadow: 1px 2px 13px grey;
    margin-top: 30px; 
  }
  #marks{
    width: 70mm;
    height: 297mm;
    box-sizing:content-box;
    /* border:1px solid palegoldenrod; */
  }
  .inputs{
    padding: 5px;
    height: 55px;
    /* line-height: 50px;  */
  }   
  .inputs1{
    padding: 5px;
    height: 130px;
    /* line-height: 125px;  */
  } 
  .inputs2{
    padding: 5px;
    height: 100px; 
  } 
}

#homebox {
  margin: 0;
  padding: 0;
  /* min-height: 40px; */
  width: 100%;
  border-bottom: 1px solid rgb(76, 76, 76);
  display: inline-block;
  box-sizing:border-box;
}
.homeleft {
  margin: 0;
  padding: 0;
  width:20%;
  height: 30px;
  float: left;
  padding: 5px;
  text-align: center;
  line-height: 30px;
}

.homeright {
  min-height: 30px;
  border-left: 1px solid rgb(76, 76, 76);
  padding: 5px;
  float: left;
  line-height: 30px;
}

.homeright1 {
  min-height: 125px;
  border-left: 1px solid rgb(76, 76, 76);
  padding: 5px;
  float: left; 
}

.homeright2 { 
  min-height: 85px; 
  border-left: 1px solid rgb(76, 76, 76);
  padding: 5px;
  float: left;
  line-height: 85px;
}
</style>
