<!--
 * @Author: 白一桐
 * @Date: 2023-03-23 09:32:31
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-11-13 16:49:27
 2023版本预览
-->
<template>
  <div>
    <!-- -------------------------------------------t----------------------------------------------- -->
    <!-- 按钮行部分 -->
    <div style="display: flex;">
      <el-button type="primary" @click="goRawdata()" v-if="!this.$route.query.taskid">原始记录</el-button> 
      <el-button v-print="printObj" @click="print()" v-if="cancelbtns" :disabled="isdisabled">导出pdf</el-button>
      <div class="printcss">
        &nbsp; &nbsp;报告总页码共 &nbsp; <el-input v-model="pagenum" placeholder="输入" style="display: inline-block;width: 70px;"></el-input>
        &nbsp;页 &nbsp; &nbsp;
      </div>
    </div>

    <img src="../../assets/tops.png" @click="toTop" class="top" alt="" style="width: 64px;height: 64px;" v-show="imgshow">
    <!-- 报告内容部分 -->

    <div id="bodydy"> 
      <!-- 报告首页 -->
      <div id="catalog">
        <h3>目录结构</h3>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree class="filter-tree" :data="treedata" :props="defaultProps" node-key="id" default-expand-all :filter-node-method="filterNode" ref="tree" @node-click="testclick" :expand-on-click-node="false">
        </el-tree>
      </div>
      <reportcover :reportingData="reportingData"> 
        <template #title>
          <p style="text-align: center;font-size:45px;width: 100mm;color: rgb(45, 106, 211);">雷电防护装置<br>检测报告(预览版)</p>
        </template>
        <template #ircodede>
          <img :src="dialogImageUrl" width="100px" height="100px" id="show">
        </template>
      </reportcover>
      <statement></statement>
      <reportBasicInformation :reportingData="reportingData" ref="reportBasicpage"
        @auditChange="auditChange"  @change="pageAnnotationChange">
        <template #standard>
          <div v-html="reportingData.standardbr"></div>
        </template>
      </reportBasicInformation>
      <div id="siteListdiv" v-if="reporIsno == true">
        <projectSummary :reportingData="reportingData" ref="projectSummarypage"
        @auditChange="auditChange"  @change="pageAnnotationChange"></projectSummary> 
      </div>

    </div>
    <!-- --------------------------------------------w------------------------------------------------>
    <div id="main">
      <el-dialog title="目录结构" :visible.sync="dialogVisible" width="30%">
        <div>
          <div id="catatop">目录</div>
          <div v-for="(item, index) in siteList" id="catacenter" :key="index">
            <span class="span1">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;{{ item.siteName }}</span>
            <span class="span2">......................{{ index + 1 }}</span>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="附件" :visible.sync="dialogVisibleAttach" width="30%">
        <div>
          <div v-for="(item, index) in attdata" id="attachcenter" :key="index">
            <span>{{ item.id }}:&nbsp;&nbsp;{{ item.name }}</span>
            <div>{<a>图片</a>}</div>
          </div>
          <div id="attachbottom">
            <p>以下空白</p>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAttach = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleAttach = false">确 定</el-button>
        </span>
      </el-dialog>

      <div v-for="(sitem, bindex) in siteList" :key="bindex">
        <div id="siteListdiv">
          <buildingBasicInformation :reportingData="sitem" :endorseMessage="`siteList.` + bindex"  @auditChange="auditChange"
            :auditMessage="sitem.siteName" @change="pageAnnotationChange">
            </buildingBasicInformation>
        </div>
        <div id="total">
          <div id="center">
            <ReportSubrgionNew :id="sitem.siteName" :SubrgionName="sitem" :reportTemplate="reportTemplate"
              :endorseMessage="`siteList.` + bindex " ref="subrgion"
              @change="pageAnnotationChange" :auditMessage="sitem.siteName + `-`"
              @auditChange="auditChange">
            </ReportSubrgionNew>
            <ReportImg v-if="sitem.photo" :photo="sitem.photo"
              :endorseMessage="`siteList.` + bindex " @change="pageAnnotationChange"
              :auditMessage="sitem.siteName + `-` " @auditChange="auditChange">
            </ReportImg>
          </div>  
        </div>
      </div>
      <figures :imgname="itm" v-for="itm in reportPhoto" :key="itm">
        <template #title>
          附图:
        </template>
      </figures>
      <figures v-if="reportingData.license" :imgname="reportingData.license">
        <template #title>
          附件1：
        </template>
      </figures>
      <figures v-if="reportingData.qualification" :imgname="reportingData.qualification">
        <template #title>
          <!-- 附件1： -->
        </template>
      </figures>
      <personalBlankPage v-if="reportInspectorCredential != ''" :imgnames="reportInspectorCredential">
        <template #title>
          附件2：
        </template>
      </personalBlankPage>
      <figures :imgname="i" v-for="i in reportotherPhoto" :key="i">
        <template #title>
          附件3：
        </template>
      </figures>
    </div>

    <!------------------------------------------打印部分----------------------------------------------->
    <iframe width="0" height="0" id="showMemberInfo">
        <div id="bodydys">
          <!-- 报告首页 -->
          <div id="printSection" ref="table">
            <table id="printData" ref="printData">
              <div id="noheaderBody"></div>
              <thead style="display: table-header-group;">
                <tr>
                  <td>
                    <printHeader>
                      <template #printheader>河北德创检测服务有限公司雷电防护装置检测报告</template>
                      <template #reportcode> 编号：{{ printdata.printcode }}</template>
                      <template #page>
                        <div id="page000"></div>
                      </template>
                    </printHeader>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div id="headerBody"></div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <printReportcover :reportingData="reportingData" ref="cover">
            <template #title>
              <p style="text-align: center;font-size:50px;width: 100mm;">雷电防护装置<br>检测报告</p>
            </template>
            <template #ircodede>
              <img :src="dialogImageUrl" width="100px" height="100px"></img>
            </template>
          </printReportcover>
          <printReoprtstatement ref="statement"></printReoprtstatement> 
          <reportBasicInformation :reportingData="reportingData" ref="reportBasic">
            <template #standard>
              <div v-html="reportingData.standardbr"></div>
            </template>
          </reportBasicInformation>
           <div id="siteListdiv">
             <projectSummary :reportingData="reportingData" v-if="reportingData"  ref="projectsummary"></projectSummary> 
           </div>
          <!--------------------------------------------w---------------------------------------------->
          <div id="fbody">
            <div v-for="(printitem, printbindex) in siteList" :key="printbindex" id="fbodystyle" className="item">
              <!-- <printReportSummary :index="printbindex" :id="printitem.siteName" :SummaryName="printitem">
              </printReportSummary> -->
              <buildingBasicInformation :reportingData="printitem" ref="buildingBasics"></buildingBasicInformation>

                <ReportSubrgionNew :id="printitem.areaName" :SubrgionName="printitem" ref="printsubrgion"
                  :reportTemplate="reportTemplate">
                </ReportSubrgionNew>
                <printReportImg v-if="printitem.photo" :photo="item.photo" :key="index">
                </printReportImg>
            </div>

            <figures :imgname="itm" v-for="itm in reportPhoto" :key="itm">
              <template #title>
                附图:
              </template>
            </figures>
            <div v-if="siteList != ''"
              style="width:100%;height:20px;text-align:center;color:black;text-decoration:underline;">
              &nbsp;&nbsp;以下空白&nbsp;&nbsp;
            </div>
            <figures v-if="reportingData.license" :imgname="reportingData.license">
              <template #title>
                附件1：
              </template>
            </figures>
            <figures v-if="reportingData.qualification" :imgname="reportingData.qualification">
              <template #title>
                <!-- 附件1： -->
              </template>
            </figures>
            <!-- <personalBlankPage :imgnames="reportInspectorCredential">
              <template #title>
                附件2：
              </template>
            </personalBlankPage> -->
            <personalBlankPage v-if="reportInspectorCredential != ''" :imgnames="reportInspectorCredential">
              <template #title>
                附件2：
              </template>
            </personalBlankPage>
            <figures :imgname="i" v-for="i in reportotherPhoto" :key="i">
              <template #title>
                附件3：
              </template>
            </figures>

          </div>
        </div>
      </iframe>

  </div>
</template>

<script>
import reportcover from '@/components/reportcover.vue'
import statement from '@/components/reportstatement.vue'
import reportBasicInformation from '@/components/tmp2023/reportBasicInformation.vue'
import buildingBasicInformation from '@/components/tmp2023/buildingBasicInformation.vue'
import projectSummary from '@/components/tmp2023/projectSummary.vue'

import figures from '@/components/figure.vue'
import personalBlankPage from '@/components/personalBlankPage.vue' 
import ReportSubrgionNew from '@/views-w/ReportSubrgionNew'
import ReportSummary from '@/views-w/ReportSummary' 
import ReportImg from '@/views-w/ReportImg'

//打印
import printHeader from '@/components/printHeader.vue'
import printReportcover from '@/components/printReportcover.vue'
import printReoprtstatement from '@/components/printReoprtstatement.vue'
export default {
  data() {
    return {
      //-----------------------------t-----------------
      reportingData: '',
      reportId: '', //报告Id 
      cancelbtns: true, //是否展示导出  
      ircode: '', //二维码
      dialogImageUrl: '', //二维码图片位置
      examinecontents: '初始值', //批注内容 
      imgIframe: false, //上传图片的弹出层
      fromData: [], //上传图片时的key等数据
      key: '', //Object key
      imgName: '', //图片名称objname
      verdicts: '', //检测结论
      printObj: {
        id: 'printSection', //要打印的id名 无#号
        popTitle: '', //页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
        extraHead: '' //头部文字 默认空
      },
      printdata: {
        username: '河北德创检测服务有限公司雷电防护装置检测报告',
        printcode: ''
      },
      isdisabled: true,
      pagenum: '',
      vstr: '',
      reportPhoto: [], //附图
      reportotherPhoto: [], //其他附图
      reportInspectorCredential: [], //检测员资质第一部分
      // reportInspectorCredential2: [],//检测员资质第2部分 
      reporIsno:false,
      reportTemplate: '', //报告版本

      //-----------------------------w----------------- 
      dialogVisible: false,
      dialogVisibleAttach: false,
      siteList: [],
      catalogueList: [],
      treedata: [],
      filterText: '',
      defaultProps: {
        label: 'label',
        children: 'children'
      },
      attdata: [
        {
          id: '附件一',
          name: '防雷装置检测甲级资质(扫描件)'
        },
        {
          id: '附件二',
          name: '公司营业执照(扫描件)'
        },
        {
          id: '附件三',
          name: '个人健康说明'
        }
      ],
      count: '',
      lastvalue: '',
      lastkey: '',
      auditlastvalue: '',
      auditlastkey: '',
      commentData: {},
      imgshow: false,
      examineMsgs: []
    }
  },
  components: {
    reportcover,
    statement,
    reportBasicInformation,
    buildingBasicInformation,
    projectSummary,
    
    figures,
    personalBlankPage,
    ReportSummary,
    ReportSubrgionNew,
    ReportImg, 
    printHeader,
    printReportcover,
    printReoprtstatement
  },
  mounted() { 
    this.getData()
    this.reportId = this.$route.query.id
    this.reportTemplate = this.$route.query.type 
    window.addEventListener('scroll', this.imgshowListener) //事件监听
    this.btnload()
  },
  methods: {
    // ----------------------------------t-----------------------------------------
    btnload() {
      setTimeout(() => {
        this.isdisabled = false
      }, 3000)
    },
    goRawdata() {
      if(this.reportTemplate == '004'){
        let routeData = this.$router.resolve({
          path: '/reportrawDataNew', 
          query: {
            id: this.reportId,
            type: 'rawdata'
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }else{
        //从表格的预览跳转原始报告页面  增加类型 以便判断
        let routeData = this.$router.resolve({
          path: '/reportrawData',
          query: {
            id: this.reportId,
            type: 'rawdata'
          }
        })
        //必要操作，否则不会打开新页面
        window.open(routeData.href, '_blank')
      }
      
    },
      //打印
    print() {
      let imgtotal = 0 //附件的页数
      if (this.reportotherPhoto.length != 0) {
        imgtotal += this.reportotherPhoto.length
      }
      if (this.reportInspectorCredential.length != 0) {
        console.log(this.reportInspectorCredential.length)
        imgtotal += 1
      }
      if (this.reportingData.license) {
        imgtotal += 1
      }
      if (this.reportingData.qualification) {
        imgtotal += 1
      }

      let pagenumber = this.pagenum - imgtotal
      let total = pagenumber - 2
      let num = ' 2 ～ ' + total
      let vstr = this.vstr.replace('page', num)
      this.$set(this.reportingData, 'printVerdicts', vstr)
      let bodyEle = $('#page000')
      // //动态添加页码 通过绝对定位
      let hgh = 363
      for (let i = 0; i < pagenumber; i++) {
        let a = '第 ' + (i - 1) + ' 页 共 ' + total + ' 页 '
        if (i >= 2 && i <= 4) {
          hgh--
          let foot = '<div style="width:200px;position:absolute;top:' + hgh * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 4 && i <= 6) {
          let foot = '<div style="width:200px;position:absolute;top:' + 360 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 6 && i <= 10) {
          let foot = '<div style="width:200px;position:absolute;top:' + 359 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 10 && i <= 24) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.5 * i + 'mm;padding-top:20mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 24 && i <= 38) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:22mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 38 && i <= 50) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 50 && i <= 70) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 70 && i <= 90) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 90 && i <= 140) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 140 && i <= 190) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.3 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 190 && i <= 250) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 250 && i <= 280) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 280 && i <= 320) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 320 && i <= 380) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 380 && i <= 420) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 420 && i <= 480) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 480 && i <= 540) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 540 && i <= 600) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 600 && i <= 680) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 680 && i <= 740) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 740 && i <= 800) {
          let foot = '<div style="width:200px;position:absolute;top:' + 358.27 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        }
      }

      this.$nextTick(() => {
        //解决第一遍数据不回显问题
        this.printJson()
        // this.showMemberInfo = true
        // this.printObj.popTitle = this.printdata.username + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + '编号：' + this.printdata.printcode
      })
    },

    printJson() {
      this.$refs.cover.getcover()
      this.$refs.statement.getstatement()
      this.$refs.reportBasic.getboxpage()
      this.$refs.projectsummary.getprojectpage() 
      let test = document.getElementById('fbody').innerHTML
      let page1 = this.$refs.cover.covers + this.$refs.statement.statements
      document.getElementById('noheaderBody').innerHTML = page1
      let page = this.$refs.reportBasic.boxpageData + this.$refs.projectsummary.projectData + test
      document.getElementById('headerBody').innerHTML = page
    },
    //#region
    //回到顶部按钮是否展示
    imgshowListener() {
      let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (top > 200) {
        this.imgshow = true
      } else {
        this.imgshow = false
      }
    },
    //回到顶部
    toTop() {
      document.documentElement.scrollTop = 0
    },
    //获取报告状态
    getStatus() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/' + this.$route.query.id
      })
        .then(res => {
          // console.log(res.data)
          if (res.data.confirmstat == 99) {
            console.log('作废的')
            // this.cancelbtn = true
            this.cancelbtns = false
          } else {
            console.log('buzuof')
          }
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    // 获取整体的数据
    getData() {
      if (this.$route.query.taskid) {
        // console.log('流程轨迹里的') 有taskid的为流程轨迹中的预览 --- 第一版本
        //流程轨迹里的
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/content/first/his/' + this.$route.query.id
        })
          .then(res => {
            this.reportingData = res.data
            this.reporIsno = true
            document.title = '预览 - ' + res.data.sites
            this.getStatus()
            // console.log(res.data) 
            if (res.data.photo) {
              let photos = res.data.photo
              photos = photos.substring(0, photos.lastIndexOf('|'))
              this.reportPhoto = photos.split('|')
              // console.log(this.reportPhoto);
            }

            if (res.data.otherPhoto) {
              let otherPhoto = res.data.otherPhoto
              otherPhoto = otherPhoto.substring(0, otherPhoto.lastIndexOf('|'))
              this.reportotherPhoto = otherPhoto.split('|')
            }

            if (res.data.inspectorCredential) {
              let inspectorCredential = res.data.inspectorCredential
              inspectorCredential = inspectorCredential.substring(0, inspectorCredential.lastIndexOf('|'))
              let arr = inspectorCredential.split('|')
              this.reportInspectorCredential = arr
            }

            if (res.data.siteList) {
              this.siteList = res.data.siteList
              // this.pointNumber(res.data.siteList)
              // this.getExamineData() //防止第一次渲染页面,子组件未加载全局事件未绑定,此方法中触发全局事件不起作用的问题
            } 
            if (res.data.verdict) {
              this.vstr = res.data.verdict
              this.verdicts = this.vstr.replace('page', '3')
            }
            if (res.data.standard) {
              let str = res.data.standard
              this.reportingData.standardbr = str.replace(/\n/g, '<br/>') //检测报告规范
            }
            if (res.data.equipment) {
              //设备
              let equip = this.reportingData.equipment.replaceAll('设备名称：', '')
              let equip1 = equip.replaceAll('型号：', '')
              let equip2 = equip1.replaceAll('设备编号：', '')
              let equip3 = equip2.replaceAll('出厂编号：', '')
              let strequip = equip3.split('|')
              let equipmentArr = []
              for (let i = 0; i < strequip.length; i++) {
                let data = {}
                // console.log(strequip[i].split("\n")[1])
                data.eqName = strequip[i].split('\n')[1]
                data.eqModel = strequip[i].split('\n')[2]
                data.eqNo = strequip[i].split('\n')[3]
                equipmentArr.push(data)
              }
              // console.log(equipmentArr)
              this.reportingData.equipmentArr = equipmentArr
            }
            if (res.data.weather == '请选择') {
              this.reportingData.weather = ''
            }
            if (res.data.soil == '请选择') {
              this.reportingData.soil = ''
            }
            this.ircode = res.data.ircode
            if (res.data.ircode) {
              this.imgimg()
            } else {
              this.ircode = 'd62bdcd41fa4becb23e201851378e28d.jpg'
              this.imgimg()
            }
          })
          .catch(error => {
            console.log(error.response.data)
          })
      } else {
        //审核部分的预览界面
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/content/temp/' + this.$route.query.id
        })
          .then(res => {
            this.reportingData = res.data
            // console.log("预览",res.data)
            document.title = '预览 - ' + res.data.sites
            this.reporIsno = true

            if (res.data.trptNo) {
              this.printdata.printcode = res.data.trptNo
            }

            if (res.data.photo) {
              let photos = res.data.photo
              photos = photos.substring(0, photos.lastIndexOf('|'))
              this.reportPhoto = photos.split('|')
              // console.log(this.reportPhoto);
            }

            if (res.data.otherPhoto) {
              let otherPhoto = res.data.otherPhoto
              otherPhoto = otherPhoto.substring(0, otherPhoto.lastIndexOf('|'))
              this.reportotherPhoto = otherPhoto.split('|')
            }

            if (res.data.inspectorCredential) {
              let inspectorCredential = res.data.inspectorCredential
              inspectorCredential = inspectorCredential.substring(0, inspectorCredential.lastIndexOf('|'))
              let arr = inspectorCredential.split('|')
              this.reportInspectorCredential = arr
            }
            // console.log(res.data.siteList)
            if (res.data.siteList) {
              this.siteList = res.data.siteList
              // this.pointNumber(res.data.siteList)
              // this.getExamineData() //防止第一次渲染页面,子组件未加载全局事件未绑定,此方法中触发全局事件不起作用的问题
            } 
            if (res.data.standard) {
              let str = res.data.standard
              this.reportingData.standardbr = str.replace(/\n/g, '<br/>') //检测报告规范 
            }
            if (res.data.equipment) {
              //设备
              let equip = this.reportingData.equipment.replaceAll('设备名称：', '')
              let equip1 = equip.replaceAll('型号：', '')
              let equip2 = equip1.replaceAll('设备编号：', '')
              let equip3 = equip2.replaceAll('出厂编号：', '')
              let strequip = equip3.split('|')
              let equipmentArr = []
              for (let i = 0; i < strequip.length; i++) {
                let data = {}
                // console.log(strequip[i].split("\n")[1])
                data.eqName = strequip[i].split('\n')[1]
                data.eqModel = strequip[i].split('\n')[2]
                data.eqNo = strequip[i].split('\n')[3]
                equipmentArr.push(data)
              }
              // console.log(equipmentArr)
              this.reportingData.equipmentArr = equipmentArr
            }
            if (res.data.weather == '请选择') {
              this.reportingData.weather = ''
            }
            if (res.data.soil == '请选择') {
              this.reportingData.soil = ''
            }
            this.ircode = res.data.ircode
            if (res.data.ircode) {
              this.imgimg()
            } else {
              this.ircode = 'd62bdcd41fa4becb23e201851378e28d.jpg'
              this.imgimg()
            }
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },
    //默认显示图片
    imgimg() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.ircode
      })
        .then(res => {
          // console.log(res.data)
          this.dialogImageUrl = res.data.url
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    //#endregion
    // ----------------------------------w-----------------------------------------
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //页面定位
    testclick(data) {
      console.log('目录：', data.label)
      var dpoint = data.label.replace(/\s*\(.*?\)\s*/g, '')
      if (dpoint) {
        // console.log('querySelector',document.querySelector('#anch3'))
        document.getElementById(dpoint).scrollIntoView({
          behavior: 'instant'
        })
      }
    },
    //回调函数调用分区子组件修约方法
    useevenRound(v1, v2) {
      let result = this.$refs.subrgion[0].evenRound(v1, v2)
      this.$bus.$emit('changepressureEqualizingRingSpacing', result)
    },
    //遍历出分区数组中每个对象的长度，作为分区检测点的个数
    pointNumber(pointLists) {
      var pointList = JSON.parse(JSON.stringify(pointLists)) //深拷贝防止影响sitelist数组
      for (var i = 0; i < pointList.length; i++) {
        var bigsum = 0
        if (pointList[i].areaList.length > 0) {
          for (var j = 0; j < pointList[i].areaList.length; j++) {
            var sum = 0
            for (var m = 0; m < pointList[i].areaList[j].sourceDataList.length; m++) {
              if (pointList[i].areaList[j].sourceDataList.length > 0) {
                var changObj = pointList[i].areaList[j].sourceDataList[m]
                for (let item in changObj) {
                  if (item.indexOf('Standard') != -1 || changObj[item] == '请选择') {
                    delete changObj[item]
                  }
                }
                // console.log('changeObj:',changObj);
                var arr = Object.keys(changObj)
                // console.log('arr:',arr);
                // console.log('分区长度：', arr.length)
                sum += arr.length - 4
                pointList[i].areaList[j].count = sum
              } else {
                pointList[i].areaList[j].count = 0
              }
            }
            bigsum += pointList[i].areaList[j].count
            pointList[i].count = bigsum
          }
        } else {
          pointList[i].count = 0
        }
      }
      this.getsiteList(pointList)
    },
    //目录结构tree数据转换
    getsiteList(list) {
      var changeList = JSON.parse(JSON.stringify(list))
      changeList.forEach(element => {
        var obj = {
          label: element.siteName + (element.count > 0 ? `(${element.count})` : ''),
          children: element.areaList && element.areaList.length > 0 ? this.copyTree(element.areaList) : element.areaList
        }
        this.treedata.push(obj)
      })
      // console.log('改变后的tree:', this.treedata);
    },
    //递归转换数据格式中每层的格式
    copyTree(childrenList) {
      // console.log('copyTree:',this.count);
      childrenList.forEach(item => {
        item.label = item.areaName + (item.count > 0 ? `(${item.count})` : '')
        item.children = item.childrenList
        if (item.childrenList && item.childrenList.length) {
          this.copyTree(item.children)
        }
      })
      return childrenList
    },
    //页面批注信息返回
    pageAnnotationChange(key, value1) {
      if (this.lastvalue !== value1 && value1 != '') {
        this.lastkey = key
        this.lastvalue = value1
        this.commentData[key] = value1
      } else if (this.lastvalue == value1 && this.lastkey !== key) {
        this.commentData[key] = value1
        console.log(this.commentData)
      } else if (this.lastvalue !== value1 && value1 == '') {
        this.commentData[key] = value1
        delete this.commentData[key]
        console.log('删除属性', this.commentData)
      }
    },
    //审核确认表信息回传文字格式
    auditChange(key, value) {
      const audit = {}
      for (var item in this.examineMsgs) {
        // console.log('上次',this.examineMsgs[item].msg);
        if (this.examineMsgs[item].msg.indexOf(key) != -1 && value != '') {
          this.examineMsgs[item].msg = key + value
          console.log('替换', this.examineMsgs)
          return
        }
      }
      if (this.auditlastvalue !== value && value != '') {
        //添加新批注
        this.auditlastkey = key
        this.auditlastvalue = value
        audit.msg = key + value
        this.examineMsgs.push(audit)
        console.log('添加examineMsgs', this.examineMsgs)
      } else if (this.auditlastvalue == value && this.auditlastkey !== key) {
        //和上次批注信息一样情况
        audit.msg = key + value
        this.examineMsgs.push(audit)
        // console.log('父组件中',audit);
      } else if (this.examinecontents == '初始值' && value == '') {
        // 第一次没提交时修改批注为空
        for (var item in this.examineMsgs) {
          // console.log('上次',this.examineMsgs[item].msg);
          if (this.examineMsgs[item].msg.indexOf(key) != -1) {
            delete this.examineMsgs[item]
            console.log('删除后', this.examineMsgs)
          }
        }
      } else if (this.examinecontents != '初始值' && value == '') {
        //批注提交后修改批注为空
        audit.msg = key
        this.examineMsgs.push(audit)
        console.log('第二次：', this.examineMsgs)
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.imgshowListener)
  }
}
</script>
<style scoped>
#btnList {
  height: 40px;
  width: 98.8%;
  padding: 10px;
  background-color: whitesmoke;
}

.left {
  float: left;
}

.right {
  height: 40px;
  line-height: 1px;
  float: left;
  border: 1px solid gainsboro;
  box-shadow: 1px 1px 4px gainsboro;
  /* border-bottom: 1px solid silver; */
  border-radius: 5px;
  text-align: center;
  margin-left: 30px;
}

/* 回到顶部 */
.top {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 50px;
  right: 100px;
}

.el-icon-plus .avatar-uploader-icon {
  border: 1px solid saddlebrown;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

#siteListdiv{
    margin-left: 25%;
    width: 1040px;
    padding: 40px; 
    border: 1px solid gainsboro;
    box-shadow: 1px 2px 13px grey;
    margin-top: 30px; 
}
/* ----------------------------------w------------------------------------ */
#main {
  overflow-x: hidden;
  width: 100%;
}

#total {
  margin-left: 25%;
  width: 60%;
  border: 1px solid gainsboro;
  box-shadow: 1px 2px 13px grey;
  margin-top: 30px;
}

#center {
  width: 90%;
  margin: 0px 50px;
}

#top {
  margin: 20px 200px;
}

#catalog {
  border: 1px solid gainsboro;
  width: 20%;
  height: 70%;
  position: fixed;
  overflow-y: auto;
  /* position: fixed;
    display:flex;
    justify-content:flex-start;
    flex-direction: column; */
  left: 10px;
}

#catatop {
  text-align: center;
  font-size: 22px;
  letter-spacing: 20px;
}

#catacenter {
  margin: 10px 100px;
  font-size: 16px;
}

.span1 {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.span2 {
  position: absolute;
  left: 300px;
}

#attachcenter {
  margin: 10px 50px;
  font-size: 16px;
}

#attachbottom p {
  margin-left: 400px;
  text-decoration: underline;
}
</style>