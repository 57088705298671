import { render, staticRenderFns } from "./projectSummary.vue?vue&type=template&id=46748fad&scoped=true&"
import script from "./projectSummary.vue?vue&type=script&lang=js&"
export * from "./projectSummary.vue?vue&type=script&lang=js&"
import style0 from "./projectSummary.vue?vue&type=style&index=0&id=46748fad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46748fad",
  null
  
)

export default component.exports